import {
    callAPI_login,
    callAPI_createAcct,
    clearUserSession,
    userSession,
    openAcctAlreadyExists,
    decorateUserIconWithEmail
} from "./userActivities.js";
import {
    userProfileUnderEdit,
    readInUserProfileUnderEdit,
    clearUserProfUnderEditForLogout
} from "./userProfEditController.js";
import {
    putUserSessionInLocalStorage,
    URL_PRE,
    WS_URL_PRE,
    lsConsts,
    getObjectFromLocalStorage,
    putObjectInLocalStorage,
    removeValueFromLocalStorage
} from "./ddpLocalStorage.js";
import {urlRoutes} from "./urlRoutes.js";
import {
    goToLearningHome,
    MCA,
    retrieveHtmlTemplateForRouteKey,
    setCurrentDirtyEditorName
} from "./routingAndNav.js";
import {
    closeModalDialog,
    hideLoaderMainContentArea,
    showLoaderMainContentArea,
    openErrorMessageModal
} from "./modalDialog.js";
import {messages} from "./messages.js";

// This state variable to record which authentication method the user selects
// from the dropdown menu. It could be one of their emails or phone numbers.
let authMethod = null;

/*
This is an object of key/value pairs where the values are booleans for whether a
code has been sent or the authentication has been completed by the user for the
given authMethod. The keys are the following constants:
 */
let authenticationFactors = {};

// authenticatedFactors Keys
const PASSWORD = `password`;
const EMAIL = `email`;
const EMAIL2 = `email2`;
const PHONE = `phone`;
const PHONE2 = `phone2`;

// These constants are for specifying which user profile editor page
// the user was trying to go to when we redirected them to the 2FA wizard.
export const EMAILS_PAGE = 'emailsPage';
export const PASSWORD_PAGE = 'passwordPage';
export const PHONES_PAGE = 'phonesPage';

// Set the default destination in case the user goes directly to the
// 2FA wizard.
let destinationEditor = PASSWORD_PAGE;

export function setDestinationEditor(userDestination) {
    destinationEditor = userDestination;
}

export async function initUiUserSecurity() {
    const searchParams = new URLSearchParams(window.location.search);
    console.log(`searchParams: ${searchParams}`);
    const emailAddr = searchParams.get('userName');
    const uuid = searchParams.get('uuid');
    const isNewUser = searchParams.get('newUser');

    if (emailAddr) {
        // ASSERT: This url has been created by the user clicking a button in an email
        // we sent them. We must clear out the userSession and profileUnderEdit.
        clearUserSession();
        clearUserProfUnderEditForLogout();
        removeValueFromLocalStorage(lsConsts.AUTHENTICATION_FACTORS);
    }

    // Initialize authentication factors if they are not already in local storage.
    authenticationFactors = getObjectFromLocalStorage(lsConsts.AUTHENTICATION_FACTORS);
    if (!authenticationFactors) {
        authenticationFactors = {};
        authenticationFactors[PASSWORD] = { authComplete: false, codeSent: false};
        authenticationFactors[EMAIL] = { authComplete: false, codeSent: false};
        authenticationFactors[EMAIL2] = { authComplete: false, codeSent: false};
        authenticationFactors[PHONE] = { authComplete: false, codeSent: false};
        authenticationFactors[PHONE2] = { authComplete: false, codeSent: false};

        putObjectInLocalStorage(lsConsts.AUTHENTICATION_FACTORS, authenticationFactors);
    }

    try {
        if (!(userSession && userSession.id) && emailAddr && uuid) {
            // ASSERT: The user is here by clicking the button in an email we sent them.

            if (isNewUser !== 'true') {
                // ASSERT: This is an existing user trying to change their password

                console.log(`emailAddr: ${emailAddr} uuid: ${uuid}`);
                await callAPI_login(emailAddr, null, uuid);
                if (userSession) {
                    putUserSessionInLocalStorage(userSession);
                    decorateUserIconWithEmail();
                    authenticationFactors[EMAIL].authComplete = true;
                    putObjectInLocalStorage(lsConsts.AUTHENTICATION_FACTORS, authenticationFactors);

                    await readInUserProfileUnderEdit();

                    if (userProfileUnderEdit) {
                        // ASSERT: able to retrieve the userProfile, so continue.
                        let hasSecondAuthFactor = true;

                        if (!userProfileUnderEdit.email2 && !userProfileUnderEdit.phone && !userProfileUnderEdit.phone2) {
                            // ASSERT: there is no second way to authenticate this user.
                            hasSecondAuthFactor = false;
                        }

                        if (hasSecondAuthFactor) {
                            initUiToSendCode();
                        } else {
                            // ASSERT: The existing user who is trying to change their password does not have a phone number
                            // or secondary email for us to run 2FA, so mark the userSession as though they have completed 2FA.
                            userSession.is2FaAuthenticated = true;
                            putUserSessionInLocalStorage(userSession);
                            decorateUserIconWithEmail();

                            goToDestinationEditor();
                        }
                    }
                }
            } else {
                // ASSERT: This is a new user who has just clicked the "Confirm account" button on the welcome email.

                const state = searchParams.get('state');
                const district = searchParams.get('district');

                const newUserCreated = await callAPI_createAcct(emailAddr, uuid, state, district);
                if (newUserCreated) {
                    // ASSERT: new user created successfully

                    // New users would not have a method specified to do a second factor auth, so just mark the object
                    // as if they've completed 2FA.
                    userSession.is2FaAuthenticated = true;
                    putUserSessionInLocalStorage(userSession);
                    decorateUserIconWithEmail();

                    goToDestinationEditor();
                } else {
                    // ASSERT: A user acct already exists for that email address. Replace main area with
                    // the acctAlreadyExists page.
                    await openAcctAlreadyExists(emailAddr);
                }
            }
        } else {
            // ASSERT: The user is already logged in, and got to the userSecuritySendCode page by simply going to the
            // 'Edit user settings' user menu option, and clicking one of the security nodes in the leftNav.

            if (userSession && userSession.is2FaAuthenticated) {
                // ASSERT: the user already completed 2FA, so send them to the page to tell them so.

                const htmlTemplate = await retrieveHtmlTemplateForRouteKey(`/userSecurity2FaComplete`);
                document.getElementById(MCA).innerHTML = htmlTemplate;
            } else {
                if (userSession) {
                    // ASSERT: the user needs to complete 2FA, so load the page where they can send a one-time code to
                    // their email or phone.

                    if (userSession.isPwdAuthenticated) {
                        authenticationFactors[PASSWORD].authComplete = true;
                        putObjectInLocalStorage(lsConsts.AUTHENTICATION_FACTORS, authenticationFactors);
                    }

                    await readInUserProfileUnderEdit();

                    if (userProfileUnderEdit) {
                        // ASSERT: the userProfile was successfully retrieved from the API, so populate the sendCode page.
                        initUiToSendCode();
                    }
                }
            }
        }
    } catch (err) {
        throw err;
    }
}

async function initUiToSendCode() {
    // By virtue of the user clicking the Change Password link in the email they received, they
    // have completed the first factor of authentication by verifying they're in control
    // of the primary email address. Now, send them to the SendCode page again, so they can
    // complete the second factor of authentication.
    const htmlTemplate = await retrieveHtmlTemplateForRouteKey('/userSecuritySendCode');
    if (htmlTemplate) {
        const mainContentArea = document.getElementById(MCA).innerHTML = htmlTemplate;

        const selectedAuthMethod = populateSecurityDropdown();
        if (selectedAuthMethod === EMAIL ||
            selectedAuthMethod === EMAIL2) {
            // Hide the dropdown only meant for phone numbers
            const textOrVoiceElems = document.querySelectorAll('.security__method');
            hideElems(textOrVoiceElems, true);
        }

        addEventListeners();
    } else {
        // ASSERT: The server is down.
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        goToLearningHome();
    }
}

function populateSecurityDropdown() {
    // The retVal will be the auth method selected by default
    let retVal = null;
    const securityDropdown = document.getElementById("security-dropdown");
    if (userProfileUnderEdit.email &&
        !authenticationFactors[EMAIL].authComplete &&
        !authenticationFactors[EMAIL].codeSent) {
        let option = document.createElement("option");
        const email = userProfileUnderEdit.email;
        if (email) {
            option.value = email;
            option.text = email;
            securityDropdown.add(option, null);
            retVal = EMAIL;
        }
    }

    if (userProfileUnderEdit.email2 &&
        !authenticationFactors[EMAIL2].authComplete &&
        !authenticationFactors[EMAIL2].codeSent) {
        let option = document.createElement("option");
        const email = userProfileUnderEdit.email2;
        if (email) {
            option.value = email;
            option.text = email;
            securityDropdown.add(option, null);

            if (!retVal) {
                retVal = EMAIL2;
            }
        }
    }

    if (userProfileUnderEdit.phone &&
        !authenticationFactors[PHONE].authComplete &&
        !authenticationFactors[PHONE].codeSent) {
        let option = document.createElement("option");
        const phone = userProfileUnderEdit.phone;
        if (phone) {
            option.value = phone;
            option.text = phone;
            securityDropdown.add(option, null);

            if (!retVal) {
                retVal = PHONE;
            }
        }
    }

    if (userProfileUnderEdit.phone2 &&
        !authenticationFactors[PHONE2].authComplete &&
        !authenticationFactors[PHONE2].codeSent) {
        let option = document.createElement("option");
        const phone = userProfileUnderEdit.phone2;
        if (phone) {
            option.value = phone;
            option.text = phone;
            securityDropdown.add(option, null);

            if (!retVal) {
                retVal = PHONE2;
            }
        }
    }

    return retVal;
}

function populatePrevCodeArrivedSecurityDropdown() {
    authMethod = null;
    const securityDropdown = document.getElementById("security-dropdown");
    if (userProfileUnderEdit.email &&
        !authenticationFactors[EMAIL].authComplete) {
        let option = document.createElement("option");
        const email = userProfileUnderEdit.email;
        if (email) {
            option.value = email;
            option.text = email;
            securityDropdown.add(option, null);

            if (!authMethod) {
                authMethod = email;
            }
        }
    }

    if (userProfileUnderEdit.email2 &&
        !authenticationFactors[EMAIL2].authComplete) {
        let option = document.createElement("option");
        const email = userProfileUnderEdit.email2;
        if (email) {
            option.value = email;
            option.text = email;
            securityDropdown.add(option, null);

            if (!authMethod) {
                authMethod = email;
            }
        }
    }

    if (userProfileUnderEdit.phone &&
        !authenticationFactors[PHONE].authComplete) {
        let option = document.createElement("option");
        const phone = userProfileUnderEdit.phone;
        if (phone) {
            option.value = phone;
            option.text = phone;
            securityDropdown.add(option, null);

            if (!authMethod) {
                authMethod = phone;
            }
        }
    }

    if (userProfileUnderEdit.phone2 &&
        !authenticationFactors[PHONE2].authComplete) {
        let option = document.createElement("option");
        const phone = userProfileUnderEdit.phone2;
        if (phone) {
            option.value = phone;
            option.text = phone;
            securityDropdown.add(option, null);

            if (!authMethod) {
                authMethod = phone;
            }
        }
    }
}

function addEventListeners() {
    const nextBtn = document.getElementById('security-next-btn');
    nextBtn.addEventListener('click', adjustUiAfterSendCode);

    const securityDropdown = document.getElementById('security-dropdown');
    securityDropdown.addEventListener('change', adjustUiForSecurityChange);

    const prevCodeReceivedBtn = document.getElementById('prev-code-arrived');
    prevCodeReceivedBtn.addEventListener('click', handlePrevCodeArrived);
}

function addPrevCodeArrivedEventListeners() {
    const nextBtn = document.getElementById('security-next-btn');
    nextBtn.addEventListener('click', adjustUiAfterVerifyCode);

    const tryAnotherWayBtn = document.getElementById('try-another-way');
    tryAnotherWayBtn.addEventListener('click', handleTryAnotherWay);

    const targetDropdown = document.getElementById('security-dropdown');
    targetDropdown.addEventListener('change', handlePrevCodeArrivedAuthMethodChanged);
}

function handlePrevCodeArrivedAuthMethodChanged(event) {
    authMethod = event.target.value;
}

async function handleTryAnotherWay() {
    showLoaderMainContentArea();
    try {
        const htmlTemplate = await retrieveHtmlTemplateForRouteKey(`/userSecuritySendCode`);
        document.getElementById(MCA).innerHTML = htmlTemplate;

        const textOrVoiceElems = document.querySelectorAll('.security__method');
        hideElems(textOrVoiceElems, true);

        populateSecurityDropdown();

        addEventListeners();
    } catch (err) {
        // ASSERT: the web server is down.
        console.dir(err);
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        goToLearningHome();
    }
    hideLoaderMainContentArea();
}

async function handlePrevCodeArrived() {
    // Open send code page
    document.getElementById(MCA).innerHTML = await retrieveHtmlTemplateForRouteKey('/userSecurityPrevCodeArrived');

    populatePrevCodeArrivedSecurityDropdown();

    addPrevCodeArrivedEventListeners();
}

/*
This callback function is invoked when the user changes where they want the one-time code sent, whether that
is a phone number or email addr.
 */
function adjustUiForSecurityChange(event) {
    const securityDropdown = event.target;

    const textOrVoiceElems = document.querySelectorAll('.security__method');
    const newSecuritySelection = securityDropdown.value;

    if (newSecuritySelection === userProfileUnderEdit.email ||
        newSecuritySelection === userProfileUnderEdit.email2) {
        hideElems(textOrVoiceElems, true);
    } else if (newSecuritySelection === userProfileUnderEdit.phone ||
        newSecuritySelection === userProfileUnderEdit.phone2) {
        hideElems(textOrVoiceElems, false);
    }
}

function hideElems(textOrVoiceElems, hide) {
    const elemArray = Array.prototype.slice.call(textOrVoiceElems);
    elemArray.forEach((elem) => {
        if (hide) {
            elem.classList.add('hidden');
        } else {
            elem.classList.remove('hidden');
        }

    });
}

async function adjustUiAfterSendCode(event) {
    event.preventDefault();

    const targetDropdown = document.getElementById('security-dropdown');
    authMethod = targetDropdown.value;

    showLoaderMainContentArea();
    try {
        let isCodeSent = false;
        if (authMethod === userProfileUnderEdit.email) {
            isCodeSent = await callAPI_emailSendCode(authMethod);
            // isCodeSent = true;
            authenticationFactors[EMAIL].codeSent = isCodeSent;
        } else if (authMethod === userProfileUnderEdit.email2) {
            isCodeSent = await callAPI_emailSendCode(authMethod);
            // isCodeSent = true;
            authenticationFactors[EMAIL2].codeSent = isCodeSent;
        } else if (authMethod === userProfileUnderEdit.phone) {
            isCodeSent = await callAPI_phoneSendCode(authMethod);
            // isCodeSent = true;
            authenticationFactors[PHONE].codeSent = isCodeSent;
        } else if (authMethod === userProfileUnderEdit.phone2) {
            isCodeSent = await callAPI_phoneSendCode(authMethod);
            // isCodeSent = true;
            authenticationFactors[PHONE2].codeSent = isCodeSent;
        }

        putObjectInLocalStorage(lsConsts.AUTHENTICATION_FACTORS, authenticationFactors);

        // Open code verify page
        const htmlTemplate = await retrieveHtmlTemplateForRouteKey(`/userSecurityVerifyCode`);
        setCurrentDirtyEditorName('securityWizard');
        document.getElementById(MCA).innerHTML = htmlTemplate;

        const infoElem = document.getElementById('security-info');
        const infoStr = `Please type in the one-time code and click Next to verify. The code was sent to <b>${authMethod}</b>`;
        infoElem.innerHTML = infoStr;

        const nextBtn = document.getElementById('security-next-btn');
        nextBtn.addEventListener('click', adjustUiAfterVerifyCode);

        const tryAnotherWayBtn = document.getElementById('try-another-way');
        tryAnotherWayBtn.addEventListener('click', handleTryAnotherWay);
    } catch (err) {
        // ASSERT: the api or web server is down.
        console.dir(err);
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        goToLearningHome();
    }
    hideLoaderMainContentArea();
}

async function adjustUiAfterVerifyCode(event) {
    event.preventDefault();

    const codeField = document.getElementById('security-code');
    const code = codeField.value;
    let keyStr = null;

    showLoaderMainContentArea();
    try {
        let codeIsValid = false;
        if (authMethod === userProfileUnderEdit.email) {
            keyStr = EMAIL;
            codeIsValid = await callAPI_emailVerifyCode(code);
            // isCodeAuthenticated = true;
        } else if (authMethod === userProfileUnderEdit.email2) {
            keyStr = EMAIL2;
            codeIsValid = await callAPI_emailVerifyCode(code);
            // isCodeAuthenticated = true;
        } else if (authMethod === userProfileUnderEdit.phone) {
            keyStr = PHONE;
            codeIsValid = await callAPI_phoneVerifyCode(code);
            // isCodeAuthenticated = true;
        } else if (authMethod === userProfileUnderEdit.phone2) {
            keyStr = PHONE2;
            codeIsValid = await callAPI_phoneVerifyCode(code);
            // isCodeAuthenticated = true;
        }

        if (codeIsValid) {
            // ASSERT: call to verify code API succeeded, and the code was verified.
            authenticationFactors[keyStr].authComplete = true;
            putObjectInLocalStorage(lsConsts.AUTHENTICATION_FACTORS, authenticationFactors);

            if (twoFactorsAreAuthenticated()) {
                goToDestinationEditor();
            } else {
                // Open send code page
                const htmlTemplate = retrieveHtmlTemplateForRouteKey(`/userSecuritySendCode`);
                if (htmlTemplate) {
                    const mainContentArea = document.getElementById(MCA).innerHTML = htmlTemplate;

                    const selectedAuthMethod = populateSecurityDropdown();
                    if (selectedAuthMethod === EMAIL ||
                        selectedAuthMethod === EMAIL2) {
                        // Hide the dropdown only meant for phone numbers
                        const textOrVoiceElems = document.querySelectorAll('.security__method');
                        hideElems(textOrVoiceElems, true);
                    }

                    addEventListeners();
                } else {
                    openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
                    setCurrentDirtyEditorName(null);
                    goToLearningHome();
                }
            }
        } else {
            // ASSERT: call to verify code API succeeded, but the code was invalid.
            const statusSpan = document.getElementById('code-verify-status');
            statusSpan.innerHTML = `The code was not valid. Please recheck and try again.`;
            const divNode = statusSpan.parentNode;
            divNode.classList.remove('hidden');
        }
    } catch (err) {
        // ASSERT: the server is down.
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        setCurrentDirtyEditorName(null);
        goToLearningHome();
    }
    hideLoaderMainContentArea();
}

function goToDestinationEditor() {
    let destinationPath = null;

    if (destinationEditor === PASSWORD_PAGE) {
        destinationPath = '/userPassword';
    } else if (destinationEditor === EMAILS_PAGE) {
        destinationPath = '/userEmails';
    } else {
        destinationPath = '/userPhone';
    }

    const dummyLink = document.getElementById('dummy-link');
    dummyLink.setAttribute('href', WS_URL_PRE + destinationPath);
    dummyLink.click();
    return;
}

function twoFactorsAreAuthenticated() {
    let numAuthenticated = 0;
    let retVal = false;
    const keyArray = Object.keys(authenticationFactors);
    keyArray.forEach((key) => {
        const isAuthenticated = authenticationFactors[key].authComplete;
        if (isAuthenticated) {
            numAuthenticated++;
            console.log(`${key} is authenticated. Total authenticated is ${numAuthenticated}`);
        } else {
            console.log(`${key} is not authenticated. Total authenticated is ${numAuthenticated}`);
        }
    });

    if (numAuthenticated >= 2) {
        retVal = true;
        setCurrentDirtyEditorName(null);
        userSession.is2FaAuthenticated = true;
        putUserSessionInLocalStorage(userSession);
        decorateUserIconWithEmail();
    }

    console.log(`retVal: ${retVal}`);

    return retVal;
}

/*
Only returns true if the twilio call succeeds and an http code in the 200's is received.
 */
async function callAPI_emailVerifyCode(code) {
    let retVal = false;
    const email = authMethod;
    const theurl = URL_PRE + `/rest/emailVerifyCode/${email}?code=${code}&csrfToken=${userSession.csrfPreventionToken}`;
    console.log(`Calling API: POST ${theurl}`);

    try {
        const response = await fetch(theurl, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error('Bad response in callAPI_emailVerifyCode()', {
                cause: {response}
            });
        }

        let respJson = await response.json();
        if (respJson.valid) {
            retVal = true;
        }

        console.log(respJson);
    } catch (err) {
        if (!err.cause) {
            // ASSERT: there is no cause, so this exception is due to the server being down.
            throw err;
        } else {
            // ASSERT: this exception is due to an http response that is not in the 200's
            console.dir(err);
        }
    }

    return retVal;
}

/*
Only returns true if the twilio call succeeds and an http code in the 200's is received.
 */
async function callAPI_phoneVerifyCode(code) {
    let retVal = false;
    const phone = authMethod;
    const theurl = URL_PRE + `/rest/phoneVerifyCode/${phone}?code=${code}&csrfToken=${userSession.csrfPreventionToken}`;
    console.log(`Calling API: POST ${theurl}`);

    try {
        const response = await fetch(theurl, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error('Bad response in callAPI_phoneVerifyCode()', {
                cause: {response}
            });
        }

        let respJson = await response.json();
        if (respJson.valid) {
            retVal = true;
        }
        console.log(respJson);
    } catch (err) {
        if (!err.cause) {
            // ASSERT: there is no cause, so this exception is due to the server being down.
            throw err;
        } else {
            // ASSERT: this exception is due to an http response that is not in the 200's
            console.dir(err);
        }
    }

    return retVal;
}

async function callAPI_phoneSendCode(phone) {
    let retVal = false;
    const channel = document.getElementById("chng-pwd-phone-channel").value;
    const theurl = URL_PRE + `/rest/phoneSendCode/${phone}?channel=${channel}&csrfToken=${userSession.csrfPreventionToken}`;
    console.log(`Calling API: POST ${theurl}`);

    const response = await fetch(theurl, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include'
    });

    if (response.ok) {
        retVal = true;
        console.log(`Phone code sent via ${channel}. Http status: ${response.status}`);
    } else {
        console.log(`Failed to send phone code via ${channel}. Http status: ${response.status}`);
    }

    return retVal;
}

async function callAPI_emailSendCode(email) {
    let retVal = false;
    const theurl = URL_PRE + `/rest/emailSendCode/${email}?acctIdStr=${userSession.id}&csrfToken=${userSession.csrfPreventionToken}`;
    console.log(`Calling API: POST ${theurl}`);

    const response = await fetch(theurl, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include'
    });

    if (response.ok) {
        retVal = true;
        console.log(`Email code sent. Http status: ${response.status}`);
    } else {
        console.log(`Failed to send email code. Http status: ${response.status}`);
    }

    return retVal;
}
