export const lsConsts = Object.freeze({
    LS_SHOW_DISTRICT_INIT_VALUE: "true",
    LS_SHOW_DISTRICT_KEY: "EditLotteryResultsShowDistrict",
    LS_CURR_DISTRICT_KEY: "ViewLotteryResultsCurrDistrict",
    LS_CURR_STATE_KEY: "ViewLotteryResultsCurrState",
    LS_STATE_METADATA: "stateMetadata",
    LS_USER_SESSION: "userSession",
    openNodeListKeySuffix: '_OpenNodeList',
    currOpenPathSuffix: '_CurrOpenPath',
    AUTHENTICATION_FACTORS: 'authenticationFactors'
});

// export const URL_PRE = "http://192.168.4.62:9080";
export const URL_PRE = "http://127.0.0.1:9080";

export const WS_URL_PRE = "http://127.0.0.1";
// export const WS_URL_PRE = "http://192.168.4.62";

// export const URL_PRE = "http://192.168.86.61:9080";

export function getUserSessionFromLocalStorage() {
    const userSessionStr = window.localStorage.getItem(`${lsConsts.LS_USER_SESSION}`);
    const userSession = JSON.parse(userSessionStr);
    if (userSession) {
        console.log(`Retrieved userProf from local storage for acct: ${userSession.id} with email: ${userSession.email}`);
    } else {
        console.log(`No user currently logged in.`);
    }

    return userSession;
}

export function getBooleanFromLocalStorage(keyStr) {
    let valueStr = window.localStorage.getItem(keyStr);

    if (!valueStr) {
        // ASSERT: This value has not been set yet, so put in the initialization value.
        if (keyStr === lsConsts.LS_SHOW_DISTRICT_KEY) {
            valueStr = lsConsts.LS_SHOW_DISTRICT_INIT_VALUE;
            window.localStorage.setItem(keyStr, valueStr);
        }
    }

    return JSON.parse(valueStr);
}

export function removeValueFromLocalStorage(keyStr) {
    window.localStorage.removeItem(keyStr);
    console.log(`Removed item from local storage for key: ${keyStr}`);
}

export function removeUserSessionFromLocalStorage() {
    const ddpAcctProf = window.localStorage.getItem(`${lsConsts.LS_USER_SESSION}`);
    window.localStorage.removeItem(`${lsConsts.LS_USER_SESSION}`);
    if (ddpAcctProf) {
        console.log(`Cleared userProf from local storage for acct: ${ddpAcctProf.id}`);
    }
}

export function putUserSessionInLocalStorage(userSession) {
    console.log(`Putting userSession in local storage...`);
    console.log(userSession);

    // Need to strip out the csrfPreventionToken before putting the userSession into local storage.
    const updatedUserSession = {
        id: userSession.id,
        email: userSession.email,
        addr_state: userSession.addr_state,
        congress_district: userSession.congress_district,
        isPwdAuthenticated: userSession.isPwdAuthenticated,
        is2FaAuthenticated: userSession.is2FaAuthenticated
    }

    window.localStorage.setItem(`${lsConsts.LS_USER_SESSION}`, JSON.stringify(updatedUserSession));
}

export function getStateMetadataFromLocalStorage() {
    const stateMetadataStr = window.localStorage.getItem(lsConsts.LS_STATE_METADATA);
    return JSON.parse(stateMetadataStr);
}

export function putHtmlTemplateInLocalStorage(keyTemplatePair) {
    console.log(`Putting html template in local storage for templateKey: ${keyTemplatePair.templateKey}`);
    window.localStorage.setItem(keyTemplatePair.templateKey, keyTemplatePair.htmlTemplate);

    return keyTemplatePair.htmlTemplate;
}

export function getHtmlTemplateFromLocalStorage(key) {
    return window.localStorage.getItem(key);
}

export function getStringFromLocalStorage(keyStr) {
    return window.localStorage.getItem(keyStr);
}

export function getNumDistrictsForState(stateAbbrev, statesMetadata) {
    let stateArray = statesMetadata.states;
    let retVal = null;
    for (let i = 0; i < 50; i++) {
        let currState = stateArray[i];
        if (stateAbbrev === currState.state_abbrev) {
            retVal = currState.num_districts;
            break;
        }
    }

    return retVal;
}

export function putStringInLocalStorage(keyStr, value) {
    console.log(`Putting ${keyStr} into local storage.`);
    window.localStorage.setItem(keyStr, value);
}

export function putBooleanInLocalStorage(keyStr, value) {
    console.log(`Putting ${keyStr} = ${value} into local storage.`);
    window.localStorage.setItem(keyStr, JSON.stringify(value));
}

export function putObjectInLocalStorage(keyStr, value) {
    console.log(`Putting object for key ${keyStr} into local storage.`);
    window.localStorage.setItem(keyStr, JSON.stringify(value));
}

export function getObjectFromLocalStorage(keyStr) {
    console.log(`Getting ${keyStr} from local storage.`);
    const objStr = window.localStorage.getItem(keyStr);
    return JSON.parse(objStr);
}

