const validationOptions = [
    {
        attribute: 'minLength',
        isValid: input => input.value && input.value.length >= parseInt(input.minLength, 10),
        errorMessage: (input, label) => `${label.textContent} needs to be at least ${input.minLength} characters`
    },
    {
        attribute: 'pattern',
        isValid: input =>  {
            const patternRegex = new RegExp(input.pattern);
            let isValid = true;
            if (input.value && input.value.trim().length >= 1) {
                isValid = patternRegex.test(input.value);
            }
            return isValid;
        },
        errorMessage: (input, label) => `Not a valid ${label.textContent}`
    },
    {
        attribute: 'match',
        isValid: input => {
            const formElement = input.parentElement.parentElement.parentElement;
            const matchSelector = input.getAttribute('match');
            const matchedElement = formElement.querySelector(`#${matchSelector}`);
            return matchedElement && matchedElement.value.trim() === input.value;
        },
        errorMessage: (input, label) => {
            const formElement = input.parentElement.parentElement.parentElement;
            const matchSelector = input.getAttribute('match');
            const matchedElement = formElement.querySelector(`#${matchSelector}`);
            const matchedLabel = matchedElement.parentElement.querySelector('label');

            return `${label.textContent} needs to match ${matchedLabel.textContent}`;
        }
    },
    {
        attribute: 'required',
        isValid: input => input.value.trim() !== '',
        errorMessage: (input, label) => `${label.textContent} is required`
    }
];

export function validateForm (formSelector) {
    const formElement = document.querySelector(formSelector);
    return validateAllFormGroups(formElement);
}

function validateAllFormGroups (formToValidate) {
    let isAllFormGroupsValid = true;
    const formGroups = formToValidate.querySelectorAll('.form-group');
    const formGroupArray = Array.from(formGroups);

    formGroupArray.forEach(formGroup => {
        if (!validateSingleFormGroup(formGroup)) {
            isAllFormGroupsValid = false;
        }
    });

    return isAllFormGroupsValid;
}

export function validateSingleFormGroup (formGroup) {
    const label = formGroup.querySelector('.form-group__label');
    const input = formGroup.querySelector('.form-group__input');
    const errorContainer = formGroup.querySelector('.form-group__error-msg');
    const errorIcon = formGroup.querySelector('.form-group__error-icon');

    // Check whether formGroup is hidden
    const formGroupHidden = input.parentElement.parentElement.classList.contains('hidden');
    if (formGroupHidden) {
        // Return indication that there is no error with this formGroup
        return true;
    }

    let isErrorPresent = false;
    for (const option of validationOptions) {
        if (input.hasAttribute(option.attribute) && !option.isValid(input)) {
            isErrorPresent = true;
            errorContainer.textContent = option.errorMessage(input, label);
            errorContainer.classList.remove('hidden');
            errorIcon.classList.remove('hidden');
            input.classList.add('form-group__input-notvalid');
        }
    }

    if (!isErrorPresent) {
        errorContainer.textContent = '';
        errorContainer.classList.add('hidden');
        errorIcon.classList.add('hidden');
        input.classList.remove('form-group__input-notvalid');
    }

    return !isErrorPresent;
}

export function setErrorOnFormGroup (formGroupId, errorMessage) {
    const formGroupElement = document.getElementById(formGroupId);
    const label = formGroupElement.querySelector('.form-group__label');
    const input = formGroupElement.querySelector('.form-group__input');
    const errorContainer = formGroupElement.querySelector('.form-group__error-msg');
    const errorIcon = formGroupElement.querySelector('.form-group__error-icon');

    errorContainer.textContent = errorMessage;
    errorContainer.classList.remove('hidden');
    errorIcon.classList.remove('hidden');
    input.classList.add('form-group__input-notvalid');
}