import {getObjectFromLocalStorage, putObjectInLocalStorage, removeValueFromLocalStorage} from "./ddpLocalStorage.js";
import {
    callAPI_logout,
    initTabWithUserSession,
    readInUserSessionFromLocalStorage,
    removeUserSessionFromTab,
    userSession
} from "./userActivities.js";
import {hideLoader, showLoader} from "./modalDialog.js";

const OPEN_TABS = 'ddpOpenTabs';
const OPEN_TAB_PRE = 'ddpTab';
const USER_LOGIN_OCCURRED = 'USER_LOGIN_OCCURRED';
const USER_LOGOUT_OCCURRED = 'USER_LOGOUT_OCCURRED';
const userSessionStateBC = new BroadcastChannel("userSessionState");

const openTab =
    {
        inactivityTOSetAt: 0,
        timeoutMillis: 0,
        inactivityWarningTimeoutId: null,
        hasTimedOutForInactivity: false
    };

userSessionStateBC.onmessage = handleUserSessionStateChange;

export function initTabManager() {
    window.addEventListener("beforeunload", handleBeforeunload);
}

/*
In the case of a page refresh this method restores the tabId into the window object. In the case
of a new page load this method sets the tab's Id as the UTC timestamp now. This method also ensures
that the tabId is in the OPEN_TABS array in localStorage.
 */
export function initTabId() {
    if (window.sessionStorage.tabId) {
        console.log(`load after refresh - tabId: ${window.sessionStorage.tabId}`);
        window.tabId = window.sessionStorage.tabId.toString();
        window.sessionStorage.removeItem("tabId");
    } else {
        window.tabId = Date.now().toString();
        console.log(`initial load - tabId: ${window.tabId}`);
    }

    let currOpenTabArray = getObjectFromLocalStorage(OPEN_TABS);
    if (!currOpenTabArray) {
        currOpenTabArray = [];
    }
    const isCurrTabInArray = currOpenTabArray.includes(window.tabId);

    if (!isCurrTabInArray) {
        currOpenTabArray.push(window.tabId);
    }

    putObjectInLocalStorage(OPEN_TABS, currOpenTabArray);
}

export function getTabId() {
    return window.tabId;
}

/*
    This method ensures that when an unload occurs during a page refresh that we won't lose
    the existing tabId.
 */
function handleBeforeunload(){
    window.sessionStorage.tabId = getTabId();
    console.log(`before unload - tabId: ${window.sessionStorage.tabId}`);

    // In case this unload is occurring due to the user clicking the page refresh button call
    // this method to do a provisional logout.
    handleTabClosing();
}

export function getCurrTabInfo() {
    return getObjectFromLocalStorage(getCurrTabKey());
}

function getCurrTabKey() {
    const currTabId = getTabId();
    return OPEN_TAB_PRE + "_" + currTabId;
}

function getTabKey(tabId) {
    return OPEN_TAB_PRE + "_" + tabId;
}

/*
This method is called if a new login userSession is established or an inactivity timeout gets reset due to user activity.
This method updates the tab info in local storage with the new timeout state information.
 */
export function updateInactivityTOInfoForTab(currTimeMillis, timeoutMillis, inactivityWarningTimeoutId) {
    let currTab = getCurrTabInfo();

    if (currTab) {
        currTab.inactivityTOSetAt = currTimeMillis;
        currTab.timeoutMillis = timeoutMillis;
        currTab.inactivityWarningTimeoutId = inactivityWarningTimeoutId;
        currTab.hasTimedOutForInactivity = false;
    } else {
        currTab = {
            inactivityTOSetAt: currTimeMillis,
            timeoutMillis: timeoutMillis,
            inactivityWarningTimeoutId: inactivityWarningTimeoutId,
            hasTimedOutForInactivity: false
        }
    }

    putObjectInLocalStorage(getCurrTabKey(), currTab);
}

export function updateTabInfoForNoUserSession() {
    let currTab = getCurrTabInfo();

    if (!currTab) {
        currTab = {
            inactivityTOSetAt: 0,
            timeoutMillis: 0,
            inactivityWarningTimeoutId: null,
            hasTimedOutForInactivity: true
        }
    } else {
        currTab.inactivityTOSetAt = 0;
        currTab.timeoutMillis = 0;
        currTab.inactivityWarningTimeoutId = null;
        currTab.hasTimedOutForInactivity = true;
    }

    putObjectInLocalStorage(getCurrTabKey(), currTab);
}

async function handleTabClosing() {
    // Remove tab from OPEN_TABS, and remove the tabInfo object from localStorage.
    const currOpenTabIdArray = getObjectFromLocalStorage(OPEN_TABS);
    const currTabId = getTabId();
    const newOpenTabIdArray = currOpenTabIdArray.filter(item => item !== currTabId);
    putObjectInLocalStorage(OPEN_TABS, newOpenTabIdArray);
    removeValueFromLocalStorage(getCurrTabKey());

    if (userSession && userSession.isPwdAuthenticated) {
        if (isThisTabLastToTimeout()) {
            /*
            Unload is occurring on the current tab, which is the only DDP tab open. This unload may be occurring,
            because the user is closing the tab, or because they clicked the page refresh button. There is no way
            to know reliably which is the case, so we have to call the logout API in case they're closing the last
            tab. In case they are just refreshing the page we need a way to maintain the user's login session. To
            do that we generate a one-time use uuid, which we call the provisionalLogoutToken. This uuid will be
            placed in sessionStorage and sent in the logout API call. The presence of the provisionalLogoutToken
            in session storage triggers code in script.js to do a login during init process passing that uuid along.
            The login API will retrieve from the database the provisionalLogoutToken that we are generating here for
            the logout call, and comparing with the one from sessionStorage sent on the login API call. If they
            match and the timestamp in the database for the uuid is not more than 10 seconds old, then the login
            will succeed and the login userSession restored.
             */

            // Generate a random UUID
            const provisionalLogoutToken = generateUuid();
            console.log(`provisionalLogoutToken: ${provisionalLogoutToken}`);
            if (provisionalLogoutToken) {
                window.sessionStorage.provisionalLogoutToken = provisionalLogoutToken;
                window.sessionStorage.emailAddr = userSession.email;
                console.log(`before unload - email: ${userSession.email} provisionalLogoutToken: ${window.sessionStorage.provisionalLogoutToken}`);
            }

            showLoader();
            try {
                await callAPI_logout(userSession.id, provisionalLogoutToken);
            } catch (err) {
                console.dir(err);
            }
            hideLoader();
            removeUserSessionFromTab();
        }
    }
}

function generateUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
}

function handleUserSessionStateChange(messageEvent) {
    console.log(`userSessionStateBC.onmessage: ${messageEvent.data}`);

    if (messageEvent.data === USER_LOGIN_OCCURRED) {
        readInUserSessionFromLocalStorage();
        initTabWithUserSession();
    } else if (messageEvent.data === USER_LOGOUT_OCCURRED) {
        removeUserSessionFromTab();
    }

}

export function notifyUserLoginOccurred() {
    userSessionStateBC.postMessage(USER_LOGIN_OCCURRED);
}

export function notifyUserLogoutOccurred() {
    userSessionStateBC.postMessage(USER_LOGOUT_OCCURRED);
}

export function isThisTabLastToTimeout() {
    const currOpenTabArray = getObjectFromLocalStorage(OPEN_TABS);
    console.log(currOpenTabArray);
    const currTabId = getTabId();
    console.log(`>> isThisTabLastToTimeout() for currTabId: ${currTabId}`);
    let currTabInfo = getCurrTabInfo();
    let retVal = true;

    /*
    possibleLastTabsStanding definition:
    Tabs that have not been marked as timed out, but that either have timed out or are within 2 seconds of timing
    out. The current tab is in this group because its timeout has fired. This code will weigh the current tab
    against any tabs that are within 2 seconds of timing out. The tab within this group that was opened most
    recently will be deemed the last tab to timeout, granting it the responsibility to open the timeout popup
    and call the logout API if the user chooses to logout or not respond before the logout timeout fires. If
    no other tabs are within 2 seconds of timing out, then this tab will be marked as timed out leaving it to
    another active tab to eventually timeout an open the inactivity popup.
     */
    const possibleLastTabsStanding = [];

    let atLeastOneTabIsMoreThanTwoSecondsFromTimeout = false;

    for (let i = 0; i < currOpenTabArray.length; i++) {
        let otherTabId = currOpenTabArray[i];
        if (otherTabId !== currTabId) {
            let otherTabInfo = getObjectFromLocalStorage(getTabKey(otherTabId));

            if (!otherTabInfo.hasTimedOutForInactivity) {
                if (isTabWithinThisTimeOfTimeout(otherTabInfo, 2000)) {
                    console.log(`Tab ${otherTabId} is within 2 seconds of timing out.`);
                    possibleLastTabsStanding.push(otherTabId);
                } else {
                    atLeastOneTabIsMoreThanTwoSecondsFromTimeout = true;
                    retVal = false;
                    console.log(`Tab ${otherTabId} is more than 2 seconds from timing out.`);
                    break;
                }
            } else {
                console.log(`Tab ${otherTabId} has timed out.`);
            }
        }
    }

    if (atLeastOneTabIsMoreThanTwoSecondsFromTimeout) {
        // ASSERT: On of the other tabs is still active, so the currTab cannot be the last tab to timeout.

        currTabInfo.hasTimedOutForInactivity = true;
        currTabInfo.inactivityTOSetAt = 0;
        currTabInfo.timeoutMillis = 0;
        currTabInfo.inactivityWarningTimeoutId = null;

        console.log(`Setting tab info as timed out for ${currTabId}. isThisTabLastToTimeout() retVal is ${retVal}.`);
        putObjectInLocalStorage(getCurrTabKey(), currTabInfo);
    } else {
        // ASSERT: There are no other tabs still active, so determine if there are any possibleLastTabsStanding tabs and
        // determine which will be given last tab standing status.

        if (possibleLastTabsStanding.length > 0) {
            console.log(`In addition to currTab ${currTabId} there are ${possibleLastTabsStanding.length} other tabs in the running for possibleLastTabsStanding.`);

            //Pick the tab in the group of possibleLastTabsStanding with the oldest tabId (i.e. most recently opened tab) to be the last tab standing.

            // Start with currTab and loop through others to find the most recently opened tab to be the winner.
            let currWinner = currTabId;
            let currWinnerCreationTimeMillis = parseInt(currTabId, 10);
            console.log(`currWinnerCreationTimeMillis: ${currWinnerCreationTimeMillis}`);

            for (let i = 0; i < possibleLastTabsStanding.length; i++) {
                let currOtherTabId = possibleLastTabsStanding[i];
                let otherTabCreationTimeMillis = parseInt(currOtherTabId, 10);
                console.log(`otherTabCreationTimeMillis: ${otherTabCreationTimeMillis}`);
                if (otherTabCreationTimeMillis > currWinnerCreationTimeMillis) {
                    currWinner = currOtherTabId;
                    currWinnerCreationTimeMillis = otherTabCreationTimeMillis;
                    console.log(`currWinner changed to ${currWinner}`);
                }
            }

            if (currWinner !== currTabId) {
                retVal = false;
                console.log(`Current Tab ${currTabId} is not the last tab standing. The winner is ${currWinner}.`);
            } else {
                console.log(`Current Tab ${currTabId} is the last tab standing. isThisTabLastToTimeout() retVal is ${retVal}.`);
            }
        } else {
            console.log(`There are no other possibleLastTabsStanding tabs within 2 seconds of timeout. Current Tab ${currTabId} is the last tab standing. isThisTabLastToTimeout() retVal is ${retVal}.`);
        }
    }

    return retVal;
}

export function isTabWithinThisTimeOfTimeout(tabInfo, timeWindowMillis) {
    let retVal = false;
    if (!tabInfo.hasTimedOutForInactivity) {
        const timeoutSetAtTime = tabInfo.inactivityTOSetAt;
        console.log(`timeoutSetAtTime: ${timeoutSetAtTime}`);
        const currTimeMillis = Date.now();
        console.log(`currTimeMillis: ${currTimeMillis}`);
        const millisSinceTimeoutSet = currTimeMillis - timeoutSetAtTime;
        console.log(`millisSinceTimeoutSet: ${millisSinceTimeoutSet}`);
        const timeoutDurationMillis = tabInfo.timeoutMillis;
        console.log(`timeoutDurationMillis: ${timeoutDurationMillis}`);
        const timeRemainingMillis = timeoutDurationMillis - millisSinceTimeoutSet;
        console.log(`timeRemainingMillis: ${timeRemainingMillis}`);

        retVal = timeRemainingMillis < timeWindowMillis;
    } else {
        console.log(`tab has already timed out.`);
    }

    console.log(`isTabWithinThisTimeOfTimeout() retVal: ${retVal}.`);
    return retVal;
}

function getTabCreationTimeMillis(tabKey) {
    console.log(`>> getTabCreationTimeMillis() for tabKey: ${tabKey}`);
    const tabIdStr = tabKey.substring(OPEN_TAB_PRE.length, tabKey.length);
    let tabCreationTimeMillis = parseInt(tabIdStr, 10);
    console.log(`<< getTabCreationTimeMillis() retVal: ${tabCreationTimeMillis}`);
    return tabCreationTimeMillis;
}