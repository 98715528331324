import {urlRoutes} from "./urlRoutes.js";
import {
    closeModalDialog,
    getModalDialog,
    hideLoaderMainContentArea,
    openErrorMessageModal,
    openModalDialog,
    showLoaderMainContentArea
} from "./modalDialog.js";
import {
    getHtmlTemplateFromLocalStorage,
    getObjectFromLocalStorage,
    getStringFromLocalStorage,
    lsConsts,
    putHtmlTemplateInLocalStorage,
    putObjectInLocalStorage,
    putStringInLocalStorage,
    removeValueFromLocalStorage,
    WS_URL_PRE
} from "./ddpLocalStorage.js";
import {smallScreen} from "./mediaQueries.js";
import {
    initUiEditRankVote,
    initUiLotteryWinnersEditMyRanking,
    initUiLotteryWinnersViewCurrentRanking,
    initUiViewCurrentRanking
} from "./rankVoting.js";
import {initUiToolsRunLottery, initUiToolsTabulateIssueVotes, initUiToolsTabulateRepVotes} from "./adminTools.js";
import {
    initUiUserEmails,
    initUiUserPassword,
    initUiUserPersonalInfo,
    initUiUserPhone
} from "./userProfEditController.js";
import {initUiUserSecurity} from "./securityWizardController.js";
import {messages} from "./messages.js";
import {initUiDonatePage} from "./userActivities.js";

// This object is fleshed out in function popLeftNavMetadata(). It contains all the metadata necessary to expand
// and highlight the appropriate nodes in the leftNav when a given page is requested/clicked for the
// main-content-area. It also contains metadata used to populate the breadcrumbs area.
const leftNavMetadata = {};

// Property name for the leftNavMetadata value holding the array of ancestor objects, which hold the information
// used to expand the appropriate leftNav nodes for a given page, as well as populate the ancestor breadcrumbs.
const ANCESTORS = 'ancestors';

// Property name for leftNavMetadata value holding the id of the leftNav element that should be highlighted
// when the given page is loaded into the main-content-area.
const SELECTABLE = 'selectable-elem';

// Property name for leftNavMetadata value holding the id of the currently selected/highlighted node in the leftNav.
// This is used for removing the highlighting of the previously selected leftNav node when a new page is clicked.
const CURR_SELECTED_LEFT_NAV_NODE = 'currSelectedNavNode';

let leftNavFullOpen = true;

let currentDirtyEditorName = null;

const CLNP = 'currLeftNavPath';
const LNP = 'leftNavPath';
const LEFT_NAV_ID = 'left-nav';
export const LEFT_NAV_CLASS = '.left-nav';
const LEFT_NAV_BUTTON_ID = 'left-nav-button';
export const MCA = 'main-content-area';
const MODAL_DIALOG = 'modal-dialog';
const NAV_SELECTED_CLASS = 'nav-selected';
const ANCHOR_TEXT = 'anchorText';
export const LEARNING = 'learning';
export const DOING = 'doing';
export const ADMIN_TOOLS = 'adminTools';
export const USER = 'user';
export const USER_PERSONAL_INFO = 'userPersonalInfo';
export const USER_NAV_HIGHLIGHTABLE_ID = 'user-name';
const ABOUT_US= 'about';
const CONTACT_US= 'contact';
const PRIVACY = 'privacy';
export const LEARNING_HOME_PATH = '/learningHome';
const DOING_HOME_PATH = '/doingHome';
const ADMIN_TOOLS_HOME_PATH = '/adminToolsHome';
const BC_HOME_BUTTON = 'bc-home-btn';
const FROM_LEFT_NAV_PARENT_PREFIX = '/fromLeftNavParentNode';
export let isInDemoMode = true;
export const LEFT_NAV_FOOTER_CLASS = '/leftNavFooter';
const LEFT_NAV_FOOTER_CONTAINER = '.footer-container'

export async function initLeftNavEntities() {
    let routeKey = '/' + DOING;
    await initLeftNavForKey(routeKey);

    routeKey = '/' + ADMIN_TOOLS;
    await initLeftNavForKey(routeKey);

    routeKey = '/' + USER;
    await initLeftNavForKey(routeKey);

    routeKey = '/' + LEARNING;
    await initLeftNavForKey(routeKey);

    console.log(`Below is leftNavMetadata after initLeftNavForKey run against doing, adminTools, user, and learning.`);
    console.dir(leftNavMetadata);

    addEventListenerToDetailsElems();

    /*
    ASSERT: The last leftNav area we initialized above is the one for Learning, because
    it's the leftNav area we want initially presented when the website is first loaded.
     */

    // Highlight the Learning link the top main-nav area to indicate it's the part of
    // the website currently presented.
    document.getElementById(LEARNING + '-button').classList.add('curr-nav');

    addNonLeftNavMetadata();

    initCurrOpenPagesForNavs();

    // Set current leftNav path to null, to trigger the changeLeftNavForUrl() to call
    // the switchLeftNav() method, which will pull in the footer template.
    leftNavMetadata[CLNP] = null;
    urlLocationHandler(null);
}

export function initCurrOpenPagesForNavs() {
    putStringInLocalStorage(LEARNING + lsConsts.currOpenPathSuffix, '/' + LEARNING + 'Home');
    putStringInLocalStorage(DOING + lsConsts.currOpenPathSuffix, '/' + DOING + 'Home');
    putStringInLocalStorage(ADMIN_TOOLS + lsConsts.currOpenPathSuffix, '/' + ADMIN_TOOLS + 'Home');
    putStringInLocalStorage(USER + lsConsts.currOpenPathSuffix, '/' + USER_PERSONAL_INFO);
}

async function initLeftNavForKey(routeKey) {
    let urlRoute = urlRoutes[routeKey];
    let htmlTemplate = await fetch(urlRoute.template).then((response) => response.text());

    let keyTemplatePair = {
        templateKey: urlRoute.template,
        htmlTemplate: htmlTemplate
    };

    // Fetch the nav html and put it in local storage, so the user can quickly go between different
    // leftNav areas of the website without a fetch from the server.
    putHtmlTemplateInLocalStorage(keyTemplatePair);

    // We don't necessarily want this html in the leftNav area, but we need to temporarily load it
    // into the dom, so the popLeftNavMetadata() method can scrape the information from the html.
    putHtmlTemplateInLeftNav(routeKey);

    // Scrape the leftNavMetadata from the loaded leftNav html.
    popLeftNavMetadata(routeKey);
}

function popLeftNavMetadata(routeKey) {
    leftNavMetadata[CLNP] = routeKey.substring(1, routeKey.length);
    const pathArray = [];

    const rootDiv = document.getElementById(LEFT_NAV_ID);
    const topUlNode = rootDiv.firstChild;
    processUlNode(pathArray, topUlNode);
}

export function getCurrentLeftNavPath() {
    return leftNavMetadata[CLNP];
}

/*
This is a recursive method that walks through the nodes of the leftNav html files and fleshes out
the module scoped leftNavMetadata object, which is needed for managing the leftNav and breadcrumbs.
 */
function processUlNode(pathArray, ulNode) {
    let childArray = ulNode.childNodes;

    for (let i = 0; i < childArray.length; i++) {
        let elem = childArray[i];
        if(elem.nodeName === 'LI') {
            let liChildArray = elem.childNodes;
            for (let j = 0; j < liChildArray.length; j++) {
                let liChildElem = liChildArray[j];
                if (liChildElem.nodeName === 'DETAILS') {
                    processDetailsMetadata(pathArray, liChildElem);
                } else if (liChildElem.nodeName === 'A') {
                    processAnchorMetadata(pathArray, liChildElem);
                }
            }
        }
    }
}

function processDetailsMetadata(pathArray, elemDetails) {
    const childArray = elemDetails.childNodes;
    let ulNode = null;

    for (let i = 0; i < childArray.length; i++) {
        let currChild = childArray[i];
        if (currChild.nodeName === 'SUMMARY') {
            const summaryChildArray = currChild.childNodes;
            for (let j = 0; j < summaryChildArray.length; j++) {
                let currSummaryChild = summaryChildArray[j];
                if (currSummaryChild.nodeName === 'A') {
                    let currPath = currSummaryChild.getAttribute('href');
                    if (currPath.startsWith(FROM_LEFT_NAV_PARENT_PREFIX)) {
                        // This accounts for the fact that parent (non-leaf) nodes in left-nav
                        // templates have a prefix in their paths to drive the logic in urlLocationHandler()
                        // for making tap on collapsed node first just expand the node rather than open page.
                        // We have to remove that part for the sake of populating leftNavMetadata.
                        currPath = currPath.substring(FROM_LEFT_NAV_PARENT_PREFIX.length);
                    }

                    let ancestorId = currPath.substring(1, currPath.length);
                    const newMetadataObj = {};
                    newMetadataObj[ANCESTORS] = [...pathArray];
                    newMetadataObj[LNP] = leftNavMetadata[CLNP];
                    newMetadataObj[SELECTABLE] = ancestorId + "-summary";

                    // Get the anchor text
                    const anchorChildArray = currSummaryChild.childNodes;
                    for (let j = 0; j < anchorChildArray.length; j++) {
                        let currAnchorChild = anchorChildArray[j];
                        if (currAnchorChild.nodeName === '#text') {
                            newMetadataObj[ANCHOR_TEXT] = currAnchorChild.nodeValue;
                        }
                    }

                    leftNavMetadata[currPath] = newMetadataObj;

                    const ancestorInfo = {
                        ancestorId: ancestorId,
                        ancestorAnchorText: newMetadataObj[ANCHOR_TEXT]
                    };
                    pathArray.push(ancestorInfo);
                }
            }
        } else if (currChild.nodeName === 'UL') {
            ulNode = currChild;
        }
    }

    // ASSERT: after the above loop we have already processed the anchor element, so
    // now recurse into the next UL list to process more details elements or
    // leaf anchor elements under the current details node.
    if (ulNode) {
        processUlNode(pathArray, ulNode);
    }

    // Pop the currPath off the pathArray, so anchors under the next details
    // element won't have a bad path in its ancestors value.
    pathArray.pop();
}

function handleToggleDetailsNode(event) {
    console.log(`Details node toggled for ${event.currentTarget.id}`);
    saveOpenNodeList(leftNavMetadata[CLNP]);
}

function processAnchorMetadata(pathArray, elemAnchor) {
    const currPath = elemAnchor.getAttribute('href');
    const routeId = currPath.substring(1, currPath.length);
    const newMetadataObj = {};
    newMetadataObj[ANCESTORS] = [...pathArray];
    newMetadataObj[LNP] = leftNavMetadata[CLNP];
    newMetadataObj[SELECTABLE] = routeId + "-li";

    // Get the anchor text
    const anchorChildArray = elemAnchor.childNodes;
    for (let j = 0; j < anchorChildArray.length; j++) {
        let currAnchorChild = anchorChildArray[j];
        if (currAnchorChild.nodeName === '#text') {
            newMetadataObj[ANCHOR_TEXT] = currAnchorChild.nodeValue;
        }
    }

    leftNavMetadata[currPath] = newMetadataObj;
}

/*
Any page that may be requested must have an entry in the leftNavMetadata. Most of those
entries are created by the popLeftNavMetadata() method, which scrapes the metadata from
the various leftNav html files. The home pages for the various leftNav entities do not
have entries in the leftNav html, so this method is where that information gets
manually populated into leftNavMetdata.
 */
function addNonLeftNavMetadata() {
    let newMetadataObj = {};
    newMetadataObj[ANCESTORS] = null;
    newMetadataObj[LNP] = LEARNING;
    newMetadataObj[SELECTABLE] = null;

    leftNavMetadata[LEARNING_HOME_PATH] = newMetadataObj;

    newMetadataObj = {};
    newMetadataObj[ANCESTORS] = null;
    newMetadataObj[LNP] = DOING;
    newMetadataObj[SELECTABLE] = null;

    leftNavMetadata[DOING_HOME_PATH] = newMetadataObj;

    newMetadataObj = {};
    newMetadataObj[ANCESTORS] = null;
    newMetadataObj[LNP] = ADMIN_TOOLS;
    newMetadataObj[SELECTABLE] = null;

    leftNavMetadata[ADMIN_TOOLS_HOME_PATH] = newMetadataObj;

    newMetadataObj = {};
    newMetadataObj[ANCESTORS] = null;
    newMetadataObj[LNP] = LEARNING;
    newMetadataObj[SELECTABLE] = null;

    leftNavMetadata['/' + LEARNING] = newMetadataObj;

    newMetadataObj = {};
    newMetadataObj[ANCESTORS] = null;
    newMetadataObj[LNP] = DOING;
    newMetadataObj[SELECTABLE] = null;

    leftNavMetadata['/' + DOING] = newMetadataObj;

    newMetadataObj = {};
    newMetadataObj[ANCESTORS] = null;
    newMetadataObj[LNP] = ADMIN_TOOLS;
    newMetadataObj[SELECTABLE] = null;

    leftNavMetadata['/' + ADMIN_TOOLS] = newMetadataObj;

    newMetadataObj = {};
    newMetadataObj[ANCESTORS] = null;
    newMetadataObj[LNP] = LEARNING;
    newMetadataObj[SELECTABLE] = null;

    leftNavMetadata['/' + ABOUT_US] = newMetadataObj;

    newMetadataObj = {};
    newMetadataObj[ANCESTORS] = null;
    newMetadataObj[LNP] = LEARNING;
    newMetadataObj[SELECTABLE] = null;

    leftNavMetadata['/' + CONTACT_US] = newMetadataObj;

    newMetadataObj = {};
    newMetadataObj[ANCESTORS] = null;
    newMetadataObj[LNP] = LEARNING;
    newMetadataObj[SELECTABLE] = null;

    leftNavMetadata['/' + PRIVACY] = newMetadataObj;
}

function putHtmlTemplateInLeftNav(routeKey) {
    const urlRoute = urlRoutes[routeKey];
    const htmlTemplate = getHtmlTemplateFromLocalStorage(urlRoute.template);

    document.getElementById(LEFT_NAV_ID).innerHTML = htmlTemplate;
}

export function setCurrentDirtyEditorName(pageId) {
    currentDirtyEditorName = pageId;
}

function isExternalSite(theUrl) {
    let retVal = false;
    if (!(theUrl.startsWith('http://127.0.0.1') ||
        theUrl.startsWith('http://192.168.4.62') ||
        theUrl.startsWith('https://ddpus.org') ||
        theUrl.startsWith('http://localhost:1234') ||
        theUrl.startsWith('http://9.239.64'))) {
        retVal = true;
    }

    return retVal;
}

export function urlRoute(event) {
    event = event || window.event;
    event.preventDefault();

    // If the user has unsaved changes in an editor, get confirmation on
    // whether they want to leave the page without saving the changes.
    if (currentDirtyEditorName) {
        console.log(`There are unsaved changes.`);
        let intendedTarget = event.target.href;

        openUnsavedChangesModal(intendedTarget, window.location.pathname);
        return;
    } else {
        console.log(`No unsaved changes. Go to clicked link.`);
    }

    // If it's a link outside DDP send it to new tab and return.
    if (isExternalSite(event.target.href)) {
        window.open(event.target.href, "_blank");
        return;
    }

    let isFromLeftNavParentNode = false;
    let location = event.target.href;

    // Strip the location down to just the path portion that comes after the domain.
    const firstDoubleSlashIx = location.indexOf('//');
    location = location.substring(firstDoubleSlashIx + 2, location.length);
    const firstSlashIx = location.indexOf('/');
    location = location.substring(firstSlashIx, location.length);

    if (location.startsWith(FROM_LEFT_NAV_PARENT_PREFIX)) {
        isFromLeftNavParentNode = true;
        location = location.substring(FROM_LEFT_NAV_PARENT_PREFIX.length);
    }

    // This logic is for left-nav in smallScreen mode to achieve the feature where tapping a node that is not yet
    // expanded will just expand the node. A tap on an expanded node would then take you to the page.
    // This makes it more efficient for the user to expand nodes to see what's in the table of contents.
    // If the node for this link is not expanded yet, then let the other code expand it and do not load
    // the page here in this method.
    const detailsElemId = location.substring(1, location.length);
    if (smallScreen && isFromLeftNavParentNode && !isNodeAlreadyOpen(detailsElemId)) {
        return;
    }

    window.history.pushState({}, "", location);

    urlLocationHandler(event.target);
}

function openUnsavedChangesModal(intendedTarget, currOpenPage) {
    const modalDialog = getModalDialog();

    modalDialog.innerHTML = getHtmlTemplateFromLocalStorage(urlRoutes['/unsavedChangesConfirmation'].template);

    openModalDialog();

    const discardChangesBtn = document.getElementById("confirmation-modal__discard-changes");
    discardChangesBtn.addEventListener("click", function () {
        currentDirtyEditorName = null;
        closeModalDialog();

        if (typeof intendedTarget == 'string') {
            const lastSlashIx = intendedTarget.lastIndexOf('/');
            const intendedTargetLink = intendedTarget.substring(lastSlashIx, intendedTarget.length);
            let intendedTargetLinkId = null;
            let targetClickable = null;
            if (intendedTargetLink === LEARNING_HOME_PATH) {
                const bcHomeElem = document.getElementById(BC_HOME_BUTTON);
                bcHomeElem.setAttribute('href', LEARNING_HOME_PATH);
                targetClickable = bcHomeElem;
            } else if (intendedTargetLink === ADMIN_TOOLS_HOME_PATH) {
                const bcHomeElem = document.getElementById(BC_HOME_BUTTON);
                bcHomeElem.setAttribute('href', ADMIN_TOOLS_HOME_PATH);
                targetClickable = bcHomeElem;
            } else {
                intendedTargetLinkId = intendedTargetLink.substring(1, intendedTargetLink.length) + "-link";
                targetClickable = document.getElementById(intendedTargetLinkId);
            }

            targetClickable.click();
        } else {
            intendedTarget.click();
        }

    });

    const stayOnPageBtn = document.getElementById("confirmation-modal__remain");
    stayOnPageBtn.addEventListener("click", function () {
        closeModalDialog();
        changeLeftNavForUrl(currOpenPage);
        if (smallScreen && leftNavFullOpen) {
            handleToggleLeftNav();
        }
    });

    stayOnPageBtn.addEventListener("keypress", function(event) {
        // If the user presses the "Enter" key on the keyboard
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            // Trigger the button element with a click
            stayOnPageBtn.click();
        }
    });

    stayOnPageBtn.focus();
}

export function routeDirectUrlAddress() {
    const pathName = window.location.pathname;

    console.log(`pathName: ${pathName}`);
}

export async function urlLocationHandler (eventAnchor){
    let location = window.location.pathname;

    if (location.length === 0) {
        location = "notFound";
    }

    if (location === '/') {
        location = LEARNING_HOME_PATH;
    }

    let route = urlRoutes[location];

    if (!route) {
        // ASSERT: User must have typed a bad url into the address bar after our domain. Return
        // the route for 404 file not found.
        route = urlRoutes.notFound;
    }

    if (isInDemoMode && route.notForDemo) {
        route = urlRoutes.notFound;
    }

    // This is the dom element where the html template should be added.
    let domDestination = route.destination;
    if (!domDestination) {
        domDestination = MCA;
    } else if (domDestination === MODAL_DIALOG || domDestination === LEFT_NAV_ID) {
        route = urlRoutes.notFound;
        domDestination = MCA;
    }

    // If one is needed, this is the initialize UI function that will
    // be invoked after the html template is added to the dom, in order
    // to retrieve data from the API layer and add event listeners.
    const initUiFunc = route.initUiFunc;

    console.log(`domDestination: ${domDestination}`);
    console.log(`initUiFunc: ${initUiFunc}`);

    if (route !== urlRoutes.notFound) {
        changeLeftNavForUrl(location);
        changeBreadcrumbsForUrl(location);
    }

    let htmlTemplate = null;

    try {
        if (domDestination && domDestination !== LEFT_NAV_ID) {
            if (route.keepInLocalStorage) {
                console.log(`Getting ${route.template} from local storage.`);
                htmlTemplate = getHtmlTemplateFromLocalStorage(route.template);
            } else {
                showLoaderMainContentArea();
                if (isInDemoMode && route.demoIsDiffPage) {
                    const dotIx = route.template.indexOf('.');
                    let template = route.template.substring(0, dotIx) + '_demo.html';
                    htmlTemplate = await retrieveHtmlTemplateForPath(template);
                } else {
                    htmlTemplate = await retrieveHtmlTemplateForPath(route.template);
                }
            }

            document.getElementById(domDestination).innerHTML = htmlTemplate;
        }

        if (initUiFunc) {
            showLoaderMainContentArea();
            await invokeInitUiFunc(initUiFunc);
        }

        if (smallScreen && leftNavFullOpen) {
            await handleToggleLeftNav();
        }
    } catch (err) {
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        setCurrentDirtyEditorName(null);
        goToLearningHome();
    }
    hideLoaderMainContentArea();

    const scrollableDiv = document.querySelector('.main-content-area-container');
    scrollableDiv.scrollTop = 0;
}

function addEventListenerToDetailsElems() {
    const detailsElems = document.querySelectorAll("details");
    detailsElems.forEach((detailsElem) => {
        detailsElem.addEventListener("toggle", handleToggleDetailsNode);
    });
}

function reOpenNodes(leftNavId) {
    const openNodeList = getObjectFromLocalStorage(leftNavId + lsConsts.openNodeListKeySuffix);
    if (openNodeList) {
        for (let i = 0; i < openNodeList.length; i++) {
            let currNodeId = openNodeList[i];
            document.getElementById(currNodeId).setAttribute("open", true);
        }
    }
}

export function closeAllNodes(leftNavId) {
    const openNodeList = getObjectFromLocalStorage(leftNavId + lsConsts.openNodeListKeySuffix);
    if (openNodeList) {
        for (let i = 0; i < openNodeList.length; i++) {
            let currNodeId = openNodeList[i];
            document.getElementById(currNodeId).removeAttribute("open");
        }
    }
}

function saveOpenNodeList(leftNavId) {
    // Clear the previous list of open nodes for the current leftNav to keep this list
    // in sync.
    removeValueFromLocalStorage(leftNavId + lsConsts.openNodeListKeySuffix);

    const rootDiv = document.getElementById(LEFT_NAV_ID);
    const topUlNode = rootDiv.firstChild;
    const openNodeIds = [];

    findOpenNodesUl(openNodeIds, topUlNode);

    putObjectInLocalStorage(leftNavId + lsConsts.openNodeListKeySuffix, openNodeIds);
}

function findOpenNodesDetails(openNodeIds, elemDetails) {
    let isOpen = elemDetails.open;
    if (isOpen) {
        openNodeIds.push(elemDetails.getAttribute("id"));
    }

    const childArray = elemDetails.childNodes;
    let ulNode = null;

    for (let i = 0; i < childArray.length; i++) {
        let currChild = childArray[i];
        if (currChild.nodeName === 'UL') {
            ulNode = currChild;
        }
    }

    // ASSERT: after the above loop we have the next UL list to process more details elements.
    if (ulNode) {
        findOpenNodesUl(openNodeIds, ulNode);
    }
}

function isNodeAlreadyOpen(detailsElemId) {
    if (!document.getElementById(detailsElemId)) {
        // ASSERT: This must be a li element, so return true
        // because we always want to load the page for leaf nodes.
        return true;
    }

    const leftNavId = leftNavMetadata[CLNP];
    const openNodeList = getObjectFromLocalStorage(leftNavId + lsConsts.openNodeListKeySuffix);
    if (openNodeList) {
        return openNodeList.includes(detailsElemId);
    }

    return false;
}

function findOpenNodesUl(openNodeIds, ulNode) {
    let childArray = ulNode.childNodes;

    for (let i = 0; i < childArray.length; i++) {
        let elem = childArray[i];
        if(elem.nodeName === 'LI') {
            let liChildArray = elem.childNodes;
            for (let j = 0; j < liChildArray.length; j++) {
                let liChildElem = liChildArray[j];
                if (liChildElem.nodeName === 'DETAILS') {
                    findOpenNodesDetails(openNodeIds, liChildElem);
                }
            }
        }
    }
}

function changeBreadcrumbsForUrl(path) {
    const navItemMeta = leftNavMetadata[path];
    const currLeftNav = leftNavMetadata[CLNP];
    const ancestorArray = navItemMeta[ANCESTORS];
    const bcDiv = document.querySelector('.breadcrumbs');
    let nextSibling = null;
    const currBcNodes = bcDiv.childNodes;
    for (let i = 0; i < currBcNodes.length; i++) {
        let currNode = currBcNodes[i];
        if (currNode.nodeName === 'A') {
            if (currLeftNav === LEARNING) {
                currNode.setAttribute('href', LEARNING_HOME_PATH);
            } else if (currLeftNav === DOING) {
                currNode.setAttribute('href', DOING_HOME_PATH);
            } else if (currLeftNav === ADMIN_TOOLS) {
                currNode.setAttribute('href', ADMIN_TOOLS_HOME_PATH);
            } else {
                currNode.setAttribute('href', '/' + USER_PERSONAL_INFO)
            }

            nextSibling = currNode.nextElementSibling;
            const anchorChildren = currNode.childNodes;
            for (let j = 0; j < anchorChildren.length; j++) {
                let currAnchorChild = anchorChildren[j];
                if (currAnchorChild.nodeName === 'SPAN') {
                    currAnchorChild.innerHTML = null;
                    if (currLeftNav === LEARNING) {
                        currAnchorChild.appendChild(document.createTextNode('Learning'));
                    } else if (currLeftNav === DOING) {
                        currAnchorChild.appendChild(document.createTextNode('Doing'));
                    } else if (currLeftNav === ADMIN_TOOLS) {
                        currAnchorChild.appendChild(document.createTextNode('Tools'));
                    } else {
                        currAnchorChild.appendChild(document.createTextNode('User'));
                    }
                }
            }

            // We've gotten the home anchor, so break and clear nodes coming after.
            break;
        }
    }

    /*
    If any elements exist in the breadcrumbs area to the right of the home icon for the previously selected
    page loop through and remove them all.
     */
    while (nextSibling) {
        let followingSibling = nextSibling.nextElementSibling;
        bcDiv.removeChild(nextSibling);
        nextSibling = followingSibling;
    }

    // Do nothing more for paths that are not part of any leftNav layouts.
    if (path === LEARNING_HOME_PATH ||
        path === DOING_HOME_PATH ||
        path === ADMIN_TOOLS_HOME_PATH ||
        path === '/' + DOING ||
        path === '/' + LEARNING ||
        path === '/' + ADMIN_TOOLS ||
        path === '/' + ABOUT_US ||
        path === '/' + CONTACT_US ||
        path === '/' + PRIVACY) {
        return;
    }

    const slashSpanElem = document.createElement('span');
    slashSpanElem.classList.add('bc-separator');
    const slashTextElem = document.createTextNode("\u00A0 / \u00A0");
    slashSpanElem.appendChild(slashTextElem);
    bcDiv.appendChild(slashSpanElem);

    if (ancestorArray) {
        for (let i = 0; i < ancestorArray.length; i++) {
            let currAncestor = ancestorArray[i];
            let elem = document.getElementById(currAncestor.ancestorId + '-summary');

            if (!elem) {
                // ASSERT: this is the leaf element
                elem = document.getElementById(currAncestor.ancestorId + '-li');
            }

            if (elem) {
                const childArray = elem.childNodes;
                for (let j = 0; j < childArray.length; j++) {
                    let currChild = childArray[j];
                    if (currChild.nodeName === 'A') {
                        const newAnchorElem = currChild.cloneNode(true);
                        bcDiv.appendChild(newAnchorElem);
                        const spanElem = document.createElement('span');
                        spanElem.classList.add('bc-separator');
                        const textElem = document.createTextNode("\u00A0 / \u00A0");
                        spanElem.appendChild(textElem);
                        bcDiv.appendChild(spanElem);
                    }
                }
            }
        }
    }

    const targetText = navItemMeta[ANCHOR_TEXT];
    const leafSpanElem = document.createElement('span');
    leafSpanElem.classList.add('bc-leaf');
    const textElem = document.createTextNode(targetText);
    leafSpanElem.appendChild(textElem);
    bcDiv.appendChild(leafSpanElem);
}

export function handleMainNavClick(event) {
    // If the user has unsaved changes in an editor, get confirmation on
    // whether they want to leave the page without saving the changes.
    if (currentDirtyEditorName) {
        console.log(`There are unsaved changes.`);
        let intendedTarget = event.target;

        openUnsavedChangesModal(intendedTarget, window.location.pathname);
        return;
    } else {
        console.log(`No unsaved changes. Go to clicked link.`);
    }

    let target = event.target;

    const editUserProfBtn = document.getElementById('user-button');
    if (editUserProfBtn.contains(target)) {
        target = editUserProfBtn;
    }

    let mainNavPath = target.getAttribute('id');
    mainNavPath = mainNavPath.substring(0, mainNavPath.indexOf('-button'));

    const currOpenPathForNav = getStringFromLocalStorage(mainNavPath + lsConsts.currOpenPathSuffix);
    const navItemMeta = leftNavMetadata[currOpenPathForNav];
    const leftNavIdForUrl = navItemMeta[LNP];

    if (leftNavIdForUrl !== leftNavMetadata[CLNP]) {
        // ASSERT: The user is switching the left nav
        switchLeftNav(mainNavPath);
        addEventListenerToDetailsElems();
        reOpenNodes(mainNavPath);
    } else {
        // ASSERT: The user clicked the main nav button that is already set.
        return;
    }

    if (currOpenPathForNav === LEARNING_HOME_PATH) {
        const bcHomeElem = document.getElementById(BC_HOME_BUTTON);
        bcHomeElem.setAttribute('href', LEARNING_HOME_PATH);
        bcHomeElem.click();
    } else if (currOpenPathForNav === DOING_HOME_PATH) {
        const bcHomeElem = document.getElementById(BC_HOME_BUTTON);
        bcHomeElem.setAttribute('href', DOING_HOME_PATH);
        bcHomeElem.click();
    } else if (currOpenPathForNav === ADMIN_TOOLS_HOME_PATH) {
        const bcHomeElem = document.getElementById(BC_HOME_BUTTON);
        bcHomeElem.setAttribute('href', ADMIN_TOOLS_HOME_PATH);
        bcHomeElem.click();
    } else {
        const linkId = currOpenPathForNav.substring(1, currOpenPathForNav.length) + '-link';
        const targetAnchor = document.getElementById(linkId);
        let targetPath = targetAnchor.href;

        const firstDoubleSlashIx = targetPath.indexOf('//');
        targetPath = targetPath.substring(firstDoubleSlashIx + 2, targetPath.length);
        const firstSlashIx = targetPath.indexOf('/');
        targetPath = targetPath.substring(firstSlashIx, targetPath.length);

        // If the current open path for the target tab is a parent node, strip of the first
        // part of the path to avoid a failure in case the user collapsed that node.
        // This avoids the following bug, which only occurs for small screensize:
        // If the user has one tab open with the page for a parent node open, and the user collapses that
        // node, then they go to another tab, and finally try to tap back to the original tab, the
        // leftNav fails to be updated back to that tab. This is due to the function that will not open the
        // page of a parent node unless the parent node is already expanded in the leftNav.
        if (targetPath.startsWith(FROM_LEFT_NAV_PARENT_PREFIX)) {
            targetPath = targetPath.substring(FROM_LEFT_NAV_PARENT_PREFIX.length);
        }

        const dummyLink = document.getElementById('dummy-link');
        dummyLink.setAttribute('href', targetPath);
        dummyLink.click();
    }
}

export function goToLearningHome() {
    const dummyLink = document.getElementById('dummy-link');
    dummyLink.setAttribute('href', WS_URL_PRE + LEARNING_HOME_PATH);
    dummyLink.click();
}

function changeLeftNavForUrl(path) {
    const navItemMeta = leftNavMetadata[path];
    const selectableElemId = navItemMeta[SELECTABLE];
    const ancestorArray = navItemMeta[ANCESTORS];
    const currSelectedId = leftNavMetadata[CURR_SELECTED_LEFT_NAV_NODE];
    const currLeftNavPath = leftNavMetadata[CLNP];
    const leftNavIdForUrl = navItemMeta[LNP];

    if (path) {
        putStringInLocalStorage(leftNavIdForUrl + lsConsts.currOpenPathSuffix, path);
    }

    // If there is currently a selected node, remove it.
    if (currSelectedId) {
        const currSelected = document.getElementById(currSelectedId);
        if (currSelected) {
            currSelected.classList.remove(NAV_SELECTED_CLASS);
        }
        leftNavMetadata[CURR_SELECTED_LEFT_NAV_NODE] = null;
    }

    if (currLeftNavPath !== leftNavIdForUrl) {
        // ASSERT: the requested URL is in a different nav tree
        // from the current one, so switch the left-nav panel
        // to the newly requested one.
        switchLeftNav(leftNavIdForUrl);
        addEventListenerToDetailsElems();
        reOpenNodes(leftNavIdForUrl);
    }

    // Ensure the clicked page node is visible in the left-nav
    if (ancestorArray) {
        for (let i = 0; i < ancestorArray.length; i++) {
            let nodeId = ancestorArray[i].ancestorId;
            document.getElementById(nodeId).setAttribute("open", true);
        }
    }

    // Select the new clicked page node
    const selectableElem = document.getElementById(selectableElemId);
    if (selectableElem) {
        selectableElem.classList.add(NAV_SELECTED_CLASS);
        leftNavMetadata[CURR_SELECTED_LEFT_NAV_NODE] = selectableElemId;
        selectableElem.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        // If selected node has children, expand it to reveal first level of decendents
        const parentNode = selectableElem.parentNode;
        if (parentNode.nodeName === 'DETAILS') {
            parentNode.setAttribute("open", true);
        }
    }
}

export function switchLeftNav(leftNavId) {
    const htmlTemplate = getHtmlTemplateFromLocalStorage('/templates/left-nav/' + leftNavId + "Nav.html");
    document.getElementById(LEFT_NAV_ID).innerHTML = htmlTemplate;
    leftNavMetadata[CLNP] = leftNavId;

    const footerTemplate = getHtmlTemplateFromLocalStorage(urlRoutes['/leftNavFooter'].template);
    const footerElem = document.querySelector(LEFT_NAV_FOOTER_CONTAINER);
    footerElem.innerHTML = footerTemplate;

    updateClassesForCurrLeftNav(leftNavId);
}

function updateClassesForCurrLeftNav(leftNavId) {
    if (leftNavId === LEARNING) {
        document.getElementById(LEARNING + '-button').classList.add('curr-nav');
        document.getElementById(DOING + '-button').classList.remove('curr-nav');
        document.getElementById(ADMIN_TOOLS + '-button').classList.remove('curr-nav');
        document.getElementById(USER_NAV_HIGHLIGHTABLE_ID).classList.remove('curr-nav');
    } else if (leftNavId === DOING) {
        document.getElementById(DOING + '-button').classList.add('curr-nav');
        document.getElementById(LEARNING + '-button').classList.remove('curr-nav');
        document.getElementById(ADMIN_TOOLS + '-button').classList.remove('curr-nav');
        document.getElementById(USER_NAV_HIGHLIGHTABLE_ID).classList.remove('curr-nav');
    } else if (leftNavId === ADMIN_TOOLS) {
        document.getElementById(ADMIN_TOOLS + '-button').classList.add('curr-nav');
        document.getElementById(LEARNING + '-button').classList.remove('curr-nav');
        document.getElementById(DOING + '-button').classList.remove('curr-nav');
        document.getElementById(USER_NAV_HIGHLIGHTABLE_ID).classList.remove('curr-nav');
    } else {
        document.getElementById(USER_NAV_HIGHLIGHTABLE_ID).classList.add('curr-nav');
        document.getElementById(LEARNING + '-button').classList.remove('curr-nav');
        document.getElementById(DOING + '-button').classList.remove('curr-nav');
        document.getElementById(ADMIN_TOOLS + '-button').classList.remove('curr-nav');
    }
}

export function handleAnyClick(clickEvent) {
    const {target} = clickEvent;

    if (target.classList.contains('bc-home')) {
        clickEvent.preventDefault();
        document.getElementById('bc-home-btn').click();
        return;
    }

    if (!target.matches("a")) {
        console.log("You clicked outside of any anchor, so doing nothing.");
        return;
    }

    clickEvent.preventDefault();
    const p1 = target.parentElement;
    const p2 = p1.parentElement;

    // For left-nav links we need to toggle the navigation node, because we
    // are preventing the default action.
    if (p1.classList.contains(LEFT_NAV_BUTTON_ID) && p2.nodeName === "DETAILS") {
        if (p2.open === false) {
            p2.open = true;
        }
    }

    urlRoute();
}

/*
Fetches the requested html template and stores it in localStorage.
 */
export async function retrieveAndStoreHtmlTemplate (routeKey) {
    let retVal = true;
    const urlRoute = urlRoutes[routeKey];
    let htmlTemplate = await retrieveHtmlTemplateForPath(urlRoute.template);

    if (htmlTemplate) {
        const keyTemplatePair = {
            templateKey: urlRoute.template,
            htmlTemplate: htmlTemplate
        };

        putHtmlTemplateInLocalStorage(keyTemplatePair);
    } else {
        retVal = false;
    }

    return retVal;
}

/*
Fetches the requested html template.
 */
export async function retrieveHtmlTemplateForRouteKey (routeKey) {
    const urlRoute = urlRoutes[routeKey];
    return await retrieveHtmlTemplateForPath(urlRoute.template);
}

async function retrieveHtmlTemplateForPath(path) {
    console.log(`Getting ${path} from the web server.`);
    const response = await fetch(path);

    if (!response.ok) {
        throw new Error(`Bad response in fetch(${path})`, {
            cause: {response}
        });
    }

    return await response.text();
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function switchLeftNavToggleState() {
    leftNavFullOpen = !leftNavFullOpen;
}

export async function handleToggleLeftNav() {
    const toggleElem = document.getElementById('navi-toggle');

    if (leftNavFullOpen) {
        leftNavFullOpen = false;
        toggleElem.classList.remove('is-active');

        const leftNav = document.getElementById('left-nav');
        leftNav.style.paddingLeft = `0`;
        const mainContentArea = document.querySelector('.main-content-area-container');
        mainContentArea.style.display = 'none';

        for (let i = 0; i < 101; i++) {
            if (i % 5 === 0) {
                await sleep(1);
            }
            leftNav.style.width = `${100 - i}%`;
            mainContentArea.style.width = `${i}%`;
        }

        mainContentArea.style.display = 'flex';
    } else {
        leftNavFullOpen = true;
        toggleElem.classList.add('is-active');

        const leftNav = document.getElementById('left-nav');
        leftNav.style.paddingLeft = `1rem`;
        const mainContentArea = document.querySelector('.main-content-area-container');
        mainContentArea.style.display = 'none';

        for (let i = 0; i < 101; i++) {
            if (i % 5 === 0) {
                await sleep(1);
            }
            leftNav.style.width = `${i}%`;
            mainContentArea.style.width = `${100 - i}%`;
        }

        mainContentArea.style.display = 'flex';
    }
}
  
async function invokeInitUiFunc(initUiFuncName) {
    if (initUiFuncName === undefined) {
        return;
    }

    if (initUiFuncName === 'initUiToolsRunLottery()') {
        initUiToolsRunLottery();
    } else if (initUiFuncName === 'initUiToolsTabulateRepVotes()') {
        initUiToolsTabulateRepVotes();
    } else if (initUiFuncName === 'initUiDonatePage()') {
        initUiDonatePage();
    } else if (initUiFuncName === 'initUiToolsTabulateIssueVotes()') {
        initUiToolsTabulateIssueVotes();
    } else if (initUiFuncName === 'initUiLotteryWinnersViewCurrentRanking()') {
        await initUiLotteryWinnersViewCurrentRanking();
    } else if (initUiFuncName === 'initUiLotteryWinnersEditMyRanking()') {
        await initUiLotteryWinnersEditMyRanking();
    } else if (initUiFuncName === "initUiViewCurrentRanking('nat-issues')") {
        await initUiViewCurrentRanking('nat-issues');
    } else if (initUiFuncName === "initUiEditRankVote('nat-issues')") {
        await initUiEditRankVote('nat-issues');
    } else if (initUiFuncName === "initUiViewCurrentRanking('nat-emergency-issues')") {
        await initUiViewCurrentRanking('nat-emergency-issues');
    } else if (initUiFuncName === "initUiEditRankVote('nat-emergency-issues')") {
        await initUiEditRankVote('nat-emergency-issues');
    } else if (initUiFuncName === 'initUiUserPersonalInfo()') {
        await initUiUserPersonalInfo();
    } else if (initUiFuncName === 'initUiUserSecurity()') {
        await initUiUserSecurity();
    } else if (initUiFuncName === 'initUiUserEmails()') {
        await initUiUserEmails();
    } else if (initUiFuncName === 'initUiUserPassword()') {
        await initUiUserPassword();
    } else if (initUiFuncName === 'initUiUserPhone()') {
        await initUiUserPhone();
    }
}
