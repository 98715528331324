import {
    handleToggleLeftNav,
    switchLeftNavToggleState,
    isInDemoMode
} from "./routingAndNav.js";

// IMPORTANT! If you change the smallScreenCutoffSize (max-width value), you must also change it in
// the file _mixins.scss
export const smallScreenCutoffSize = 685;
const windowWidth = window.innerWidth;
export let smallScreen = windowWidth <= smallScreenCutoffSize;

export async function initMediaQueries() {
    const mediaQuery = window.matchMedia(`(max-width: ${smallScreenCutoffSize}px)`);

    mediaQuery.onchange = async (e) => {
        const adminToolsButton = document.getElementById("adminTools-button");
        const leftNav = document.getElementById('left-nav');
        const mainContentArea = document.querySelector('.main-content-area-container');
        const separatorElem = document.querySelector('.separator');
        const navBtnElem = document.querySelector('.nav-btn');

        if (e.matches) {
            console.log(`This is a narrow screen — less than or equal to ${smallScreenCutoffSize}px wide.`);
            adminToolsButton.style.display = "none";
            separatorElem.style.display = "none";
            navBtnElem.style.display = "inline-block";
            smallScreen = true;

            console.log(`smallScreen set to ${smallScreen}`);

            switchLeftNavToggleState();
            await handleToggleLeftNav();
        } else {
            console.log(`This is a wide screen — more than ${smallScreenCutoffSize}px wide.`);
            if (!isInDemoMode) {
                adminToolsButton.style.display = "block";
            }

            separatorElem.style.display = "block";
            navBtnElem.style.display = "none";
            smallScreen = false;

            console.log(`smallScreen set to ${smallScreen}`);

            leftNav.style.paddingLeft = `1rem`;
            leftNav.style.width = `35%`;
            mainContentArea.style.width = `60%`;
        }
    };
}

