import {urlRoutes} from "./urlRoutes.js";
import {
    getHtmlTemplateFromLocalStorage,
    getNumDistrictsForState,
    getStateMetadataFromLocalStorage,
    lsConsts,
    URL_PRE,
    removeUserSessionFromLocalStorage,
    removeValueFromLocalStorage,
    putUserSessionInLocalStorage,
    getUserSessionFromLocalStorage, WS_URL_PRE
} from "./ddpLocalStorage.js";
import {
    closeModalDialog,
    getModalDialog,
    hideLoader,
    openModalDialog,
    showLoader,
    openErrorMessageModal
} from "./modalDialog.js";
import {
    LEARNING,
    DOING,
    ADMIN_TOOLS,
    MCA,
    getCurrentLeftNavPath,
    closeAllNodes,
    initCurrOpenPagesForNavs,
    handleMainNavClick,
    goToLearningHome,
    setCurrentDirtyEditorName,
    retrieveHtmlTemplateForRouteKey,
    isInDemoMode
} from "./routingAndNav.js";
import {
    clearUserProfUnderEditForLogout
} from "./userProfEditController.js";
import {setErrorOnFormGroup,
    validateForm,
    validateSingleFormGroup
} from "./formValidation.js";
import {
    notifyUserLoginOccurred,
    notifyUserLogoutOccurred,
    updateInactivityTOInfoForTab,
    isThisTabLastToTimeout,
    isTabWithinThisTimeOfTimeout,
    getCurrTabInfo,
    getTabId
} from "./tabManager.js";
import {messages} from "./messages.js";

// Mirror object of org.tpp.model.UserSession on the server side
export let userSession = {
    csrfPreventionToken: "",
    email: "",
    id: "",
    addr_state: "",
    congress_district: 0,
    isPwdAuthenticated: false,
    is2FaAuthenticated: false
}

const timeoutResetEvents = ['click', 'mousemove', 'keydown'];
let inactivityWarningTimeoutId = undefined;
let inactivityLogoutTimeoutId = undefined;
let mousemoveEvents = 0;

// 10 minute inactivity timeout
const timeoutMillis = 600000;

// 2 minute logout timeout
const logoutTimeoutMillis = 120000;

export function setupInactivityTimeouts() {
    inactivityWarningTimeoutId = setTimeout(handleInactivityWarningTimeout, timeoutMillis);
    const currTimeMillis = Date.now();
    console.log(`Inactivity timeout set at ${currTimeMillis} for ${timeoutMillis} millis - inactivityWarningTimeoutId: ${inactivityWarningTimeoutId}`);
    updateInactivityTOInfoForTab(currTimeMillis, timeoutMillis, inactivityWarningTimeoutId);

    timeoutResetEvents.forEach(event=> {
        window.addEventListener(event, handleInactivityWarningTimeoutReset);
    });
}

function clearInactivityTimeoutResetEventListeners() {
    timeoutResetEvents.forEach(event=> {
        window.removeEventListener(event, handleInactivityWarningTimeoutReset);
    });
}

function handleInactivityWarningTimeoutReset(event) {
    if (event && event.type === 'mousemove') {
        mousemoveEvents++;
        if ((mousemoveEvents % 500) !== 0) {
            return;
        }
        if (mousemoveEvents > 100000) {
            mousemoveEvents = 1;
        }
        console.log(`mousemove events: ${mousemoveEvents} timeout reset.`);
    }

    /*
    If the currTab is within 4 seconds of timeout don't reset the timeout. This is important because of the strategy
    explained in the comments for the possibleLastTabsStanding definition. Any tab could time out at any time. A
    tab that times out must be able to depend on any tab that it thinks is within 2 seconds of timing out being
    certain to trigger the timeout popup and possibly call the logout API. We cannot allow a race condition bug where
    a timed out tab gets the tab info of this tab, computes that it is within 2 seconds of timing out, to then have a last
    moment user action reset its timeout, because that could leave the session in an uncertain state.
     */
    const currTabInfo = getCurrTabInfo();
    if (!isTabWithinThisTimeOfTimeout(currTabInfo, 4000)) {
        if (inactivityWarningTimeoutId) {
            clearTimeout(inactivityWarningTimeoutId);
            inactivityWarningTimeoutId = setTimeout(handleInactivityWarningTimeout, timeoutMillis);

            const currTimeMillis = Date.now();
            updateInactivityTOInfoForTab(currTimeMillis, timeoutMillis, inactivityWarningTimeoutId);
            console.log(`Resetting inactivity timeout at ${currTimeMillis} for ${timeoutMillis} millis - inactivityWarningTimeoutId: ${inactivityWarningTimeoutId}`);
        }
    } else {
        console.log(`Tab ${getTabId()} is within 4 seconds of timing out, so not resetting inactivity timeout.`);
    }
}

function handleInactivityWarningTimeout() {
    console.log('Inactivity timeout fired.');
    if (isThisTabLastToTimeout()) {
        clearTimeout(inactivityWarningTimeoutId);
        inactivityWarningTimeoutId = undefined;
        clearInactivityTimeoutResetEventListeners();
        console.log(`Timeout cleared - inactivityWarningTimeoutId: ${inactivityWarningTimeoutId}`);
        openInactivityPopup();

        inactivityLogoutTimeoutId = setTimeout(handleInactivityLogoutTimeout, logoutTimeoutMillis);
        console.log(`Logout timeout set for ${logoutTimeoutMillis} millis - inactivityLogoutTimeoutId: ${inactivityLogoutTimeoutId}`);
    }
}

async function handleInactivityLogoutTimeout() {
    console.log('Logout timeout fired.');
    if (userSession) {
        showLoader();
        try {
            await callAPI_logout(userSession.id, null);
        } catch (err) {
            console.dir(err);
        }
        hideLoader()
    }

    removeUserSessionFromTab();
    closeModalDialog();
}

function clearInactivityTimeouts() {
    clearInactivityTimeoutResetEventListeners();
    clearTimeout(inactivityWarningTimeoutId);
    inactivityWarningTimeoutId = undefined;
    clearTimeout(inactivityLogoutTimeoutId);
    inactivityLogoutTimeoutId = undefined;
}

export function readInUserSessionFromLocalStorage() {
    userSession = getUserSessionFromLocalStorage();
}

export function clearUserSession() {
    userSession = null;
    removeUserSessionFromLocalStorage();
}

export async function showUserContextMenu(e) {
    const userArea = document.querySelector(".user-area");
    const userAreaRect = userArea.getBoundingClientRect();
    const contextMenu = document.querySelector(".user-menu");
    const loginOption = document.getElementById("user-login");
    const logoutOption = document.getElementById("logout");
    const editUserProfOption = document.getElementById("user-button");
    const authSessionOption = document.getElementById("authenticate-session");
    const signUpOption = document.getElementById("sign-up");
    const sponsoredSignUpOption = document.getElementById("sign-up-new-member");
    const socialShareBtn = document.getElementById('social-share');
    const socialShareTwitter = document.getElementById('social-twitter-icon');
    const socialShareFacebook = document.getElementById('social-facebook-icon');

    contextMenu.style.top = `${userAreaRect.bottom}`;
    contextMenu.style.right = `${userAreaRect.left}`;

    let isClickedOutside = !(
        userArea.contains(e.target) || contextMenu.contains(e.target)
    );

    let isClickedOutsideSocial = !(
        socialShareBtn.contains(e.target)
    );

    if (isClickedOutsideSocial) {
        socialShareTwitter.classList.add('hidden');
        socialShareFacebook.classList.add('hidden');
    } else {
        if (!socialShareTwitter.classList.contains('hidden')) {
            socialShareTwitter.classList.add('hidden');
            socialShareFacebook.classList.add('hidden');
        } else {
            socialShareTwitter.classList.remove('hidden');
            socialShareFacebook.classList.remove('hidden');
        }
    }

    if (isClickedOutside || (contextMenu.style.display == "inline-block" && userArea.contains(e.target))) {
        contextMenu.style.display = "none";
    } else {
        if (contextMenu.style.display == "inline-block") {
            if (logoutOption.contains(e.target)) {
                contextMenu.style.display = "none";

                showLoader();
                try {
                    await callAPI_logout(userSession.id, null);
                } catch (err) {
                    console.dir(err);
                }
                hideLoader();
                removeUserSessionFromTab();
            } else if (loginOption.contains(e.target)) {
                contextMenu.style.display = "none";
                if (!isInDemoMode) {
                    openLogin();
                } else {
                    showDemoNotice();
                }
            } else if (editUserProfOption.contains(e.target)) {
                contextMenu.style.display = "none";
                handleMainNavClick(e);
            } else if (authSessionOption.contains(e.target)) {
                contextMenu.style.display = "none";
                openLogin();
            } else if (signUpOption.contains(e.target)) {
                contextMenu.style.display = "none";
                // Pass typeOfEdit value 2, which means this is a new user
                // who has found their own way to the sign up page.
                if (!isInDemoMode) {
                    openUserProfForm(2);
                } else {
                    showDemoNotice();
                }
            } else if (sponsoredSignUpOption.contains(e.target)) {
                contextMenu.style.display = "none";
                // Pass typeOfEdit value 3, which means this is a logged in user
                // who has chosen to let a new user sign up with their device. This
                // comes with the implication that the signed in user will get a
                // referral credit of some kind.
                openUserProfForm(3);
            } else if (loginOption.contains(e.target)) {
                contextMenu.style.display = "none";
                openLogin();
            }
        } else {
            if (userSession) {
                if (userSession.isPwdAuthenticated) {
                    authSessionOption.classList.add("hidden");
                } else {
                    authSessionOption.classList.remove("hidden");
                }

                loginOption.classList.add("hidden");
                logoutOption.classList.remove("hidden");

                signUpOption.classList.add("hidden");
                sponsoredSignUpOption.classList.remove("hidden");

                editUserProfOption.classList.remove("hidden");
            } else {
                authSessionOption.classList.add("hidden");

                loginOption.classList.remove("hidden");
                logoutOption.classList.add("hidden");

                signUpOption.classList.remove("hidden");
                sponsoredSignUpOption.classList.add("hidden");

                editUserProfOption.classList.add("hidden");
            }

            contextMenu.style.display = "inline-block";
        }
    }
};

export function shareToSocialMedia(e) {
    const socialShareBtn = document.getElementById('social-share');
    const socialShareTwitter = document.getElementById('social-twitter');
    const socialShareFacebook = document.getElementById('social-facebook');

    if (socialShareBtn.contains(e.target)) {
        console.log(`Social share button clicked`);
        return;
    }

    let shareUrl = null;
    const currPathName = window.location.pathname;
    const ddpUrlParm = 'https://ddpus.org' + currPathName;

    if (socialShareTwitter.contains(e.target)) {
        const textParm = `Join the Direct Democracy Platform, and help put a stop to corruption!`;
        const viaParm = `DDPUSOrg`;

        shareUrl = `https://twitter.com/intent/tweet?text=${textParm}&url=${ddpUrlParm}&via=${viaParm}`;
    } else if (socialShareFacebook.contains(e.target)) {
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${ddpUrlParm}`;
    }

    if (shareUrl) {
        const dummyLink = document.getElementById('dummy-link');
        dummyLink.setAttribute('href', shareUrl);
        dummyLink.click();
    }
}

async function sendNewUserWelcomeEmail(e) {
    // This method is being driven by a form submit action. We must prevent the default
    // browser action, which would be to submit the form and refresh the page.
    e.preventDefault();

    if (!validateForm('#newProfForm')) {
        return;
    }

    const emailElem = document.getElementById("user-email");
    const stateDropdown = document.getElementById("state-dropdown-newuser-prof");
    const districtDropdown = document.getElementById("district-num-newuser-prof");

    showLoader();
    try {
        const isSendSuccess = await callAPI_sendWelcomeEmail(emailElem.value, stateDropdown.value, districtDropdown.value);

        if (isSendSuccess) {
            await openEmailSentConfirmation('/welcomeEmailSentConfirmation');
        }
    } catch (err) {
        console.dir(err);
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        setCurrentDirtyEditorName(null);
        goToLearningHome();
    }
    hideLoader();
}

export async function callAPI_createAcct(emailAddr, uuid, state, district) {
    let retVal = false;
    let theurl = URL_PRE + `/rest/account?uuid=${uuid}`;

    const userProf = {};
    userProf.id = null;
    userProf.gender = 1;
    userProf.pronouns = 1;
    userProf.oauthid = "blah";
    userProf.is_admin = 1;
    userProf.is_addr_verified = 1;
    userProf.email = emailAddr;
    userProf.addr_state = state;
    userProf.congress_district = district;

    console.log(`Calling API: POST ${theurl} with json: ${JSON.stringify(userProf)}`);

    try {
        const response = await fetch(theurl,
            {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userProf)
            }
        );

        if (!response.ok) {
            throw new Error('Bad response in callAPI_getRankVoteId()', {
                cause: {response}
            })
        }

        console.log(response);
        userSession = await response.json();
        retVal = true;
        console.log(`userSession returned for: ${emailAddr}`);
        console.log(userSession);
    } catch (err) {
        console.dir(err);
        if (err.cause) {
            console.log(`A user already exists in the system for email: ${emailAddr}`);
        } else {
            throw err;
        }
    }

    return retVal;
}

// typeOfEdit === 1 -> Indicates an existing member editing their user profile.
// typeOfEdit === 2 -> Indicates is a new member signing up on their own.
// typeOfEdit === 3 -> Indicates an existing member helping a new member sign up.
function openUserProfForm(typeOfEdit) {
    const modalDialog = getModalDialog();

    if (typeOfEdit === 1) {
        // This code moved to editor rather than modal popup. Look at initUiUserPersonalInfo()
    } else if (typeOfEdit === 2 || typeOfEdit === 3) {
        // New user acct being created.
        modalDialog.innerHTML = getHtmlTemplateFromLocalStorage(urlRoutes['/createNewUserProfile'].template);

        const title = document.getElementById("user-prof-title");
        title.textContent = `Sign Up`;

        const stateDropdown = document.getElementById("state-dropdown-newuser-prof");
        stateDropdown.addEventListener("change", handleNewUserProfStateChange);

        const newProfileForm = document.forms["newProfForm"];
        newProfileForm.setAttribute('novalidate', '');
        newProfileForm.addEventListener("submit", sendNewUserWelcomeEmail);
        Array.from(newProfileForm.elements).forEach((element) => {
            element.addEventListener('blur', event => {
                if (event.srcElement.parentElement.classList.contains('form-group')) {
                    validateSingleFormGroup(event.srcElement.parentElement);
                }
            });
        });

        const cancelBtn = document.getElementById('createNewUserCancel');
        cancelBtn.addEventListener("click", function () {
            closeModalDialog();
        });

        openModalDialog();

        document.getElementById("user-email").focus();
    }
}

export function showDemoNotice() {
    const modalDialog = getModalDialog();
    modalDialog.innerHTML = getHtmlTemplateFromLocalStorage(urlRoutes['/demoOnlyNotice'].template);

    const newProfileForm = document.forms["demoNoticeForm"];
    newProfileForm.addEventListener("submit", () => {
        const dummyLink = document.getElementById('dummy-link');
        dummyLink.setAttribute('href', 'https://www.zeffy.com/donation-form/c6b21bd7-8412-483e-bd2e-a3c7d95324e8');
        dummyLink.click();
        closeModalDialog();
    });

    const cancelBtn = document.getElementById('demoNoticeCancel');
    cancelBtn.addEventListener("click", function () {
        closeModalDialog();
    });

    openModalDialog();
}

export function initUiDonatePage() {
    const newProfileForm = document.forms["donatePageForm"];
    newProfileForm.addEventListener("submit", () => {
        const dummyLink = document.getElementById('dummy-link');
        dummyLink.setAttribute('href', 'https://www.zeffy.com/donation-form/c6b21bd7-8412-483e-bd2e-a3c7d95324e8');
        dummyLink.click();
    });
}

async function handleLogin(e) {
    // This method is being driven by a form submit action. We must prevent the default
    // browser action, which would be to submit the form and refresh the page.
    e.preventDefault();

    if (!validateForm('#profLoginForm')) {
        return;
    }

    const ddpAcctUserName = document.getElementById("user-email").value;
    const ddpAcctPwd = document.getElementById("loginPassword").value;

    if (!ddpAcctPwd) {
        return;
    }

    await processLogin(ddpAcctUserName, ddpAcctPwd, null, null);
}

export async function processLogin(ddpAcctUserName, ddpAcctPwd, uuid, provisionalLogoutToken) {
    showLoader();
    try {
        const credsAuthenticated = await callAPI_login(ddpAcctUserName, ddpAcctPwd, uuid, provisionalLogoutToken);

        if (credsAuthenticated) {
            // ASSERT: The server is up, and the credentials validation succeeded.
            if (userSession) {
                putUserSessionInLocalStorage(userSession);
                decorateUserIconWithEmail();
                reloadCurrPage();
                setupInactivityTimeouts();
            }

            notifyUserLoginOccurred();
            closeModalDialog();
        } else {
            // ASSERT: The server is up, but the credentials provided were not valid.
            console.log(`Login attempt failed.`);
            if (!provisionalLogoutToken) {
                setErrorOnFormGroup('loginPasswordFormGroup', 'This ' +
                    'Email Address - password combination not valid');
            }
        }

    } catch (err) {
        // ASSERT: The server is down.
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        goToLearningHome();
    }
    hideLoader();
}

async function handleForgotPwd(e) {
    e.preventDefault();

    const pwdElem = document.getElementById("loginPassword");
    pwdElem.removeAttribute('required');
    if (!validateForm('#profLoginForm')) {
        pwdElem.setAttribute('required', 'true');
        return;
    }
    pwdElem.setAttribute('required', 'true');

    await sendPasswordResetEmail();
}

async function sendPasswordResetEmail() {
    const emailAddr = getEmailAddrFromDom();

    let isSendSuccess = true;
    let theurl = URL_PRE + `/rest/sendChangePwdEmail/${emailAddr}`;
    console.log(`Calling API: POST ${theurl}`);

    showLoader();
    try {
        const response = await fetch(theurl, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include'
        });

        if (response.ok) {
            console.log(`sendChangePwdEmail call succeeded.`);
        } else {
            isSendSuccess = false;
            console.log(`ERROR: sendChangePwdEmail failed for: ${emailAddr}`);
        }

        if (isSendSuccess) {
            await openEmailSentConfirmation(`/emailSentConfirmation`);
        }
    } catch (err) {
        console.dir(err);
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        setCurrentDirtyEditorName(null);
        goToLearningHome();
    }
    hideLoader();
}

function reloadCurrPage() {
    let currPath = window.location.pathname;
    currPath = currPath.substring(1, currPath.length);
    const currPageLink = document.getElementById(`${currPath}-link`);
    if (currPageLink) {
        currPageLink.click();
    }

}

export function removeUserSessionFromTab() {
    clearUserSession();
    clearInactivityTimeouts();
    clearUserProfUnderEditForLogout();
    removeValueFromLocalStorage(lsConsts.AUTHENTICATION_FACTORS);
    document.getElementById("user-name").innerHTML = `?`;
    removeValueFromLocalStorage(lsConsts.LS_CURR_DISTRICT_KEY);
    removeValueFromLocalStorage(lsConsts.LS_SHOW_DISTRICT_KEY);
    removeValueFromLocalStorage(lsConsts.LS_CURR_STATE_KEY);

    const currentLeftNavPath = getCurrentLeftNavPath();
    closeAllNodes(currentLeftNavPath);

    removeValueFromLocalStorage(LEARNING + lsConsts.openNodeListKeySuffix);
    removeValueFromLocalStorage(DOING + lsConsts.openNodeListKeySuffix);
    removeValueFromLocalStorage(ADMIN_TOOLS + lsConsts.openNodeListKeySuffix);

    removeValueFromLocalStorage(LEARNING + lsConsts.currOpenPathSuffix);
    removeValueFromLocalStorage(DOING + lsConsts.currOpenPathSuffix);
    removeValueFromLocalStorage(ADMIN_TOOLS + lsConsts.currOpenPathSuffix);

    initCurrOpenPagesForNavs();

    // Send browser to learning home
    document.getElementById(LEARNING + '-button').click();
}

export async function callAPI_logout(ddpAcctId, provisionalLogoutToken) {
    let theurl = URL_PRE + `/rest/logout/${ddpAcctId}`;
    if (provisionalLogoutToken) {
        theurl = theurl + `?provisionalLogoutToken=${provisionalLogoutToken}`;
    }
    console.log(`Calling API: POST ${theurl}`);

    try {
        const response = await fetch(theurl, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include'
        });

        notifyUserLogoutOccurred();
        console.log(`API logout returned successfully. CSRF prevention token and refreshToken removed for acctId: ${ddpAcctId}`);
    } catch (err) {
        notifyUserLogoutOccurred();
        throw err;
    }
}

function openLogin() {
    const modalDialog = getModalDialog();
    modalDialog.innerHTML = getHtmlTemplateFromLocalStorage(urlRoutes['/userLogin'].template);
    openModalDialog();

    const userLoginForm = document.forms["profLoginForm"];
    userLoginForm.setAttribute('novalidate', '');
    userLoginForm.addEventListener("submit", handleLogin);
    Array.from(userLoginForm.elements).forEach((element) => {
        element.addEventListener('blur', event => {
            if (event.srcElement.parentElement.classList.contains('form-group')) {
                validateSingleFormGroup(event.srcElement.parentElement);
            }
        });
    });

    const cancelBtn = document.getElementById("login-cancel-btn");
    cancelBtn.addEventListener("click", closeModalDialog);
    const forgotPwdBtn = document.getElementById('forgot-pwd-login');
    forgotPwdBtn.addEventListener('click', handleForgotPwd);
    const userNameField = document.getElementById("user-email");
    userNameField.focus();

    userNameField.addEventListener("keypress", function(event) {
        // If the user presses the "Enter" key on the keyboard
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            // Trigger the button element with a click
            const okBtn = document.getElementById("login-btn");
            okBtn.click();
        }
    });
}

export async function openAcctAlreadyExists(emailAddr) {
    document.getElementById(MCA).innerHTML = await retrieveHtmlTemplateForRouteKey('/acctAlreadyExists');

    document.getElementById('email-already-exists').innerHTML = emailAddr;

    const forgotPwdBtn = document.getElementById('forgot-pwd-acct-exists');
    forgotPwdBtn.addEventListener('click', sendPasswordResetEmail);
}

/*
This function populates the userSession variable if successful.
 */
export async function callAPI_login(ddpUserName, pwd, uuid, provisionalLogoutToken) {
    let retVal = false;
    let headers = null;
    let theurl = URL_PRE + `/rest/login`;
    if (pwd) {
        let authString = `${ddpUserName}:${pwd}`
        headers = new Headers();
        headers.set('Authorization', 'Basic ' + btoa(authString));
        console.log(`Calling /rest/login API with Basic auth.`);
    } else {
        if (uuid) {
            theurl += `?email=${ddpUserName}&uuid=${uuid}`;
            console.log(`Calling /rest/login API with uuid for change password processing`);
        } else if(provisionalLogoutToken) {
            theurl += `?email=${ddpUserName}&provisionalLogoutToken=${provisionalLogoutToken}`;
            console.log(`Calling /rest/login API with uuid for provisional logout/login processing`);
        }
    }

    let response = null;
    try {
        if (headers) {
            response = await fetch(theurl, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                headers: headers
            });
        } else {
            response = await fetch(theurl, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include'
            });
        }

        if (!response.ok) {
            throw new Error('Bad response in callAPI_login()', {
                cause: {response}
            });
        }

        userSession = await response.json();
        retVal = true;
        console.log(`userSession returned for: ${ddpUserName}`);
    } catch (err) {
        if (!err.cause) {
            // ASSERT: this exception was not caused by a successful api call and a non 200's http response code, which
            // means this must be due to the server being down.
            throw err;
        } else {
            // ASSERT: the api call succeeded, but the http response code was not in the 200's, which means the creds
            // were not valid.
            console.dir(err);
        }
    }

    return retVal;
}

export async function getExistingCsrfToken(ddpAcctId) {
    const theurl = URL_PRE + `/rest/csrfToken`;
    console.log(`Calling API: GET ${theurl}`);

    try {
        const response = await fetch(theurl, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include'
        });

        if (response.ok) {
            const newToken = await response.json();
            userSession.csrfPreventionToken = newToken.csrfPreventionToken;
            console.log(`CSRF prevention token returned for acctId: ${ddpAcctId}`);
        } else {
            console.log(`ERROR: Failure retrieving csrf token.`);
            showLoader();
            try {
                await callAPI_logout(userSession.id, null);
            } catch(err) {
                console.dir(err);
            }
            hideLoader();
            removeUserSessionFromTab();
        }
    } catch (e) {
        console.log(`ERROR: Failure refreshing csrf token.`);
    }

}

function handleNewUserProfStateChange(event) {

    const statesMetadata = getStateMetadataFromLocalStorage();
    const stateDropdown = document.getElementById("state-dropdown-newuser-prof");
    const numDistricts = getNumDistrictsForState(stateDropdown.value, statesMetadata);

    const districtNumDropdown = document.getElementById("district-num-newuser-prof");
    while (districtNumDropdown.firstChild) districtNumDropdown.removeChild(districtNumDropdown.firstChild);

    for (let i = 0; i < numDistricts + 1; i++) {
        let option = document.createElement("option");
        option.value = i;
        option.text = i;

        if (i == 0) {
            option.value = " ";
            option.text = " ";
        }

        districtNumDropdown.add(option, null);
    }
}

async function openEmailSentConfirmation(routeKey) {
    const emailAddr = getEmailAddrFromDom();

    const modalDialog = getModalDialog();
    modalDialog.innerHTML = await retrieveHtmlTemplateForRouteKey(routeKey);

    document.getElementById('emailVariable').textContent = emailAddr;

    openModalDialog();

    const okBtn = document.getElementById("ok-btn");
    okBtn.addEventListener('click', event => {
        closeModalDialog();
    });
}

function getEmailAddrFromDom() {
    const emailField = document.getElementById('user-email');
    let emailAddr = null;

    if (emailField) {
        emailAddr = emailField.value;
    }

    if (!emailAddr) {
        emailAddr = document.getElementById('email-already-exists').textContent;
    }

    return emailAddr;
}

function openInactivityPopup() {
    const modalDialog = getModalDialog();
    modalDialog.innerHTML = getHtmlTemplateFromLocalStorage(urlRoutes['/inactivityPopup'].template);;

    openModalDialog();

    const okBtn = document.getElementById("ok-btn");
    okBtn.addEventListener('click', event => {
        clearTimeout(inactivityLogoutTimeoutId);
        inactivityLogoutTimeoutId = undefined;
        setupInactivityTimeouts();
        closeModalDialog();
        console.log('Stay Logged In clicked.');
    });

    const logoutBtn = document.getElementById("logout-btn");
    logoutBtn.addEventListener('click', handlePopupLogoutButtonClick);
}

async function handlePopupLogoutButtonClick() {
    clearTimeout(inactivityLogoutTimeoutId);
    inactivityLogoutTimeoutId = undefined;

    showLoader();
    try {
        if (userSession) {
            await callAPI_logout(userSession.id, null);
        }
    } catch (err) {
        console.dir(err);
    }
    hideLoader();

    removeUserSessionFromTab();
    closeModalDialog();
    console.log('Logout clicked');
}

export async function initTabWithUserSession() {
    decorateUserIconWithEmail();

    if (userSession.isPwdAuthenticated){
        setupInactivityTimeouts();
    }

    await getExistingCsrfToken(userSession.id);
}

export function decorateUserIconWithEmail() {
    document.getElementById("user-name").innerHTML = userSession.email;
}

async function callAPI_sendWelcomeEmail(emailAddr, stateStr, districtStr) {
    let theurl = URL_PRE + `/rest/sendWelcomeEmail/${emailAddr}?state=${stateStr}&district=${districtStr}`;

    console.log(`Calling SendEmailApi.sendWelcomeEmail(): POST ${theurl}`);
    const response = await fetch(theurl,
        {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );

    let isSendSuccess = true;
    if (response.ok) {
        console.log(`sendChangePwdEmail call succeeded.`);
    } else {
        isSendSuccess = false;
        console.log(`ERROR: sendChangePwdEmail failed for: ${emailAddr}`);
    }

    return isSendSuccess;
}