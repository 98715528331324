import {urlRoutes} from "./urlRoutes.js";
import {
    closeModalDialog,
    getModalDialog,
    hideLoader, hideLoaderMainContentArea, openErrorMessageModal,
    openModalDialog,
    showLoader, showLoaderMainContentArea
} from "./modalDialog.js";
import {
    getBooleanFromLocalStorage,
    getNumDistrictsForState,
    getStateMetadataFromLocalStorage,
    getStringFromLocalStorage,
    lsConsts,
    putBooleanInLocalStorage,
    putStringInLocalStorage,
    removeValueFromLocalStorage,
    URL_PRE
} from "./ddpLocalStorage.js";
import {
    goToLearningHome,
    retrieveHtmlTemplateForRouteKey,
    setCurrentDirtyEditorName,
    isInDemoMode
} from "./routingAndNav.js";
import {showDemoNotice, userSession} from "./userActivities.js";
import {messages} from "./messages.js";

const RANKED_ITEM_ID_PRE = 'ranked-item-';
const LEFT_NAV_CLASS = '.left-nav';
const RANKED_ITEM_PTS_ID_PRE = 'pts-box-value-';
const VIEW_CURR_RANKING_ID = 'view-current-ranking';
const ID_YES_CONTAINER = 'yesContainer';
const ID_UNDECIDED_CONTAINER = 'undecidedContainer';
const ID_NO_CONTAINER = 'noContainer';

let lastDragY; // This is used to reduce how often handleDragOver is executed.
let lastDragX; // This is used to reduce how often handleDragOver is executed.
let lastElementOver; // This is used to determine when the dragging leaves a suitable drop area.
let lastDraggingLocation = null; // This is used to clear a previous dragging location if the current one has moved.
const dragDropImg = new Image();
dragDropImg.src = "img/dragdropTransparent.png";
let yesChangeProblems;
let undecidedProblems;
let doNotChangeProblems;
let currRankVoteId; // voteId currently selected in the UI.
let itemsInRankVote; // Array of items in current rank vote.
let numCandidatesInRankVote;  // Number of items in current rank vote. Number used to determine points given to top ranked item.
let availPointsArray; // Array of integers from 0 to the value of top ranked item in rank vote, which is numCandidatesInRankVote.
let currRanking = {
    yesList: {
        top: null,
        bottom: null,
        size: 0,
        action: null
    },
    undecidedList: {
        top: null,
        bottom: null,
        size: 0,
        action: null
    },
    noList: {
        top: null,
        bottom: null,
        size: 0,
        action: null
    }
}
let rankedItem = {
    itemId: null,
    points: null,
    higher: null,
    lower: null
}

/*
This function is for initializing the page for users to edit their ranking of a list of Issues.
 */
export async function initUiEditRankVote(voteKey) {
    if (!isInDemoMode && !(userSession && userSession.isPwdAuthenticated)) {
        const rankedListContainer = document.getElementById("drop-area-content");
        rankedListContainer.innerHTML = messages.MUST_LOGIN;
        return;
    }

    if(document.querySelector(".ranked-item.grabbable") != null) {
        // ASSERT: this is a page with draggable elements

        if (!isInDemoMode) {
            const rankVoteIdMapping = await callAPI_getRankVoteId(voteKey);
            if (rankVoteIdMapping) {
                currRankVoteId = rankVoteIdMapping.voteId;

                const rankVoteItemList = await callAPI_getRankVoteItems(currRankVoteId);

                if (rankVoteItemList) {
                    console.dir(rankVoteItemList);
                    itemsInRankVote = rankVoteItemList.rankVoteItems;
                    numCandidatesInRankVote = itemsInRankVote.length;
                    populateAvailPtsForRankVote(numCandidatesInRankVote);
                    populateRankVoteItems();

                    // Arrange list according to user ranking
                    await moveRankVoteItemsIntoPosition();

                    console.log(`currRanking:`);
                    console.dir(currRanking);
                }
            }
        } else {
            const abstractButtons = document.querySelectorAll('.ranked-item-button-abstract');
            abstractButtons.forEach(elem => {
                elem.addEventListener('click', e => {
                    showDemoNotice();
                });
            });

            const itemStatsButtons = document.querySelectorAll(".ranked-item-button-stats");
            itemStatsButtons.forEach((item) => {
                item.addEventListener("click", showDemoNotice);
            });

            const itemTitles = document.querySelectorAll(".ranked-item-title");
            itemTitles.forEach((item) => {
                item.addEventListener("click", showDemoNotice);
            });

        }

        addEventListenersRankVoteEdit();
    }
}

function populateAvailPtsForRankVote(numCandidatesInRankVote) {
    availPointsArray = [];
    let val = (numCandidatesInRankVote * -1);
    for (let i = 0; i < (numCandidatesInRankVote * 2 + 1); i++) {
        availPointsArray[i] = val;
        val += 1;
    }
}

function addEventListenersRankVoteEdit() {
    yesChangeProblems = document.querySelector(".drop-area.yes-change");
    undecidedProblems = document.querySelector(".drop-area.undecided");
    doNotChangeProblems = document.querySelector(
        ".drop-area.no-do-not-change"
    );

    document.querySelector(".breadcrumbs").addEventListener("dragover", handleDragOver);
    document.querySelector(".header").addEventListener("dragover", handleDragOver);
    yesChangeProblems.addEventListener("dragover", handleDragOver);
    undecidedProblems.addEventListener("dragover", handleDragOver);
    doNotChangeProblems.addEventListener("dragover", handleDragOver);
    document.querySelector(LEFT_NAV_CLASS).addEventListener("dragover", handleDragOver);
    document.querySelector(".separator").addEventListener("dragover", handleDragOver);

    // The drag&drop dragOver objects are the divs that contain all the
    // draggable elements.
    const dropAreas = Array.from(document.querySelectorAll(".drop-area"));

    dropAreas.forEach(function (item) {
        item.addEventListener("drop", handleDrop);
    });

    // The objects for all other drag&drop events are the item
    // elements.
    let items = document.querySelectorAll(".ranked-item.grabbable");
    items.forEach(function (item) {
        item.addEventListener("dragstart", handleDragStart);
        item.addEventListener("dragend", handleDragEnd);
    });

    if (!isInDemoMode) {
        const itemAbstractButtons = document.querySelectorAll(".ranked-item-button-abstract");
        itemAbstractButtons.forEach((item) => {
            item.addEventListener("click", showIssueAbstract);
        });

        const itemStatsButtons = document.querySelectorAll(".ranked-item-button-stats");
        itemStatsButtons.forEach((item) => {
            item.addEventListener("click", showIssueStats);
        });

        const itemTitles = document.querySelectorAll(".ranked-item-title");
        itemTitles.forEach((item) => {
            item.addEventListener("click", showIssueAbstract);
        });
    }

    const ptsDropdowns = document.querySelectorAll(".pts-box-value");
    ptsDropdowns.forEach((item) => {
        item.addEventListener("change", handleRankVotePointsChange);
    });

    const saveBtn = document.getElementById('rank-edit-save');
    const cancelBtn = document.getElementById('rank-edit-cancel');

    saveBtn.addEventListener("click", handleSaveRankEdit);
    cancelBtn.addEventListener("click", handleCancelRankEdit);

    disableButtons();
}

function populateRankVoteItems () {
    // Add all problems to the undecided container to begin with
    let undecidedContainer = document.querySelector(".undecided");

    // Populate the undecided Problems container
    const templateRankedItem = document.getElementById(RANKED_ITEM_ID_PRE + '0');

    // Initialize currRanking
    initCurrRanking();

    // Add each rankedItem element and update its properties
    for (let i = 0; i < itemsInRankVote.length; i++) {
        let currItem = itemsInRankVote[i];
        let newRankedItem = templateRankedItem.cloneNode(true);

        undecidedContainer.appendChild(newRankedItem);
        updateRankedItemElemProps(currItem, newRankedItem);

        // Add item to model
        let newItem = {
            itemId: currItem.itemId,
            points: 0
        }
        addToEndOfList(currRanking.undecidedList, newItem);
    }

    // Remove the template item element
    undecidedContainer.removeChild(templateRankedItem);
}

function handleDragOver(e) {
    e.preventDefault();

    let letFunctionRun = false;

    if (Math.abs(lastDragY - e.clientY) > 9) {
        // This handler gets called too often. Just return unless the user
        // drags the element at least 10 pixels vertically or horizontally
        // since the last time we allowed this full method to run.
        letFunctionRun = true;
        lastDragY = e.clientY;
    }

    if (Math.abs(lastDragX - e.clientX) > 9) {
        // This handler gets called too often. Just return unless the user
        // drags the element at least 10 pixels vertically or horizontally
        // since the last time we allowed this full method to run.
        letFunctionRun = true;
        lastDragX = e.clientX;
    }

    if (!letFunctionRun) {
        return false;
    }

    // ASSERT: The element has been dragged far enough to run this function.
    // Allow the current dragging location calculations to run.

    // console.log(`New lastDragY: ${lastDragY}`);
    // console.log(`New lastDragX: ${lastDragX}`);
    // console.log(`Currently over: ${this.classList}`);

    if (lastElementOver.classList.contains("drop-area") && !this.classList.contains("drop-area")) {

        // ASSERT: The dragging has left any suitable drop area, so clear all dragging demarcations and return.

        yesChangeProblems.classList.remove("drag-over-empty");
        undecidedProblems.classList.remove("drag-over-empty");
        doNotChangeProblems.classList.remove("drag-over-empty");

        const draggableElements = [
            ...document.querySelectorAll(".ranked-item.grabbable:not(.dragging)"),
        ];

        draggableElements.forEach((item) =>
            item.classList.remove("closeAbove", "closeBelow")
        );

        return false;
    }

    // The currDraggingLocation will be a js object containing the closest element above and below
    // the current dragging location of the cursor.
    const currDraggingLocation = getCurrDraggingLocation(this, e.clientY);

    // Mark the ranked-item elements that are closest to the current dragging location.
    if (currDraggingLocation.closestAbove.element != null) {
        currDraggingLocation.closestAbove.element.classList.add("closeAbove");

        // console.log(
        //   `currClosestAbove: ${
        //     currDraggingLocation.closestAbove.element.getElementsByTagName("p")[0]
        //       .innerHTML
        //   }`
        // );
    }

    if (currDraggingLocation.closestBelow.element != null) {
        currDraggingLocation.closestBelow.element.classList.add("closeBelow");

        // console.log(
        //   `currClosestBelow: ${
        //     currDraggingLocation.closestBelow.element.getElementsByTagName("p")[0]
        //       .innerHTML
        //   }`
        // );
    }

    // Adjust the nearest ranked-item UI markings. Remove any that are no longer applicable
    // if the cursor has moved on to another location.
    if (lastDraggingLocation != null) {
        if (
            lastDraggingLocation.closestAbove.element != null &&
            lastDraggingLocation.closestAbove.element !==
            currDraggingLocation.closestAbove.element
        ) {
            // ASSERT: The last closestAbove element is no longer the closest
            // element above the current cursor location, so unmark it in the UI.
            lastDraggingLocation.closestAbove.element.classList.remove("closeAbove");
        }

        if (
            lastDraggingLocation.closestBelow.element != null &&
            lastDraggingLocation.closestBelow.element !==
            currDraggingLocation.closestBelow.element
        ) {
            // ASSERT: The last closestBelow element is no longer the closest
            // element below the current cursor location, so unmark it in the UI.
            lastDraggingLocation.closestBelow.element.classList.remove("closeBelow");
        }
    }

    // Mark the entire drop-area if it does not currently contain any items
    // other than the one currently being dragged.
    if (this === yesChangeProblems) {
        if (currDraggingLocation.closestAbove.element == null &&
            currDraggingLocation.closestBelow.element == null) {
            yesChangeProblems.classList.add("drag-over-empty");
        }
    }

    if (this === undecidedProblems) {
        if (currDraggingLocation.closestAbove.element == null &&
            currDraggingLocation.closestBelow.element == null) {
            undecidedProblems.classList.add("drag-over-empty");
        }
    }

    if (this === doNotChangeProblems) {
        if (currDraggingLocation.closestAbove.element == null &&
            currDraggingLocation.closestBelow.element == null) {
            doNotChangeProblems.classList.add("drag-over-empty");
        }
    }

    // Remove the drop-area demarcations if the dragging has moved from an empty drop-area
    // to another drop-area.
    if (lastElementOver === yesChangeProblems && this !== yesChangeProblems) {
        yesChangeProblems.classList.remove("drag-over-empty");
    }

    if (lastElementOver === undecidedProblems && this !== undecidedProblems) {
        undecidedProblems.classList.remove("drag-over-empty");
    }

    if (lastElementOver === doNotChangeProblems && this !== doNotChangeProblems) {
        doNotChangeProblems.classList.remove("drag-over-empty");
    }

    // Save the current cursor position state, so that we can unmark elements
    // that will be moved away from by the dragging cursor.
    lastDraggingLocation = currDraggingLocation;
    lastElementOver = this;

    return false;
}

// For drag and drop this method will return the closest elements, if any,
// above and below the current dragging cursor. If the dragging element
// is above an empty drop area, then no closest elements will be returned.
function getCurrDraggingLocation(dropContainer, yDraggingLocation) {
    const draggableElements = [
        ...dropContainer.querySelectorAll(".ranked-item.grabbable:not(.dragging)"),
    ];

    let initialValue = {
        closestAbove: {
            element: null,
            distanceToMiddle: Number.POSITIVE_INFINITY,
        },

        closestBelow: {
            element: null,
            distanceToMiddle: Number.POSITIVE_INFINITY,
        },
    };

    // This call to reduce() will go through all the items other than the one
    // being dragged and compare the distances, in the y direction, between the
    // current dragging cursor location and the middle of each ranked-item element. It
    // will return the closest one above and below the current cursor location. If
    // the cursor is dragging over an empty drop area the closestAbove and
    // closestBelow objects will be null. If closetBelow is null and closestAbove
    // is not that means the cursor is at the bottom of a drop area. If the opposite
    // is true then it's at the top of a drop area.
    return draggableElements.reduce((closest, rankedItem) => {
        const box = rankedItem.getBoundingClientRect();
        const middleY = box.top + box.height / 2;
        const distanceToMiddle = yDraggingLocation - middleY;

        if (
            distanceToMiddle < 0 &&
            Math.abs(distanceToMiddle) < closest.closestBelow.distanceToMiddle
        ) {
            // console.log(
            //   `New closestBelow: ${rankedItem.getElementsByTagName("p")[0].innerHTML}`
            // );

            closest.closestBelow.element = rankedItem;
            closest.closestBelow.distanceToMiddle = Math.abs(distanceToMiddle);

            return closest;
        } else if (
            distanceToMiddle > 0 &&
            distanceToMiddle < closest.closestAbove.distanceToMiddle
        ) {
            // console.log(
            //   `New closestAbove: ${rankedItem.getElementsByTagName("p")[0].innerHTML}`
            // );

            closest.closestAbove.element = rankedItem;
            closest.closestAbove.distanceToMiddle = distanceToMiddle;

            return closest;
        } else {
            if (closest.closestAbove.element != null) {
                // console.log(
                //   `Same closestAbove: ${
                //     closest.closestAbove.element.getElementsByTagName("p")[0].innerHTML
                //   }`
                // );
            }

            if (closest.closestBelow.element != null) {
                // console.log(
                //   `Same closestBelow: ${
                //     closest.closestBelow.element.getElementsByTagName("p")[0].innerHTML
                //   }`
                // );
            }

            return closest;
        }
    }, initialValue);
}

function initCurrRanking() {
    currRanking.yesList.action = null;
    currRanking.yesList.top = null;
    currRanking.yesList.bottom = null;
    currRanking.yesList.size = 0;

    currRanking.undecidedList.action = null;
    currRanking.undecidedList.top = null;
    currRanking.undecidedList.bottom = null;
    currRanking.undecidedList.size = 0;

    currRanking.noList.action = null;
    currRanking.noList.top = null;
    currRanking.noList.bottom = null;
    currRanking.noList.size = 0;
}

async function populateViewRankVoteItems() {
    // Populate the div with items
    const itemDiv = document.getElementById(VIEW_CURR_RANKING_ID);
    const templateItem = document.getElementById(RANKED_ITEM_ID_PRE + '0');

    // Add each item element and update its properties
    for (let i = 0; i < itemsInRankVote.length; i++) {
        let currItem = itemsInRankVote[i];
        let newItem = templateItem.cloneNode(true);

        itemDiv.appendChild(newItem);
        updateRankedItemElemProps(currItem, newItem);
    }

    // Remove the templateItem element
    itemDiv.removeChild(templateItem);

    // Move the issues into rank order
    const rankVoteResults = await callAPI_getRankVoteResults();

    if (rankVoteResults) {
        const rankedListContainer = document.getElementById("view-current-ranking");

        if (rankVoteResults.rankedItemStats.length > 0) {
            for (let i = 0; i < rankVoteResults.rankedItemStats.length; i++) {
                let currItem = rankVoteResults.rankedItemStats[i];
                let currItemId = RANKED_ITEM_ID_PRE + currItem.itemId;

                rankedListContainer.appendChild(document.getElementById(currItemId));
            }
        } else {
            const rankedListContainer = document.getElementById("view-current-ranking");
            rankedListContainer.innerHTML = `The ranking votes have not been tabulated yet. Check back in a few days.`;
        }
    }
}

function updateRankedItemElemProps (rankVoteItem, rankedItemElem) {

    rankedItemElem.setAttribute('id', RANKED_ITEM_ID_PRE + rankVoteItem.itemId);

    const childNodes = rankedItemElem.childNodes;
    for (let i = 0; i <childNodes.length; i++) {
        let currChild = childNodes[i];
        if (currChild.nodeName === 'P' && currChild.classList.contains('ranked-item-title')) {
            currChild.innerHTML = null;
            const newTitleText = document.createTextNode(rankVoteItem.title);
            currChild.appendChild(newTitleText);
        } else if (currChild.nodeName === 'DIV' && currChild.classList.contains('pts-box')) {
            const ptsBoxChildNodes = currChild.childNodes;
            for (let j = 0; j < ptsBoxChildNodes.length; j++) {
                let currPtsBoxChild = ptsBoxChildNodes[j];
                if (currPtsBoxChild.nodeName === 'SELECT') {
                    currPtsBoxChild.setAttribute('id', RANKED_ITEM_PTS_ID_PRE + rankVoteItem.itemId);

                    currPtsBoxChild.options.length = 0;
                    for (let i = (availPointsArray.length - 1); i >= 0; i--) {
                        let option = document.createElement("option");
                        let availPtsValue = availPointsArray[i];
                        option.value = availPtsValue;
                        option.text = availPtsValue;

                        currPtsBoxChild.add(option, null);
                    }

                    break;
                }
            }
        }
    }
}

function setItemPointsDropdown (itemId, pointValue) {
    const dropdownId = RANKED_ITEM_PTS_ID_PRE + itemId;
    const itemPtsDropdown = document.getElementById(dropdownId);
    itemPtsDropdown.value = pointValue;
}

async function moveRankVoteItemsIntoPosition () {
    // Ensure all problems are in the undecided container to begin with
    let yesContainer = document.querySelector(".yes-change");
    let noContainer = document.querySelector(".no-do-not-change");

    const userRankVote = await callAPI_getUserRanking();

    if (!userRankVote) {
        return;
    }

    console.dir(userRankVote);

    // Populate the Change Problems container
    let yesListItemArray = userRankVote.yesList;
    let orderedItems = [];

    if (yesListItemArray != null) {
        yesListItemArray.forEach(function (itemRank) {
            let ix = itemRank.rank - 1;
            // console.log(`ix: ${ix}`);

            orderedItems[ix] = {
                itemId: itemRank.itemId,
                points: itemRank.points
            };
        });

        // Populate the currRanking linked list. This linked list is the Model.
        let currItem = null;
        for (let i = 0; i < orderedItems.length; i++) {
            let currItemId = orderedItems[i].itemId;
            let newItem = removeItemFromList(currRanking.undecidedList, currItemId);
            newItem.points = orderedItems[i].points;
            addToEndOfList(currRanking.yesList, newItem);
        }

        // Use the Model linked list to move the View DOM elements into position.
        let cntr = 0;
        currItem = currRanking.yesList.top;
        while (currItem !== null) {
            let currItemId = currItem.itemId;
            let currItemElem = getRankedItemElem(currItemId)
            yesContainer.appendChild(currItemElem);
            setItemPointsDropdown(currItemId, currItem.points);
            currItem = currItem.lower;
            cntr++;
        }
    }

    // Populate the No list container
    let noListItemArray = userRankVote.noList;
    let noListOrderedArray = [];

    if (noListItemArray != null) {
        noListItemArray.forEach(function (itemRank) {
            let ix = itemRank.rank - 1;
            // console.log(`ix: ${ix}`);
            noListOrderedArray[ix] = {
                itemId: itemRank.itemId,
                points: itemRank.points
            };
        });

        // Populate the currRanking linked list. This is the Model.
        let currItem = null;
        for (let i = 0; i < noListOrderedArray.length; i++) {
            let newItem = removeItemFromList(currRanking.undecidedList, noListOrderedArray[i].itemId);
            newItem.points = noListOrderedArray[i].points;
            addToEndOfList(currRanking.noList, newItem);
        }

        // Use the linked list to move the View DOM elements into position
        let cntr = 0;
        currItem = currRanking.noList.top;
        while (currItem !== null) {
            let currItemId = currItem.itemId;
            noContainer.appendChild(getRankedItemElem(currItemId));
            setItemPointsDropdown(currItemId, currItem.points);
            currItem = currItem.lower;
            cntr++;
        }
    }

    // Use the linked list to set the undecided dropdowns to 0
    let currItem = currRanking.undecidedList.top;
    while (currItem) {
        let currItemId = currItem.itemId;
        setItemPointsDropdown(currItemId, currItem.points);
        currItem = currItem.lower;
    }
}

/*
This function is called whenever the user changes the value in the points dropdown, which
will likely change where the item is positioned within a container. It may also cause the
item to be moved to some location in another container. The containers from top to bottom are
the yesContainer, undecidedContainer, and noContainer.
 */
function handleRankVotePointsChange(event) {

    if (isInDemoMode) {
        showDemoNotice();
        return;
    }

    setCurrentDirtyEditorName('rankVoteEditor');
    enableButtons();
    let itemAboveId = null;
    let itemBelowId = null;
    const ptsDropdownElem = event.srcElement;
    const ptsValue = +ptsDropdownElem.value;
    const elemId = ptsDropdownElem.getAttribute('id');
    const itemId = getItemNumberFromId(elemId);
    let srcList = null;
    const theItemElem = document.getElementById(RANKED_ITEM_ID_PRE + itemId);
    const srcContainer = theItemElem.parentElement;

    // Find the modelItem being moved and remove if from its current model linked list
    if (srcContainer.classList.contains('undecided')) {
        srcList = currRanking.undecidedList;
    } else if (srcContainer.classList.contains('yes-change')) {
        srcList = currRanking.yesList;
    } else {
        srcList = currRanking.noList;
    }

    const movedItemModel = removeItemFromList(srcList, itemId);
    movedItemModel.points = ptsValue;

    // Place the modelItem in the correct place in the target linked list
    if (movedItemModel.points === 0) {
        const targetContainer = document.getElementById(ID_UNDECIDED_CONTAINER);
        const targetAboveAndBelowIds = placeItemInListAndUpdateSize(currRanking.undecidedList, movedItemModel);

        if (targetAboveAndBelowIds.itemBelowId) {
            const itemBelowElem = document.getElementById(RANKED_ITEM_ID_PRE + targetAboveAndBelowIds.itemBelowId);
            targetContainer.insertBefore(theItemElem, itemBelowElem);
        } else {
            targetContainer.appendChild(theItemElem);
        }
    } else if (movedItemModel.points > 0) {
        const targetContainer = document.getElementById(ID_YES_CONTAINER);
        const targetAboveAndBelowIds = placeItemInListAndUpdateSize(currRanking.yesList, movedItemModel);

        if (targetAboveAndBelowIds.itemBelowId) {
            const itemBelowElem = document.getElementById(RANKED_ITEM_ID_PRE + targetAboveAndBelowIds.itemBelowId);
            targetContainer.insertBefore(theItemElem, itemBelowElem);
        } else {
            targetContainer.appendChild(theItemElem);
        }
    } else {
        const targetContainer = document.getElementById(ID_NO_CONTAINER);
        const targetAboveAndBelowIds = placeItemInNoListAndUpdatePointsAndSize(currRanking.noList, movedItemModel);

        if (targetAboveAndBelowIds.itemBelowId) {
            const itemBelowElem = document.getElementById(RANKED_ITEM_ID_PRE + targetAboveAndBelowIds.itemBelowId);
            targetContainer.insertBefore(theItemElem, itemBelowElem);
        } else {
            targetContainer.appendChild(theItemElem);
        }
    }

    setYesListPoints();

    updateRankEditPointsInUi();
}

/*
This method is only called if an item is placed in the noList by its points value changing.
The point of this method is to identify what items will be above and below the moved item when it
does get relocated. If the points value was changed to a value of an existing item in the
list, then this method will also change point values of the displaced item, and any
others that may need to change in a "bump points up or down" call.
 */
function placeItemInNoListAndUpdatePointsAndSize(noList, movedItem) {
    const retVal = {
        itemAboveId: null,
        itemBelowId: null
    };

    let currItem = noList.top;
    while (currItem && currItem.points > movedItem.points) {
        // Point to next item in the list
        currItem = currItem.lower;
    }

    // ASSERT: currItem points is less than or equal to new points value or null.
    let itemAbove = null;
    let itemBelow = null;
    if (currItem) {
        if (currItem.points === movedItem.points) {
            // ASSERT: the movedItem's points were set to the same value as currItem,
            // so bump its points up or down to make room between the itemAbove and
            // itemBelow for the later method that updates the points for all the items
            // in this list.
            const numItemsAbove = getNumItemsAbove(noList, currItem);
            const desiredPointsValue = Math.abs(movedItem.points);

            if (desiredPointsValue <= numItemsAbove) {
                // ASSERT: No dice. Gotta bump down.
                bumpPointsDown(noList, currItem);
                itemBelow = currItem;
                if (currItem.higher) {
                    // ASSERT: movedItem is added in the middle of the list somewhere
                    itemAbove = currItem.higher;
                    itemAbove.lower = movedItem;
                    movedItem.higher = itemAbove;
                    movedItem.lower = currItem;
                    currItem.higher = movedItem;
                } else {
                    // ASSERT: movedItem is added to top of list
                    noList.top = movedItem;
                    movedItem.higher = null;
                    itemAbove = null;
                    movedItem.lower = currItem;
                    currItem.higher = movedItem;
                }
            } else {
                bumpPointsUp(noList, currItem);
                itemAbove = currItem;
                if (currItem.lower) {
                    // ASSERT: movedItem is added in the middle of the list somewhere
                    itemBelow = currItem.lower;
                    itemBelow.higher = movedItem;
                    movedItem.lower = itemBelow;
                    currItem.lower = movedItem;
                    movedItem.higher = currItem;
                } else {
                    // ASSERT: movedItem is added to bottom of list
                    itemBelow = null;
                    noList.bottom = movedItem;
                    movedItem.lower = null;
                    movedItem.higher = currItem;
                    currItem.lower = movedItem;
                }
            }
        } else {
            // ASSERT: there is room in this spot for the movedItem's
            // new points value.
            if (noList.top === currItem) {
                // ASSERT: movedItem is moving into top spot.
                noList.top = movedItem;
                movedItem.lower = currItem;
                movedItem.higher = null;
                currItem.higher = movedItem;
                itemAbove = null;
            } else {
                // ASSERT: movedItem is moving into the middle of the
                // list somewhere.
                itemAbove = currItem.higher;
                itemAbove.lower = movedItem;
                movedItem.higher = itemAbove;
                currItem.higher = movedItem;
                movedItem.lower = currItem;
            }

            itemBelow = currItem;
        }
    } else {
        // ASSERT: the movedItem will move to the bottom of the list.
        if (noList.bottom) {
            // ASSERT: there currently is a bottom item in the list.
            itemAbove = noList.bottom;
            movedItem.higher = itemAbove;
            itemAbove.lower = movedItem;
            noList.bottom = movedItem;
            itemBelow = null;
        } else {
            // ASSERT: the noList was empty
            itemAbove = null;
            itemBelow = null;
            noList.top = movedItem;
            noList.bottom = movedItem;

            // This is the first item dragged into the noList, so default
            // it to -1 points if it's dragged in from another container.
            if (movedItem.points > -1) {
                movedItem.points = -1;
            }
        }
    }

    if (itemAbove) {
        retVal.itemAboveId = itemAbove.itemId;
    }

    if (itemBelow) {
        retVal.itemBelowId = itemBelow.itemId;
    }

    // movedItem was added to the noList, so bump the size value
    noList.size++;

    return retVal;
}

/*
This method identifies what items will be above and below the moved item. It also
places the movedItem into the linked list and updates the size of the list.
 */
function placeItemInListAndUpdateSize(list, movedItem) {
    const retVal = {
        itemAboveId: null,
        itemBelowId: null
    };

    let currItem = list.top;
    while (currItem && currItem.points > movedItem.points) {
        // Point to next item in the list
        currItem = currItem.lower;
    }

    // ASSERT: currItem points is less than or equal to new points value or null.
    let itemAbove = null;
    let itemBelow = null;
    if (currItem) {
        // ASSERT: there is room in this spot for the movedItem
        // above the currItem.
        if (list.top === currItem) {
            // ASSERT: movedItem is moving into top spot.
            list.top = movedItem;
            movedItem.lower = currItem;
            currItem.higher = movedItem;
            itemAbove = null;
        } else {
            // ASSERT: movedItem is moving into the middle of the
            // list somewhere.
            itemAbove = currItem.higher;
            itemAbove.lower = movedItem;
            movedItem.higher = itemAbove;
            currItem.higher = movedItem;
            movedItem.lower = currItem;
        }

        itemBelow = currItem;
    } else {
        // ASSERT: the movedItem will move to the bottom of the list.
        if (list.bottom) {
            // ASSERT: there currently is a bottom item in the list.
            itemAbove = list.bottom;
            movedItem.higher = itemAbove;
            itemAbove.lower = movedItem;
            list.bottom = movedItem;
            itemBelow = null;
        } else {
            // ASSERT: the list was empty
            itemAbove = null;
            itemBelow = null;
            list.top = movedItem;
            list.bottom = movedItem;
        }
    }

    if (itemAbove) {
        retVal.itemAboveId = itemAbove.itemId;
    }

    if (itemBelow) {
        retVal.itemBelowId = itemBelow.itemId;
    }

    // movedItem was added to the list, so bump the size value
    list.size++;

    return retVal;
}

function getRankingItemFromMetadataList(list, itemId) {
    let retVal = null;
    let currItem = list.top;
    while (currItem) {
        if (currItem.itemId == itemId) {
            retVal = currItem;
            break;
        }

        // Point to next item in the list
        currItem = currItem.lower;
    }

    return retVal;
}

async function handleSaveRankEdit() {
    if (!(userSession && userSession.isPwdAuthenticated)) {
        const rankedListContainer = document.getElementById("drop-area-content");
        rankedListContainer.innerHTML = messages.MUST_LOGIN;
        return;
    }

    let json = {};
    json.acctId = userSession.id;
    json.voteId = currRankVoteId;
    json.yesList = [];
    json.noList = [];

    let yesContainer = document.querySelector(".yes-change");
    let noContainer = document.querySelector(".no-do-not-change");

    const yesArray = Array.from(yesContainer.children);
    yesArray.forEach(function (currItem, ix) {
        let idStr = currItem.getAttribute("id");
        idStr = idStr.substring(idStr.lastIndexOf('-') + 1, idStr.length);
        const ptsBoxSelectElem = document.getElementById(RANKED_ITEM_PTS_ID_PRE + idStr);

        json.yesList[ix] = {
            itemId: idStr,
            rank: ix + 1,
            points: ptsBoxSelectElem.value
        };
    });

    console.log(json.yesList);

    const noArray = Array.from(noContainer.children);
    noArray.forEach(function (currItem, ix) {
        let idStr = currItem.getAttribute("id");
        idStr = idStr.substring(idStr.lastIndexOf('-') + 1, idStr.length);
        const ptsBoxSelectElem = document.getElementById(RANKED_ITEM_PTS_ID_PRE + idStr);

        json.noList[ix] = {
            itemId: idStr,
            rank: ix + 1,
            points: ptsBoxSelectElem.value
        };
    });

    console.log(json.noList);

    showLoaderMainContentArea();
    try {
        const updateSucceeded = await callAPI_updateUserRanking(json);

        if (updateSucceeded) {
            setCurrentDirtyEditorName(null);
            disableButtons();
        }
    } catch (err) {
        console.dir(err);
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        setCurrentDirtyEditorName(null);
        goToLearningHome();
    }
    hideLoaderMainContentArea();
}

function enableButtons() {
    const saveBtn = document.getElementById('rank-edit-save');
    const cancelBtn = document.getElementById('rank-edit-cancel');

    saveBtn.disabled = false;
    cancelBtn.disabled = false;
}

function disableButtons() {
    const saveBtn = document.getElementById('rank-edit-save');
    const cancelBtn = document.getElementById('rank-edit-cancel');

    saveBtn.disabled = true;
    cancelBtn.disabled = true;
}

function handleDrop(e) {
    console.log("handleDrop >>>");

    if (isInDemoMode) {
        showDemoNotice();
        return;
    }

    setCurrentDirtyEditorName('rankVoteEditor');
    enableButtons();

    e.stopPropagation(); // stops the browser from redirecting.
    const targetContainer = e.currentTarget;
    const draggedElemId = e.dataTransfer.getData("text/plain");
    const theItemElem = document.getElementById(draggedElemId);
    const itemId = getItemNumberFromId(draggedElemId);
    const srcContainer = theItemElem.parentElement;
    const targetLocAbove = lastDraggingLocation.closestAbove;
    const targetLocBelow = lastDraggingLocation.closestBelow;
    let itemAboveId = null;
    let itemBelowId = null;
    let srcList = null;

    console.dir(targetLocAbove);
    console.dir(targetLocBelow);
    console.dir(srcContainer);
    console.dir(this);

    // Find the modelItem being moved and remove if from its current model linked list
    if (srcContainer.classList.contains('undecided')) {
        srcList = currRanking.undecidedList;
    } else if (srcContainer.classList.contains('yes-change')) {
        srcList = currRanking.yesList;
    } else {
        srcList = currRanking.noList;
    }

    const movedItemModel = removeItemFromList(srcList, itemId);

    if (targetLocAbove.element) {
        itemAboveId = getItemNumberFromId(targetLocAbove.element.getAttribute('id'));
    }

    if (targetLocBelow.element) {
        itemBelowId = getItemNumberFromId(targetLocBelow.element.getAttribute('id'));
    }

    if (targetContainer.classList.contains('undecided')) {
        movedItemModel.points = 0;
        const targetAboveAndBelowIds = placeItemInListAndUpdateSize(currRanking.undecidedList, movedItemModel);

        if (targetAboveAndBelowIds.itemBelowId) {
            const itemBelowElem = document.getElementById(RANKED_ITEM_ID_PRE + targetAboveAndBelowIds.itemBelowId);
            targetContainer.insertBefore(theItemElem, itemBelowElem);
        } else {
            targetContainer.appendChild(theItemElem);
        }
    } else if (targetContainer.classList.contains('yes-change')) {
        if (itemBelowId) {
            movedItemModel.points = getPointsInItem(currRanking.yesList, +itemBelowId);
        } else if (itemAboveId) {
            movedItemModel.points = getPointsInItem(currRanking.yesList, +itemAboveId) -1;
        } else {
            movedItemModel.points = 10;
        }
        const targetAboveAndBelowIds = placeItemInListAndUpdateSize(currRanking.yesList, movedItemModel);

        if (targetAboveAndBelowIds.itemBelowId) {
            const itemBelowElem = document.getElementById(RANKED_ITEM_ID_PRE + targetAboveAndBelowIds.itemBelowId);
            targetContainer.insertBefore(theItemElem, itemBelowElem);
        } else {
            targetContainer.appendChild(theItemElem);
        }
    } else {
        // The drag target container is the noList container
        if (itemAboveId && itemBelowId) {
            const pointsAbove = getPointsInItem(currRanking.noList, +itemAboveId);
            const pointsBelow = getPointsInItem(currRanking.noList, +itemBelowId);
            if (pointsAbove - 1 > pointsBelow) {
                // ASSERT: Theres room between without bumping.
                movedItemModel.points = pointsAbove -1;
            } else {
                const itemAboveModel = getItemForId(currRanking.noList, itemAboveId);
                const numItemsAbove = getNumItemsAbove(currRanking.noList, itemAboveModel);
                const desiredPointsValue = Math.abs(pointsAbove);

                if (desiredPointsValue <= numItemsAbove) {
                    // ASSERT: there's no room to bump the above item up in points, so
                    // take the points from the item below, and it'll be bumped down.
                    movedItemModel.points = pointsBelow;
                } else {
                    // ASSERT: there's room to bump the above item's points up, and take
                    // its points.
                    movedItemModel.points = pointsAbove;
                }
            }
        } else if (itemAboveId) {
            // ASSERT: movedItem is being placed at the bottom of the noList container.
            const ptsAbove = getPointsInItem(currRanking.noList, +itemAboveId);
            if (ptsAbove > (numCandidatesInRankVote * -1)) {
                // ASSERT: There's room below the item above
                movedItemModel.points = ptsAbove - 1;
            } else {
                // ASSERT: There's no room below the item above, so grab its point value
                // and allow its value to be bumped up
                movedItemModel.points = ptsAbove;
            }
        } else {
            // ASSERT: movedItem is being placed at the top of the noList container.
            const ptsBelow = getPointsInItem(currRanking.noList, +itemBelowId);
            if (ptsBelow < -1) {
                // ASSERT: There's room above the item above.
                movedItemModel.points = ptsBelow + 1;
            } else {
                // ASSERT: There's no room above the item below, so grab its point value
                // and allow its value to be bumped down.
                movedItemModel.points = ptsBelow;
            }
        }
        const targetAboveAndBelowIds = placeItemInNoListAndUpdatePointsAndSize(currRanking.noList, movedItemModel);

        if (targetAboveAndBelowIds.itemBelowId) {
            const itemBelowElem = document.getElementById(RANKED_ITEM_ID_PRE + targetAboveAndBelowIds.itemBelowId);
            targetContainer.insertBefore(theItemElem, itemBelowElem);
        } else {
            targetContainer.appendChild(theItemElem);
        }
    }

    // Update the UI
    theItemElem.style.opacity = "1";

    setYesListPoints();

    updateRankEditPointsInUi();

    return false;
}

function updateRankEditPointsInUi() {

    let currItem = currRanking.yesList.top;
    while (currItem != null) {
        setItemPointsDropdown(currItem.itemId, currItem.points);
        currItem = currItem.lower;
    }

    currItem = currRanking.undecidedList.top;
    while (currItem != null) {
        setItemPointsDropdown(currItem.itemId, currItem.points);
        currItem = currItem.lower;
    }

    currItem = currRanking.noList.top;
    while (currItem != null) {
        setItemPointsDropdown(currItem.itemId, currItem.points);
        currItem = currItem.lower;
    }

}

function setYesListPoints() {
    // Store points values not available for yesList
    const ptsTakenByNoList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let currItem = currRanking.noList.top;
    while (currItem != null) {
        let absoluteValueTaken = Math.abs(currItem.points);
        ptsTakenByNoList[absoluteValueTaken - 1] = 1;
        currItem = currItem.lower;
    }

    let desiredPtsValue = numCandidatesInRankVote;
    currItem = currRanking.yesList.top;
    while (currItem != null) {
        while (ptsTakenByNoList[desiredPtsValue - 1]) {
            desiredPtsValue--;
        }

        currItem.points = desiredPtsValue;
        desiredPtsValue--;
        currItem = currItem.lower;
    }
}

function getItemForId(list, itemId) {
    let retVal = 0;
    let currItem = list.top;
    while (currItem != null) {
        if (currItem.itemId === itemId) {
            retVal = currItem;
            break;
        }

        currItem = currItem.lower;
    }

    return retVal;
}

function getNumItemsAbove(list, item) {
    let currItem = item;
    let retVal = 0;
    while (currItem != null) {
        retVal++;
        currItem = currItem.higher;
    }

    return retVal;
}

function bumpPointsUp(list, item) {
    item.points++;
    let currItem = item;
    while (currItem != null) {
        if (currItem.higher && currItem.higher.points > currItem.points) {
            // ASSERT: There's room, so stop bumping
            break;
        } else {
            if (currItem.higher) {
                currItem.higher.points++;
            }
        }

        currItem = currItem.higher;
    }
}

function bumpPointsDown(list, item) {
    item.points--;
    let currItem = item;
    while (currItem != null) {
        if (currItem.lower && currItem.lower.points < currItem.points) {
            // ASSERT: There's room, so stop bumping
            break;
        } else {
            if (currItem.lower) {
                currItem.lower.points--;
            }
        }

        currItem = currItem.lower;
    }
}

function getPointsInItem(list, itemId) {
    let retVal = 0;
    let currItem = list.top;
    while (currItem != null) {
        if (currItem.itemId === itemId) {
            retVal = currItem.points;
            break;
        }

        currItem = currItem.lower;
    }

    return retVal;
}

function removeItemFromList(list, itemId) {
    let currItem = list.top;
    let prevItem = null;
    while (currItem.itemId != itemId) {
        prevItem = currItem;
        currItem = currItem.lower;
    }

    // ASSERT: currItem points to the item to be removed, and if
    // not null prevItem points to the item above the one to remove.
    const retVal = currItem;
    if (list.top === currItem) {
        // ASSERT: item being moved was the top item in list
        const lowerItem = currItem.lower;
        if (lowerItem) {
            // ASSERT: there was a second item in the list, so
            // make it the new top item.
            lowerItem.higher = null;
            list.top = lowerItem;
        } else {
            // ASSERT: item being moved was the only item in list.
            list.top = null;
            list.bottom = null;
            list.size = 0;
        }
    } else if (list.bottom === currItem) {
        // ASSERT: item being moved was the bottom item in list
        const higherItem = currItem.higher;
        if (higherItem) {
            // ASSERT: there was a second lowest in the list, so
            // make it the new bottom item.
            higherItem.lower = null;
            list.bottom = higherItem;
        } else {
            // ASSERT: item being moved was the only item in list.
            list.bottom = null;
            list.top = null;
            list.size = 0;
        }
    } else {
        // ASSERT: item removed from middle of list
        let nextItem = currItem.lower;
        prevItem.lower = nextItem;
        nextItem.higher = prevItem;
    }

    updateListSize(list);

    retVal.higher = null;
    retVal.lower = null;
    return retVal;
}

function addToEndOfList(list, item) {
    if (list.bottom) {
        list.bottom.lower = item;
        item.higher = list.bottom;
        item.lower = null;
        list.bottom = item;
    } else {
        list.top = item;
        list.bottom = item;
        item.higher = null;
        item.lower = null;
    }

    updateListSize(list);
}

function updateListSize(list) {
    if (list.top) {
        let currItem = list.top;
        let cntr = 1;
        while (currItem != list.bottom) {
            currItem = currItem.lower;
            cntr++;
        }

        list.size = cntr;
    } else {
        list.bottom = null;
        list.size = 0;
    }
}

function moveItem (srcList, targetList, itemId, itemAboveId, itemBelowId) {
    let movedItem = null;
    let currItem = srcList.top;
    while (currItem.itemId != itemId) {
        currItem = currItem.lower;
    }

    // ASSERT: currItem is the item that has moved. Remove
    // it from the list.
    movedItem = currItem;

    if (movedItem === srcList.top) {
        // ASSERT: the item moved was the top ranked
        srcList.top = movedItem.lower;
        if (srcList.top) {
            srcList.top.higher = null;
        } else {
            // ASSERT: this removes the last item
            srcList.bottom = null;
        }
    } else if(srcList.bottom === movedItem) {
        // ASSERT: the item moved was the bottom ranked
        srcList.bottom = movedItem.higher;
        if (srcList.bottom) {
            srcList.bottom.lower = null;
        } else {
            // ASSERT: this removes the last item
            srcList.top = null;
        }
    } else {
        // ASSERT: the item moved was in the middle somewhere
        movedItem.higher.lower = movedItem.lower;
        movedItem.lower.higher = movedItem.higher;
    }

    // Reset the list size
    srcList.size--;

    // reset the movedItem pointers
    movedItem.lower = null;
    movedItem.higher = null;

    // Add the movedItem to it's new location
    if (!itemAboveId) {
        // ASSERT: item was moved to top spot.
        movedItem.lower = targetList.top;
        if (targetList.top) {
            targetList.top.higher = movedItem;
        } else {
            // ASSERT: the target list was empty
            targetList.bottom = movedItem;
        }
        targetList.top = movedItem;
    } else if (!itemBelowId) {
        // ASSERT: item was moved to bottom spot.
        movedItem.higher = targetList.bottom;
        if (targetList.bottom) {
            targetList.bottom.lower = movedItem;
        } else {
            // ASSERT: the target list was empty
            targetList.top = movedItem;
        }
        targetList.bottom = movedItem;
    } else {
        // ASSERT: item was moved between 2 items.
        currItem = targetList.top;
        while (currItem.itemId != itemBelowId) {
            currItem = currItem.lower;
        }

        // ASSERT: currItem points to item below the
        // insert location.
        currItem.higher.lower = movedItem;
        movedItem.higher = currItem.higher;
        movedItem.lower = currItem;
        currItem.higher = movedItem;
    }

    // Reset the list size
    targetList.size++;

    return movedItem;
}

function handleDragEnd(e) {
    console.log("handleDragEnd >>>");

    let items = document.querySelectorAll(".ranked-item.grabbable");

    items.forEach(function (item) {
        item.classList.remove("dragging");
        item.classList.remove("closeAbove", "closeBelow");
        item.style.opacity = "1";
    });

    yesChangeProblems.classList.remove("drag-over-empty");
    undecidedProblems.classList.remove("drag-over-empty");
    doNotChangeProblems.classList.remove("drag-over-empty");

    return false;
}

function getItemNumberFromId(itemId) {
    return itemId.substring(itemId.lastIndexOf('-') + 1, itemId.length);
}

async function showIssueAbstract(clickEvent) {
    const {target} = clickEvent;
    const modalDialog = getModalDialog();

    showLoader();
    try {
        modalDialog.innerHTML = await retrieveHtmlTemplateForRouteKey('/issueAbstract');

        let titleElem = null;

        if (target.classList.contains('ranked-item-button-abstract')) {
            titleElem = target.parentElement.previousElementSibling;
        } else {
            titleElem = target;
        }

        const titleStr = titleElem.innerText;
        let abstractStr;
        let idStr = titleElem.parentElement.getAttribute('id');
        idStr = idStr.substring(idStr.lastIndexOf('-') + 1, idStr.length);

        let issue;
        for (let i = 0; i < itemsInRankVote.length; i++) {
            let currIssue = itemsInRankVote[i];
            const idInt = parseInt(idStr, 10);
            if (currIssue.itemId == idInt) {
                abstractStr = currIssue.description;
                console.dir(currIssue);
                break;
            }
        }

        const childNodes = modalDialog.firstChild.childNodes;
        for (let i = 0; i < childNodes.length; i++) {
            let currChild = childNodes[i];
            if (currChild.nodeName === 'H1') {
                currChild.innerText = titleStr;
            } else if (currChild.nodeName === 'P') {
                currChild.innerText = abstractStr;
            }
        }

        openModalDialog();
        const okBtn = document.querySelector(".ok-btn");
        okBtn.addEventListener("click", closeModalDialog);
    } catch (err) {
        console.dir(err);
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        setCurrentDirtyEditorName(null);
        goToLearningHome();
    }
    hideLoader();
}

async function showIssueStats(clickEvent) {
    const {target} = clickEvent;
    const modalDialog = getModalDialog();

    showLoader();
    try {
        modalDialog.innerHTML = await retrieveHtmlTemplateForRouteKey('/issueStats');

        const buttonBar = target.parentElement;
        const itemTitle = buttonBar.previousElementSibling.innerHTML;
        let idStr = buttonBar.parentElement.getAttribute('id');
        idStr = idStr.substring(idStr.lastIndexOf('-') + 1, idStr.length);

        // Get the issue statistics from the voting results
        const theurl = URL_PRE + `/rest/vote/rank/results?voteId=${currRankVoteId}`;
        console.log(`Calling API: GET ${theurl}`);

        const response = await fetch(theurl, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include'
        });
        const rankVoteResults = await response.json();

        const rankedItemStats = rankVoteResults.rankedItemStats;
        let itemStats = null;
        for (let i = 0; i < rankedItemStats.length; i++) {
            let currItem = rankedItemStats[i];
            if (currItem.itemId == idStr) {
                itemStats = currItem;
                break;
            }
        }

        document.getElementById('item-title').innerHTML = itemTitle;
        document.getElementById('issue-stats-pts').innerHTML = itemStats.totalPoints;
        document.getElementById('issue-stats-votes-for').innerHTML = itemStats.totalVotesFor;
        document.getElementById('issue-stats-votes-against').innerHTML = itemStats.totalVotesAgainst;
        document.getElementById('issue-stats-votes-undecided').innerHTML = itemStats.totalVotesUndecided;
        document.getElementById('issue-stats-total-voters').innerHTML = rankVoteResults.totalVoters;
        document.getElementById('issue-stats-rank').innerHTML = itemStats.itemRank;

        openModalDialog();
        const okBtn = document.querySelector(".ok-btn");
        okBtn.addEventListener("click", closeModalDialog);
    } catch (err) {
        console.dir(err);
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        setCurrentDirtyEditorName(null);
        goToLearningHome();
    }
    hideLoader();
}

function handleDragStart(e) {
    console.log("handleDragStart >>>");
    this.style.opacity = "0.4";
    lastElementOver = this;

    this.classList.add("dragging");
    lastDragY = e.clientY;
    // console.log(`dragstart at y = ${lastDragY}`);
    lastDragX = e.clientX;
    // console.log(`dragstart at x = ${lastDragX}`);

    e.dataTransfer.clearData();
    e.dataTransfer.effectAllowed = "move";
    let srcId = e.target.id;
    e.dataTransfer.setData("text/plain", srcId);

    e.dataTransfer.setDragImage(
        dragDropImg,
        (dragDropImg.width * 2) / 3,
        dragDropImg.height * 0.75
    );

    return false;
}

function handleCancelRankEdit() {
    let currPath = window.location.pathname;
    currPath = currPath.substring(1, currPath.length);
    setCurrentDirtyEditorName(null);
    document.getElementById(`${currPath}-link`).click();
}

function getRankedItemElem (rankedItemId) {
    let undecidedContainer = document.querySelector(".undecided");
    const problemArray = Array.from(undecidedContainer.children);
    const elemId = RANKED_ITEM_ID_PRE + rankedItemId;

    for (var i = 0; i < problemArray.length; i++) {
        let currProblem = problemArray[i];
        let currId = currProblem.getAttribute("id");
        if (currId == elemId) {
            return currProblem;
        }
    }
}

/*
This function is for initializing the page for users to edit their ranking of a list of Lottery winners (candidates).
 */
export async function initUiLotteryWinnersEditMyRanking() {
    if (isInDemoMode) {
        const stateDropdown = document.getElementById('state-dropdown');
        stateDropdown.addEventListener('change', e => {
            showDemoNotice();
        });

        const districtDropdown = document.getElementById('district-num');
        districtDropdown.addEventListener('change', e => {
            showDemoNotice();
        });

        const abstractButtons = document.querySelectorAll('.ranked-item-button-abstract');
        abstractButtons.forEach(elem => {
            elem.addEventListener('click', e => {
                showDemoNotice();
            });
        });

        const itemStatsButtons = document.querySelectorAll(".ranked-item-button-stats");
        itemStatsButtons.forEach((item) => {
            item.addEventListener("click", showDemoNotice);
        });

        const itemTitles = document.querySelectorAll(".ranked-item-title");
        itemTitles.forEach((item) => {
            item.addEventListener("click", showDemoNotice);
        });

        addEventListenersRankVoteEdit();
        return;
    }

    if (!(userSession && userSession.isPwdAuthenticated)) {
        const rankedListContainer = document.getElementById("drop-area-content");
        rankedListContainer.innerHTML = messages.MUST_LOGIN;
        return;
    }

    let voteKey = userSession.addr_state;

    const stateDropdown = document.getElementById("state-dropdown");
    stateDropdown.value = userSession.addr_state;

    // Add only the user's state to the dropdown. They should not be able to vote for other states.
    const stateOption = document.createElement("option");
    stateOption.value = userSession.addr_state;
    stateOption.text = userSession.addr_state;
    stateDropdown.add(stateOption, null);

    // Only create an option for the user's district and an empty one they can choose in order
    // to bring up the senate winners ranking.
    const districtNumDropdown = document.getElementById("district-num");
    let option = document.createElement("option");

    option.value = " ";
    option.text = " ";
    districtNumDropdown.add(option, null);

    option = document.createElement("option");
    option.value = userSession.congress_district;
    option.text = userSession.congress_district;
    districtNumDropdown.add(option, null);

    const isShowDistrictRace = getBooleanFromLocalStorage(lsConsts.LS_SHOW_DISTRICT_KEY);
    if (isShowDistrictRace) {
        voteKey += "-" + userSession.congress_district;
        districtNumDropdown.value = userSession.congress_district;
    } else {
        districtNumDropdown.value = " ";
    }

    districtNumDropdown.addEventListener("change", (event) => {
        const dropdownValue = districtNumDropdown.value;

        if (dropdownValue === " ") {
            // ASSERT: User wants to edit the rank of the senate race candidates.
            putBooleanInLocalStorage(lsConsts.LS_SHOW_DISTRICT_KEY, false);
            document.getElementById(`lotteryWinnersEditMyRanking-link`).click();
        } else {
            // ASSERT: User wants to edit the rank of the district race candidates.
            putBooleanInLocalStorage(lsConsts.LS_SHOW_DISTRICT_KEY, true);
            document.getElementById(`lotteryWinnersEditMyRanking-link`).click();
        }
    });

    if(document.querySelector(".ranked-item.grabbable") != null) {
        // ASSERT: this is a page with draggable elements

        const rankVoteIdMapping = await callAPI_getRankVoteId(voteKey);
        if (rankVoteIdMapping) {
            currRankVoteId = rankVoteIdMapping.voteId;

            if (currRankVoteId) {
                const rankVoteItemList = await callAPI_getRankVoteItems();

                if (rankVoteItemList) {
                    console.dir(rankVoteItemList);
                    itemsInRankVote = rankVoteItemList.rankVoteItems;
                    numCandidatesInRankVote = itemsInRankVote.length;
                    populateAvailPtsForRankVote(numCandidatesInRankVote);
                    populateRankVoteItems();

                    addEventListenersRankVoteEdit();

                    // Arrange list according to user ranking
                    await moveRankVoteItemsIntoPosition();

                    console.dir(currRanking);
                }
            } else {
                alert(`Either the lottery has not been run yet, or nobody has entered the lottery for this state or congressional district. Check back later.`);
            }
        }
    }
}

function handleSenateRaceBtn() {
    const districtNumDropdown = document.getElementById("district-num");
    districtNumDropdown.value = " ";

    // ASSERT: User wants to view the ranking of the senate race candidates.
    removeValueFromLocalStorage(lsConsts.LS_CURR_DISTRICT_KEY);

    // Reload the page to bring in the chosen state's lottery winners
    document.getElementById(`lotteryWinnersViewCurrentRanking-link`).click();
}

/*
This function is for initializing the page for users to view the current ranking of a list of Lottery winners (candidates).
 */
export async function initUiLotteryWinnersViewCurrentRanking() {
    if (isInDemoMode) {
        const stateDropdown = document.getElementById('state-dropdown');
        stateDropdown.addEventListener('change', e => {
            showDemoNotice();
        });

        const districtDropdown = document.getElementById('district-num');
        districtDropdown.addEventListener('change', e => {
            showDemoNotice();
        });

        const senateCandidatesBtn = document.getElementById('view-senate-candidates');
        senateCandidatesBtn.addEventListener('click', e => {
            showDemoNotice();
        });

        const abstractButtons = document.querySelectorAll('.ranked-item-button-abstract');
        abstractButtons.forEach(elem => {
            elem.addEventListener('click', e => {
                showDemoNotice();
            });
        });

        const itemStatsButtons = document.querySelectorAll(".ranked-item-button-stats");
        itemStatsButtons.forEach((item) => {
            item.addEventListener("click", showDemoNotice);
        });

        const itemTitles = document.querySelectorAll(".ranked-item-title");
        itemTitles.forEach((item) => {
            item.addEventListener("click", showDemoNotice);
        });

        return;
    }

    const statesMetadata = getStateMetadataFromLocalStorage();
    const stateDropdown = document.getElementById("state-dropdown");
    const senateRaceBtn = document.getElementById('view-senate-candidates');
    senateRaceBtn.addEventListener('click', handleSenateRaceBtn);

    let currState = getStringFromLocalStorage(lsConsts.LS_CURR_STATE_KEY);
    if (!currState) {
        if (userSession) {
            currState = userSession.addr_state;
        }
    }
    stateDropdown.value = currState;
    let voteKey = currState;

    let numDistricts = getNumDistrictsForState(currState, statesMetadata);

    const districtNumDropdown = document.getElementById("district-num");

    for (let i = 0; i < numDistricts + 1; i++) {
        let option = document.createElement("option");
        option.value = i;
        option.text = i;

        if (i === 0) {
            option.value = " ";
            option.text = " ";
        }

        districtNumDropdown.add(option, null);
    }

    const selectedDistrict = getStringFromLocalStorage(lsConsts.LS_CURR_DISTRICT_KEY);
    if (selectedDistrict) {
        senateRaceBtn.disabled = false;
        districtNumDropdown.value = selectedDistrict;
        voteKey += "-" + selectedDistrict;
    } else {
        senateRaceBtn.disabled = true;
        districtNumDropdown.value = " ";
    }

    stateDropdown.addEventListener("change", (event) => {
        putStringInLocalStorage(lsConsts.LS_CURR_STATE_KEY, stateDropdown.value);
        numDistricts = getNumDistrictsForState(stateDropdown.value, statesMetadata);

        // let districtNumDropdown = document.getElementById("district-num");
        while (districtNumDropdown.firstChild) districtNumDropdown.removeChild(districtNumDropdown.firstChild);

        for (let i = 0; i < numDistricts + 1; i++) {
            let option = document.createElement("option");
            option.value = i;
            option.text = i;

            if (i === 0) {
                option.value = " ";
                option.text = " ";
            }

            districtNumDropdown.add(option, null);
        }

        // Clear local storage and the selected value to empty for district number
        removeValueFromLocalStorage(lsConsts.LS_CURR_DISTRICT_KEY);
        districtNumDropdown.value = " ";

        // Reload the page to bring in the chosen state's lottery winners
        document.getElementById(`lotteryWinnersViewCurrentRanking-link`).click();
    });

    districtNumDropdown.addEventListener("change", (event) => {
        const dropdownValue = districtNumDropdown.value;

        if (dropdownValue === " ") {
            // ASSERT: User wants to view the ranking of the senate race candidates.
            removeValueFromLocalStorage(lsConsts.LS_CURR_DISTRICT_KEY);

            // Reload the page to bring in the chosen state's lottery winners
            document.getElementById(`lotteryWinnersViewCurrentRanking-link`).click();
        } else {
            // ASSERT: User wants to view the ranking of the district race candidates.
            putStringInLocalStorage(lsConsts.LS_CURR_DISTRICT_KEY, dropdownValue);

            // Reload the page to bring in the chosen district's lottery winners
            document.getElementById(`lotteryWinnersViewCurrentRanking-link`).click();
        }
    });

    if (currState) {
        await initUiViewCurrentRanking(voteKey);
        if (!currRankVoteId) {
            const rankedListContainer = document.getElementById("view-current-ranking");
            rankedListContainer.innerHTML = `Nobody signed up in time for this lottery. Check back next year.`;
        }
    } else {
        const rankedListContainer = document.getElementById("view-current-ranking");
        rankedListContainer.innerHTML = `Choose a state for the senate lottery result. Also choose a district if you want to see a congressional district lottery result.`;
    }
}

/*
This function is for initializing the page for users to view the current ranking of a list of Issues.
 */
export async function initUiViewCurrentRanking(voteKey) {
    if (!isInDemoMode) {
        currRankVoteId = null;
        const rankVoteIdMapping = await callAPI_getRankVoteId(voteKey);

        if (rankVoteIdMapping && rankVoteIdMapping.voteId) {
            currRankVoteId = rankVoteIdMapping.voteId;

            const rankVoteItemList = await callAPI_getRankVoteItems();

            if (rankVoteItemList) {
                console.dir(rankVoteItemList);
                itemsInRankVote = rankVoteItemList.rankVoteItems;
                await populateViewRankVoteItems();
            }
        }

        const itemAbstractButtons = document.querySelectorAll(".ranked-item-button-abstract");
        itemAbstractButtons.forEach((item) => {
            item.addEventListener("click", showIssueAbstract);
        });

        const itemStatsButtons = document.querySelectorAll(".ranked-item-button-stats");
        itemStatsButtons.forEach((item) => {
            item.addEventListener("click", showIssueStats);
        });

        const itemTitles = document.querySelectorAll(".ranked-item-title");
        itemTitles.forEach((item) => {
            item.addEventListener("click", showIssueAbstract);
        });
    } else {
        const abstractButtons = document.querySelectorAll('.ranked-item-button-abstract');
        abstractButtons.forEach(elem => {
            elem.addEventListener('click', e => {
                showDemoNotice();
            });
        });

        const itemStatsButtons = document.querySelectorAll(".ranked-item-button-stats");
        itemStatsButtons.forEach((item) => {
            item.addEventListener("click", showDemoNotice);
        });

        const itemTitles = document.querySelectorAll(".ranked-item-title");
        itemTitles.forEach((item) => {
            item.addEventListener("click", showDemoNotice);
        });

    }
}

export async function callAPI_getRankVoteId(voteKey) {
    let theurl = URL_PRE + `/rest/vote/rank/id?voteKey=${voteKey}`;
    console.log(`Calling RankVoteApi.getRankVoteId(): GET ${theurl}`);

    let response = await fetch(theurl, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include'
    });

    if (!response.ok) {
        throw new Error('Bad response in callAPI_getRankVoteId()', {
            cause: {response}
        })
    }

    return await response.json();
}

async function callAPI_getRankVoteItems() {
    const theurl = URL_PRE + `/rest/vote/rank/items?tier=1&voteId=${currRankVoteId}`;
    console.log(`Calling RankVoteApi.getRankVoteItems(): GET ${theurl}`);

    const response = await fetch(theurl, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include'
    });

    if (!response.ok) {
        throw new Error('Bad response in callAPI_getRankVoteItems()', {
            cause: {response}
        })
    }

    return await response.json();
}

async function callAPI_getRankVoteResults() {
    const theurl = URL_PRE + `/rest/vote/rank/results?voteId=${currRankVoteId}`;
    console.log(`Calling RankVoteApi.getRankVoteResults(): GET ${theurl}`);

    const response = await fetch(theurl, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include'
    });

    if (!response.ok) {
        throw new Error('Bad response in callAPI_getRankVoteResults()', {
            cause: {response}
        })
    }

    return await response.json();
}

async function callAPI_getUserRanking() {
    const theurl = URL_PRE + `/rest/vote/rank?voteId=${currRankVoteId}&csrfToken=${userSession.csrfPreventionToken}`;
    console.log(`Calling RankVoteApi.getUserRanking(): GET ${theurl}`);

    const response = await fetch(theurl, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include'
    });

    if (!response.ok) {
        throw new Error('Bad response in callAPI_getUserRanking()', {
            cause: {response}
        })
    }

    return await response.json();
}

async function callAPI_updateUserRanking(json) {
    let retVal = false;

    const theurl = URL_PRE + `/rest/vote/rank?csrfToken=${userSession.csrfPreventionToken}`;
    console.log(`Calling API: PUT ${theurl} with json: ${JSON.stringify(json)}`);

    const response = await fetch(theurl,
        {
            method: 'PUT',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(json)
        }
    );

    if (!response.ok) {
        throw new Error('Bad response in callAPI_getRankVoteId()', {
            cause: {response}
        })
    }

    retVal = true;
    console.log(response);

    return retVal;
}