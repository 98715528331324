export const urlRoutes = {
    notFound: {
        template: "/templates/404.html",
        title: "",
        description: ""
    },
    "/": {
        template: "/templates/learningHome.html",
        title: "",
        description: ""
    },
    "/about": {
        template: "/templates/about.html",
        title: "",
        description: ""
    },
    "/acctAlreadyExists": {
        template: "/templates/acctAlreadyExists.html",
        title: "",
        destination: "modal-dialog",
        description: ""
    },
    "/adminTools": {
        template: "/templates/left-nav/adminToolsNav.html",
        destination: "left-nav",
        keepInLocalStorage: true,
        title: "",
        description: ""
    },
    "/adminToolsHome": {
        template: "/templates/adminToolsHome.html",
        title: "",
        notForDemo: true,
        description: ""
    },
    "/clearlyBetterProcess": {
        template: "/templates/clearlyBetterProcess.html",
        title: "",
        description: ""
    },
    "/contact": {
        template: "/templates/contact.html",
        title: "",
        description: ""
    },
    "/createNewUserProfile": {
        template: "/templates/createNewUserProfile.html",
        keepInLocalStorage: true,
        title: "",
        destination: "modal-dialog",
        description: ""
    },
    "/demoOnlyNotice": {
        template: "/templates/demoOnlyNotice.html",
        keepInLocalStorage: true,
        title: "",
        destination: "modal-dialog",
        description: ""
    },
    "/doing": {
        template: "/templates/left-nav/doingNav.html",
        destination: "left-nav",
        keepInLocalStorage: true,
        title: "",
        description: ""
    },
    "/doingHome": {
        template: "/templates/doingHome.html",
        title: "",
        description: ""
    },
    "/donate": {
        template: "/templates/donate.html",
        title: "",
        initUiFunc: "initUiDonatePage()",
        description: ""
    },
    "/emailSentConfirmation": {
        template: "/templates/emailSentConfirmation.html",
        title: "",
        destination: "modal-dialog",
        description: ""
    },
    "/errorMessage": {
        template: "/templates/errorMessage.html",
        title: "",
        keepInLocalStorage: true,
        destination: "modal-dialog",
        description: ""
    },
    "/howBillProposals": {
        template: "/templates/howBillProposals.html",
        title: "",
        description: ""
    },
    "/howDDP": {
        template: "/templates/howDDP.html",
        title: "",
        description: ""
    },
    "/howDDPMCResponsibilities": {
        template: "/templates/howDDPMCResponsibilities.html",
        title: "",
        description: ""
    },
    "/howLotteries": {
        template: "/templates/howLotteries.html",
        title: "",
        description: ""
    },
    "/howRanking": {
        template: "/templates/howRanking.html",
        title: "",
        description: ""
    },
    "/howRankingEmergency": {
        template: "/templates/howRankingEmergency.html",
        title: "",
        description: ""
    },
    "/howRankingRegular": {
        template: "/templates/howRankingRegular.html",
        title: "",
        description: ""
    },
    "/inactivityPopup": {
        template: "/templates/inactivityPopup.html",
        title: "",
        destination: "modal-dialog",
        description: ""
    },
    "/issueAbstract": {
        template: "/templates/issueAbstract.html",
        title: "",
        destination: "modal-dialog",
        description: ""
    },
    "/issueStats": {
        template: "/templates/issueStats.html",
        title: "",
        destination: "modal-dialog",
        description: ""
    },
    "/learning": {
        template: "/templates/left-nav/learningNav.html",
        destination: "left-nav",
        keepInLocalStorage: true,
        title: "",
        description: ""
    },
    "/learningHome": {
        template: "/templates/learningHome.html",
        keepInLocalStorage: true,
        title: "",
        description: ""
    },
    "/leftNavFooter": {
        template: "/templates/leftNavFooter.html",
        keepInLocalStorage: true,
        title: "",
        description: ""
    },
    "/lotteryCurrent": {
        template: "/templates/lotteryCurrent.html",
        title: "",
        description: ""
    },
    "/lotteryUpcoming": {
        template: "/templates/lotteryUpcoming.html",
        title: "",
        description: ""
    },
    "/lotteryWinnersEditMyRanking": {
        template: "/templates/lotteryWinnersEditMyRanking.html",
        initUiFunc: "initUiLotteryWinnersEditMyRanking()",
        title: "",
        demoIsDiffPage: true,
        description: ""
    },
    "/lotteryWinnersViewCurrentRanking": {
        template: "/templates/lotteryWinnersViewCurrentRanking.html",
        initUiFunc: "initUiLotteryWinnersViewCurrentRanking()",
        title: "",
        demoIsDiffPage: true,
        description: ""
    },
    "/nationalIssuesEditMyRanking": {
        template: "/templates/nationalIssuesEditMyRanking.html",
        initUiFunc: "initUiEditRankVote('nat-issues')",
        title: "",
        demoIsDiffPage: true,
        description: ""
    },
    "/nationalIssuesEmergencyEditMyRanking": {
        template: "/templates/nationalIssuesEmergencyEditMyRanking.html",
        initUiFunc: "initUiEditRankVote('nat-emergency-issues')",
        title: "",
        demoIsDiffPage: true,
        description: ""
    },
    "/nationalIssuesEmergencyViewCurrentRanking": {
        template: "/templates/nationalIssuesEmergencyViewCurrentRanking.html",
        initUiFunc: "initUiViewCurrentRanking('nat-emergency-issues')",
        title: "",
        demoIsDiffPage: true,
        description: ""
    },
    "/nationalIssuesViewCurrentRanking": {
        template: "/templates/nationalIssuesViewCurrentRanking.html",
        initUiFunc: "initUiViewCurrentRanking('nat-issues')",
        title: "",
        demoIsDiffPage: true,
        description: ""
    },
    "/nodeIssues": {
        template: "/templates/nodeIssues.html",
        title: "",
        description: ""
    },
    "/nodeRepresentatives": {
        template: "/templates/nodeRepresentatives.html",
        title: "",
        description: ""
    },
    "/nodeVoteDdp": {
        template: "/templates/nodeVoteDdp.html",
        title: "",
        description: ""
    },
    "/privacy": {
        template: "/templates/privacy.html",
        title: "",
        description: ""
    },
    "/voteDdpPolicyIssues": {
        template: "/templates/voteDdpPolicyIssues.html",
        title: "",
        description: ""
    },
    "/voteDdpProcessIssues": {
        template: "/templates/voteDdpProcessIssues.html",
        title: "",
        description: ""
    },
    "/voteDdpWebApp": {
        template: "/templates/voteDdpWebApp.html",
        title: "",
        description: ""
    },
    "/howVolunteerBallotLogistics": {
        template: "/templates/howVolunteerBallotLogistics.html",
        title: "",
        description: ""
    },
    "/howVolunteerOutreach": {
        template: "/templates/howVolunteerOutreach.html",
        title: "",
        description: ""
    },
    "/howVolunteerSection": {
        template: "/templates/howVolunteerSection.html",
        title: "",
        description: ""
    },
    "/howVolunteerSessionProcedures": {
        template: "/templates/howVolunteerSessionProcedures.html",
        title: "",
        description: ""
    },
    "/nodeVoteGovernment": {
        template: "/templates/nodeVoteGovernment.html",
        title: "",
        description: ""
    },
    "/nodeVoteNational": {
        template: "/templates/nodeVoteNational.html",
        title: "",
        description: ""
    },
    "/nodeVoteNationalEmergency": {
        template: "/templates/nodeVoteNationalEmergency.html",
        title: "",
        description: ""
    },
    "/nodeVoteNationalRegular": {
        template: "/templates/nodeVoteNationalRegular.html",
        title: "",
        description: ""
    },
    "/toolsRunLottery": {
        template: "/templates/toolsRunLottery.html",
        initUiFunc: "initUiToolsRunLottery()",
        title: "",
        notForDemo: true,
        description: ""
    },
    "/toolsTabulateRepVotes": {
        template: "/templates/toolsTabulateRepVotes.html",
        initUiFunc: "initUiToolsTabulateRepVotes()",
        title: "",
        notForDemo: true,
        description: ""
    },
    "/toolsTabulateIssueVotes": {
        template: "/templates/toolsTabulateIssueVotes.html",
        initUiFunc: "initUiToolsTabulateIssueVotes()",
        title: "",
        notForDemo: true,
        description: ""
    },
    "/twoFaNotComplete": {
        template: "/templates/twoFaNotComplete.html",
        destination: "modal-dialog",
        title: "",
        description: ""
    },
    "/unsavedChangesConfirmation": {
        template: "/templates/unsavedChangesConfirmation.html",
        keepInLocalStorage: true,
        title: "",
        destination: "modal-dialog",
        description: ""
    },
    "/user": {
        template: "/templates/left-nav/userNav.html",
        destination: "left-nav",
        keepInLocalStorage: true,
        title: "",
        description: ""
    },
    "/userEmails": {
        template: "/templates/userEmails.html",
        initUiFunc: "initUiUserEmails()",
        title: "",
        notForDemo: true,
        description: ""
    },
    "/userLogin": {
        template: "/templates/userLogin.html",
        keepInLocalStorage: true,
        title: "",
        destination: "modal-dialog",
        description: ""
    },
    "/userPassword": {
        template: "/templates/userPassword.html",
        initUiFunc: "initUiUserPassword()",
        title: "",
        notForDemo: true,
        description: ""
    },
    "/userPersonalInfo": {
        template: "/templates/userPersonalInfo.html",
        initUiFunc: "initUiUserPersonalInfo()",
        title: "",
        notForDemo: true,
        description: ""
    },
    "/userPhone": {
        template: "/templates/userPhone.html",
        initUiFunc: "initUiUserPhone()",
        title: "",
        notForDemo: true,
        description: ""
    },
    "/userSecurity2FaComplete": {
        template: "/templates/userSecurity2FaComplete.html",
        title: "",
        notForDemo: true,
        description: ""
    },
    "/userSecurityPrevCodeArrived": {
        template: "/templates/userSecurityPrevCodeArrived.html",
        title: "",
        notForDemo: true,
        description: ""
    },
    "/userSecuritySendCode": {
        template: "/templates/userSecuritySendCode.html",
        initUiFunc: "initUiUserSecurity()",
        title: "",
        notForDemo: true,
        description: ""
    },
    "/userSecurityVerifyCode": {
        template: "/templates/userSecurityVerifyCode.html",
        title: "",
        notForDemo: true,
        description: ""
    },
    "/welcomeEmailSentConfirmation": {
        template: "/templates/welcomeEmailSentConfirmation.html",
        title: "",
        destination: "modal-dialog",
        description: ""
    },
    "/whoRulesAmerica": {
        template: "/templates/whoRulesAmerica.html",
        title: "",
        description: ""
    },
    "/whoRulesElites": {
        template: "/templates/whoRulesElites.html",
        title: "",
        description: ""
    },
    "/whoRulesMIC": {
        template: "/templates/whoRulesMIC.html",
        title: "",
        description: ""
    },
    "/whoRulesMSM": {
        template: "/templates/whoRulesMSM.html",
        title: "",
        description: ""
    },
    "/whoRulesWallStreet": {
        template: "/templates/whoRulesWallStreet.html",
        title: "",
        description: ""
    },
    "/whoRulesPoliticalClass": {
        template: "/templates/whoRulesPoliticalClass.html",
        title: "",
        description: ""
    },
    "/whyDangerToContinue": {
        template: "/templates/whyDangerToContinue.html",
        title: "",
        description: ""
    },
    "/whyMoneyInPolitics": {
        template: "/templates/whyMoneyInPolitics.html",
        title: "",
        description: ""
    },
    "/whyThisHappened": {
        template: "/templates/whyThisHappened.html",
        title: "",
        description: ""
    },
    "/whyWeNeedDdp": {
        template: "/templates/whyWeNeedDdp.html",
        title: "",
        description: ""
    }
}
