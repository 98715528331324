import {smallScreen} from "./mediaQueries.js";
import {getHtmlTemplateFromLocalStorage} from "./ddpLocalStorage.js";
import {urlRoutes} from "./urlRoutes.js";

export function getModalDialog() {
    return document.querySelector(".modal");
}

export function openModalDialog() {
    const modalDialog = getModalDialog();
    const overlay = getOverlay();
    const leftNavBtn = document.querySelector('.nav-btn');

    modalDialog.classList.remove("hidden");
    overlay.classList.remove("hidden");
    leftNavBtn.style.display = 'none';
}

export function closeModalDialog() {
    const modalDialog = getModalDialog();
    const overlay = getOverlay();
    const leftNavBtn = document.querySelector('.nav-btn');

    modalDialog.classList.add("hidden");
    overlay.classList.add("hidden");
    if (smallScreen) {
        leftNavBtn.style.display = 'inline-block';
    }
}

function getOverlay() {
    return document.querySelector(".overlay");
}

export function showLoader() {
    const loader = document.querySelector(".loader");
    const overlay = getOverlay();
    const leftNavBtn = document.querySelector('.nav-btn');

    loader.classList.remove("loader--hidden");
    overlay.classList.remove("hidden");
    leftNavBtn.style.display = 'none';
}

export function hideLoader() {
    const loader = document.querySelector(".loader");
    const overlay = getOverlay();
    const leftNavBtn = document.querySelector('.nav-btn');

    loader.classList.add("loader--hidden");
    overlay.classList.add("hidden");
    if (smallScreen) {
        leftNavBtn.style.display = 'inline-block';
    }
}

export function showLoaderMainContentArea() {
    const loader = document.querySelector('.loader-main');
    loader.classList.remove("loader--hidden");

    const scrollableDiv = document.querySelector('.main-content-area-container');
    scrollableDiv.scrollTop = 0;
}

export function hideLoaderMainContentArea() {
    const loader = document.querySelector('.loader-main');
    loader.classList.add("loader--hidden");
}

export function openErrorMessageModal(templateRoute, title, message) {
    const modalDialog = getModalDialog();
    modalDialog.innerHTML = getHtmlTemplateFromLocalStorage(urlRoutes[templateRoute].template);

    document.getElementById('titleArea').innerHTML = title;
    document.getElementById('messageArea').innerHTML = message;

    openModalDialog();

    const okBtn = document.getElementById("ok-btn");
    okBtn.addEventListener('click', event => {
        closeModalDialog();
    });
}

