import {urlRoutes} from "./urlRoutes.js";
import {
    setCurrentDirtyEditorName,
    retrieveHtmlTemplateForRouteKey,
    LEARNING
} from "./routingAndNav.js";
import {
    getNumDistrictsForState,
    getStateMetadataFromLocalStorage,
    putUserSessionInLocalStorage,
    URL_PRE, WS_URL_PRE
} from "./ddpLocalStorage.js";
import {
    userSession,
    setupInactivityTimeouts,
    decorateUserIconWithEmail
} from "./userActivities.js";
import {
    validateForm,
    validateSingleFormGroup
} from "./formValidation.js";
import {
    closeModalDialog,
    getModalDialog,
    hideLoaderMainContentArea,
    openModalDialog,
    showLoaderMainContentArea
} from "./modalDialog.js";
import {messages} from "./messages.js";

let badCurrPwdAuthAttempts = 0;
let phoneInput = null;
let phone2Input = null;

// Mirror object of org.tpp.model.Acct on the server side
export let userProfileUnderEdit = {
    id: 0,
    first_name: "",
    last_name: "",
    middle_name: "",
    addr_street1: "",
    addr_street2: "",
    addr_city: "",
    addr_zip: "",
    addr_state: "",
    addr_cntryreg: "",
    phone: "",
    phone2: "",
    email: "",
    email2: "",
    congress_district: 0,
    gender: "",
    ethnicity: "",
    pronouns: "",
    occupation: "",
    annual_income: 0,
    oauthid: "",
    register_date: "",
    is_admin: "",
    is_addr_verified: "",
    is_lottery_rep: "",
    is_lottery_sen: "",
    currPwd: "",
    newPwd1: "",
    newPwd2: ""
}

export async function initUiUserPersonalInfo() {
    if (!(userSession && userSession.isPwdAuthenticated)) {
        const profFormElem = document.getElementById("profForm");
        profFormElem.innerHTML = messages.MUST_LOGIN;
        return;
    }

    await popUserPersonalInfoFields();
    displayLotteryCheckboxes(true);
    displayVerifiedFields(false);
    alterUserProfBasedOnCheckboxes();
    const verifyCheckBox = document.getElementById("user-verify");
    const repCheckBox = document.getElementById("in-lottery-rep");
    const senateCheckBox = document.getElementById("in-lottery-senate");
    verifyCheckBox.addEventListener("click", alterUserProfBasedOnCheckboxes);
    repCheckBox.addEventListener("click", alterUserProfBasedOnCheckboxes);
    senateCheckBox.addEventListener("click", alterUserProfBasedOnCheckboxes);
    const userProfileForm = document.forms["profForm"];
    userProfileForm.setAttribute('novalidate', '');
    userProfileForm.addEventListener("submit", updateUserPersonalInfo);
    document.getElementById('userProfEditCancel').addEventListener('click', handleCancelUserProfEdit);
    Array.from(userProfileForm.elements).forEach((element) => {
        element.addEventListener('blur', event => {
            if (event.srcElement.parentElement.classList.contains('form-group')) {
                validateSingleFormGroup(event.srcElement.parentElement);
            }
        });
    });

    disableSaveAndCancelButtons();
}

// Verified fields are the fields we need only if the use wants to vote
// or be included in lotteries.
const displayVerifiedFields = function (show) {
    const firstNameField = document.getElementById("first-name");
    const lastNameField = document.getElementById("last-name");
    const streetAddrField = document.getElementById("street-addr");
    const streetAddr2Field = document.getElementById("street-addr2");
    const cityField = document.getElementById("addr-city");
    const zipField = document.getElementById("addr-zip");

    if (show) {
        firstNameField.parentElement.classList.remove("hidden");
        lastNameField.parentElement.classList.remove("hidden");
        streetAddrField.parentElement.classList.remove("hidden");
        streetAddr2Field.parentElement.classList.remove("hidden");
        cityField.parentElement.classList.remove("hidden");
        zipField.parentElement.classList.remove("hidden");
    } else {
        firstNameField.parentElement.classList.add("hidden");
        lastNameField.parentElement.classList.add("hidden");
        streetAddrField.parentElement.classList.add("hidden");
        streetAddr2Field.parentElement.classList.add("hidden");
        cityField.parentElement.classList.add("hidden");
        zipField.parentElement.classList.add("hidden");
    }
}

function alterUserProfBasedOnCheckboxes() {
    enableUserProfEditButtons();

    const verifyCheckBox = document.getElementById("user-verify");
    const repCheckBox = document.getElementById("in-lottery-rep");
    const senateCheckBox = document.getElementById("in-lottery-senate");
    let isVerifyChecked = verifyCheckBox.checked;
    let isRepChecked = repCheckBox.checked;
    let isSenateChecked = senateCheckBox.checked;

    console.log(`repCheckBox: ${isRepChecked} senateCheckBox: ${isSenateChecked}`);

    if (isVerifyChecked || isRepChecked || isSenateChecked) {
        // ASSERT: At least one checkbox is checked, so show all extra input fields.

        document.getElementById("lbl-prof-first-name").parentElement.classList.remove("hidden");
        document.getElementById("lbl-prof-last-name").parentElement.classList.remove("hidden");
        document.getElementById("lbl-prof-addr-street1").parentElement.classList.remove("hidden");
        document.getElementById("lbl-prof-addr-street2").parentElement.classList.remove("hidden");
        document.getElementById("lbl-prof-addr-city").parentElement.classList.remove("hidden");
        document.getElementById("lbl-prof-addr-zip").parentElement.classList.remove("hidden");
    } else {
        // ASSERT: Neither checkbox is checked, so hide all extra input fields.

        document.getElementById("lbl-prof-first-name").parentElement.classList.add("hidden");
        document.getElementById("lbl-prof-last-name").parentElement.classList.add("hidden");
        document.getElementById("lbl-prof-addr-street1").parentElement.classList.add("hidden");
        document.getElementById("lbl-prof-addr-street2").parentElement.classList.add("hidden");
        document.getElementById("lbl-prof-addr-city").parentElement.classList.add("hidden");
        document.getElementById("lbl-prof-addr-zip").parentElement.classList.add("hidden");
    }
}

export async function initUiUserEmails() {
    if (!is2faComplete()) {
        return;
    }

    if (!(userSession && userSession.isPwdAuthenticated)) {
        const dummyLink = document.getElementById('dummy-link');
        dummyLink.setAttribute('href', WS_URL_PRE + `/userPassword`);
        dummyLink.click();
        return;
    }

    await popUserEmails();
    const userProfileForm = document.forms["profEmailsForm"];
    userProfileForm.setAttribute('novalidate', '');
    userProfileForm.addEventListener("submit", updateUserEmails);
    document.getElementById('userProfEditCancel').addEventListener('click', handleCancelUserProfEdit);
    Array.from(userProfileForm.elements).forEach((element) => {
        element.addEventListener('blur', event => {
            if (event.srcElement.parentElement.classList.contains('form-group')) {
                validateSingleFormGroup(event.srcElement.parentElement);
            }
        });
    });

    disableSaveAndCancelButtons();
}

export async function initUiUserPassword() {
    if (!is2faComplete()) {
        return;
    }

    await popUserPassword();
    const userProfileForm = document.forms["profPasswordForm"];
    userProfileForm.addEventListener("submit", updateUserPassword);
    userProfileForm.setAttribute('novalidate', '');
    document.getElementById('userProfEditCancel').addEventListener('click', handleCancelUserProfEdit);
    Array.from(userProfileForm.elements).forEach((element) => {
        element.addEventListener('blur', event => {
            if (event.srcElement.parentElement.classList.contains('form-group')) {
                validateSingleFormGroup(event.srcElement.parentElement);
            }
        });
    });

    disableSaveAndCancelButtons();
}

export async function initUiUserPhone() {
    if (!is2faComplete()) {
        return;
    }

    if (!(userSession && userSession.isPwdAuthenticated)) {
        const dummyLink = document.getElementById('dummy-link');
        dummyLink.setAttribute('href', WS_URL_PRE + `/userPassword`);
        dummyLink.click();
        return;
    }

    await popUserPhone();
    const userProfileForm = document.forms["profPhoneForm"];
    userProfileForm.setAttribute('novalidate', '');
    userProfileForm.addEventListener("submit", updateUserPhone);
    document.getElementById('userProfEditCancel').addEventListener('click', handleCancelUserProfEdit);
    Array.from(userProfileForm.elements).forEach((element) => {
        element.addEventListener('blur', event => {
            if (event.srcElement.parentElement.classList.contains('form-group')) {
                validateSingleFormGroup(event.srcElement.parentElement);
            }
        });
    });

    disableSaveAndCancelButtons();
}

export async function readInUserProfileUnderEdit() {
    userProfileUnderEdit = await callAPI_getAcctDetails(userSession.id);
}

async function popUserPassword(emailAddr) {
    if (!userProfileUnderEdit || userProfileUnderEdit.id === 0) {
        // ASSERT: This is the first time during this user's session that they have opened any of the pages of
        // the userProf editor. Retrieve the userProf from the API.
        userProfileUnderEdit = await callAPI_getAcctDetails(userSession.id);
    }

    if (!userProfileUnderEdit) {
        return;
    }

    console.log(`userSession for new user needing to set a password...`);
    console.dir(userSession);

    const userNameField = document.getElementById('password-userName');
    userNameField.value = userProfileUnderEdit.email;

    if (userSession.isPwdAuthenticated) {
        console.log(`User is password authenticated, so putting focus on existing password field.`);
        const currPwdField = document.getElementById("currPwd");
        currPwdField.addEventListener('input', changePwdInfoChanged);
        currPwdField.focus();
    } else {
        console.log(`User is not password authenticated, so hidding existing password field.`);
        const currPwdAuthDiv = document.getElementById('currPwdAuthDiv');
        currPwdAuthDiv.classList.add('hidden');
    }

    const newPwd1Field = document.getElementById("newPwd1");
    newPwd1Field.addEventListener('input', changePwdInfoChanged);

    const newPwd2Field = document.getElementById("newPwd2");
    newPwd2Field.addEventListener('input', changePwdInfoChanged);
}

async function popUserPhone() {
    if (!userProfileUnderEdit || userProfileUnderEdit.id === 0) {
        // ASSERT: This is the first time during this user's session that they have opened any of the pages of
        // the userProf editor. Retrieve the userProf from the API.
        userProfileUnderEdit = await callAPI_getAcctDetails(userSession.id);
    }

    if (!userProfileUnderEdit) {
        return;
    }

    const userNameField = document.getElementById('phone-userName');
    userNameField.value = userProfileUnderEdit.email;

    const currPwdField = document.getElementById("currPwd");
    currPwdField.focus();
    currPwdField.addEventListener('input', infoChanged);

    const phoneField = document.getElementById("phone");
    if (userProfileUnderEdit.phone) {
        phoneField.value = userProfileUnderEdit.phone;
    }

    phoneInput = window.intlTelInput(phoneField, {
        utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });

    phoneField.addEventListener('input', infoChanged);

    const phone2Field = document.getElementById("phone2");
    if (userProfileUnderEdit.phone2) {
        phone2Field.value = userProfileUnderEdit.phone2;
    }

    phone2Input = window.intlTelInput(phone2Field, {
        utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });

    phone2Field.addEventListener('input', infoChanged);

    const validPhoneStatus = document.getElementById("valid-phone-status");
    validPhoneStatus.classList.add('hidden');
}

function disableCurrPwdControls() {
    const currPwdField = document.getElementById("currPwd");
    currPwdField.disabled = true;

    const authButton = document.getElementById("authCurrPwd");
    authButton.disabled = true;
}

function enablePwdChangeControls() {
    const newPwd1Field = document.getElementById("newPwd1");
    newPwd1Field.disabled = false;

    const newPwd2Field = document.getElementById("newPwd2");
    newPwd2Field.disabled = false;
}

function enableSendPhoneControls() {
    const sendCodeToPhoneBtn = document.getElementById("chng-pwd-send-phone-code");
    sendCodeToPhoneBtn.disabled = false;

    const phoneDropdown = document.getElementById("chng-pwd-phone");
    phoneDropdown.disabled = false;

    const channelDropdown = document.getElementById("chng-pwd-phone-channel");
    channelDropdown.disabled = false;
}

function enableSendEmailControls() {
    const sendCodeToEmailBtn = document.getElementById("chng-pwd-send-email-code");
    sendCodeToEmailBtn.disabled = false;

    const emailDropdown = document.getElementById("chng-pwd-email");
    emailDropdown.disabled = false;
}

function disableSendPhoneControls() {
    const sendCodeToPhoneBtn = document.getElementById("chng-pwd-send-phone-code");
    sendCodeToPhoneBtn.disabled = true;

    const phoneDropdown = document.getElementById("chng-pwd-phone");
    phoneDropdown.disabled = true;

    const channelDropdown = document.getElementById("chng-pwd-phone-channel");
    channelDropdown.disabled = true;

}

function disableSendEmailControls() {
    const sendCodeToEmailBtn = document.getElementById("chng-pwd-send-email-code");
    sendCodeToEmailBtn.disabled = true;

    const emailDropdown = document.getElementById("chng-pwd-email");
    emailDropdown.disabled = true;

}

function disableVerifyPhoneControls() {
    const codeReceivedField = document.getElementById("chng-pwd-phone-code");
    codeReceivedField.disabled = true;

    const verifyPhoneCodeBtn = document.getElementById("chng-pwd-verify-phone-code");
    verifyPhoneCodeBtn.disabled = true;
}

function disableVerifyEmailControls() {
    const codeReceivedField = document.getElementById("chng-pwd-email-code");
    codeReceivedField.disabled = true;

    const verifyEmailCodeBtn = document.getElementById("chng-pwd-verify-email-code");
    verifyEmailCodeBtn.disabled = true;
}

function enableVerifyPhoneControls() {
    const codeReceivedField = document.getElementById("chng-pwd-phone-code");
    codeReceivedField.disabled = false;
    codeReceivedField.focus();
    const verifyPhoneCodeBtn = document.getElementById("chng-pwd-verify-phone-code");
    verifyPhoneCodeBtn.disabled = false;

    codeReceivedField.addEventListener("keypress", function(event) {
        // If the user presses the "Enter" key on the keyboard
        if (event.key === "Enter") {
            event.preventDefault();
            verifyPhoneCodeBtn.click();
        }
    });
}

function enableVerifyEmailControls() {
    const codeReceivedField = document.getElementById("chng-pwd-email-code");
    codeReceivedField.disabled = false;
    codeReceivedField.focus();
    const verifyEmailCodeBtn = document.getElementById("chng-pwd-verify-email-code");
    verifyEmailCodeBtn.disabled = false;

    codeReceivedField.addEventListener("keypress", function(event) {
        // If the user presses the "Enter" key on the keyboard
        if (event.key === "Enter") {
            event.preventDefault();
            verifyEmailCodeBtn.click();
        }
    });
}

async function adjustUiAfterCodeToPhone() {
    const isCodeSent = await sendSmsOtp();
    // const isCodeSent = true;

    if (isCodeSent) {
        disableSendPhoneControls();

        const sendCodeToPhoneStatus = document.getElementById("send-code-status");
        sendCodeToPhoneStatus.textContent = `Verification code is on its way...`;

        enableVerifyPhoneControls();
    }
}

async function callAPI_authenticatePassword(currPwd) {
    let retVal = false;

    const theurl = URL_PRE + `/rest/account/password/${currPwd}?csrfToken=${userSession.csrfPreventionToken}`;
    console.log(`Calling API to authenticate the current password entered by the user...`);

    const response = await fetch(theurl, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include'
    });

    if (response.ok) {
        retVal = true;
        console.log(`Current password authenticated for acctId: ${userSession.id}`);
    } else {
        console.log(`Current authentication failed for acctId: ${userSession.id}`);
    }

    return retVal;
}

async function popUserEmails() {
    if (!userProfileUnderEdit || userProfileUnderEdit.id === 0) {
        // ASSERT: This is the first time during this user's session that they have opened any of the pages of
        // the userProf editor. Retrieve the userProf from the API.
        userProfileUnderEdit = await callAPI_getAcctDetails(userSession.id);
    }

    if (!userProfileUnderEdit) {
        return;
    }

    const userNameField = document.getElementById('email-userName');
    userNameField.value = userProfileUnderEdit.email;

    const currPwdField = document.getElementById("currPwd");
    currPwdField.addEventListener('input', infoChanged);
    currPwdField.focus();

    const emailField = document.getElementById("email1");
    emailField.addEventListener('input', infoChanged);
    if (userProfileUnderEdit.email) {
        emailField.value = userProfileUnderEdit.email;
    }

    const email2Field = document.getElementById("email2");
    email2Field.addEventListener('input', infoChanged);
    if (userProfileUnderEdit.email2) {
        email2Field.value = userProfileUnderEdit.email2;
    }
}

export function clearUserProfUnderEditForLogout() {
    userProfileUnderEdit = null;
}

async function popUserPersonalInfoFields() {
    if (!userProfileUnderEdit || userProfileUnderEdit.id === 0) {
        // ASSERT: This is the first time during this user's session that they have opened any of the pages of
        // the userProf editor. Retrieve the userProf from the API.
        userProfileUnderEdit = await callAPI_getAcctDetails(userSession.id);
    }

    if (!userProfileUnderEdit) {
        return;
    }

    const emailField = document.getElementById('personal-email');
    emailField.value = userProfileUnderEdit.email;

    const statesMetadata = getStateMetadataFromLocalStorage();

    const stateDropdown = document.getElementById("state-dropdown-user-prof");
    const districtNumDropdown = document.getElementById("district-num-user-prof");
    districtNumDropdown.addEventListener('input', infoChanged);

    stateDropdown.value = userProfileUnderEdit.addr_state;

    const numDistricts = getNumDistrictsForState(userProfileUnderEdit.addr_state, statesMetadata);

    for (let i = 1; i < numDistricts + 1; i++) {
        let option = document.createElement("option");
        option.value = i;
        option.text = i;

        districtNumDropdown.add(option, null);
    }

    districtNumDropdown.value = userProfileUnderEdit.congress_district;

    const userVerifyCheckbox = document.getElementById('user-verify');
    if (userProfileUnderEdit.is_addr_verified) {
        userVerifyCheckbox.checked = true;
    } else {
        userVerifyCheckbox.checked = false;
    }

    const isLotteryRepCheckbox = document.getElementById('in-lottery-rep');
    if (userProfileUnderEdit.is_lottery_rep == 1) {
        isLotteryRepCheckbox.checked = true;
    } else {
        isLotteryRepCheckbox.checked = false;
    }

    const isLotterySenCheckbox = document.getElementById('in-lottery-senate');
    if (userProfileUnderEdit.is_lottery_sen == 1) {
        isLotterySenCheckbox.checked = true;
    } else {
        isLotterySenCheckbox.checked = false;
    }

    const firstNameField = document.getElementById('first-name');
    firstNameField.addEventListener('input', infoChanged);
    if (userProfileUnderEdit.first_name) {
        firstNameField.value = userProfileUnderEdit.first_name;
    }

    const lastNameField = document.getElementById('last-name');
    lastNameField.addEventListener('input', infoChanged);
    if (userProfileUnderEdit.last_name) {
        lastNameField.value = userProfileUnderEdit.last_name;
    }

    const streetAddrField = document.getElementById('street-addr');
    streetAddrField.addEventListener('input', infoChanged);
    if (userProfileUnderEdit.addr_street1) {
        streetAddrField.value = userProfileUnderEdit.addr_street1;
    }

    const streetAddr2Field = document.getElementById('street-addr2');
    streetAddr2Field.addEventListener('input', infoChanged);
    if (userProfileUnderEdit.addr_street2) {
        streetAddr2Field.value = userProfileUnderEdit.addr_street2;
    }

    const addrCityField = document.getElementById('addr-city');
    addrCityField.addEventListener('input', infoChanged);
    if (userProfileUnderEdit.addr_city) {
        addrCityField.value = userProfileUnderEdit.addr_city;
    }

    const addrZipField = document.getElementById('addr-zip');
    addrZipField.addEventListener('input', infoChanged);
    if (userProfileUnderEdit.addr_zip) {
        addrZipField.value = userProfileUnderEdit.addr_zip;
    }

    stateDropdown.addEventListener("change", handleUserProfStateChange);
}

async function callAPI_getAcctDetails(ddpAcctId) {
    let ddpAcctProf = null;
    const theurl = URL_PRE + `/rest/account/${ddpAcctId}?csrfToken=${userSession.csrfPreventionToken}`;
    console.log(`Calling API: GET ${theurl}`);

    const response = await fetch(theurl, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include'
    });

    if (!response.ok) {
        throw new Error('Bad response in callAPI_getRankVoteId()', {
            cause: {response}
        })
    }

    ddpAcctProf = await response.json();

    if (ddpAcctProf) {
        console.log(`userProf retrieved from API for acctId: ${ddpAcctProf.id}`);
    } else {
        console.log(`ERROR: Expected acctId ${ddpAcctId} not found in the system`);
    }

    return ddpAcctProf;
}

function handleCancelUserProfEdit() {
    let currPath = window.location.pathname;
    currPath = currPath.substring(1, currPath.length);
    setCurrentDirtyEditorName(null);
    document.getElementById(`${currPath}-link`).click();
}

async function updateUserEmails(e) {
    // This method is being driven by a form submit action. We must prevent the default
    // browser action, which would be to submit the form and refresh the page.
    e.preventDefault();

    if (!validateForm('#profEmailsForm')) {
        return;
    }

    // Populate the userProf that we will send to the API first from the values in
    // userProfUnderEdit, which represents a local cache of the userProf in the db.
    const userProf = {};
    cloneTheSourceUserProf(userProf, userProfileUnderEdit);

    // Replace userProf values with UI field values the current editor page.
    overlayNewEmailValues(userProf);

    // Authenticate password before sending new userProf to backend.
    const currPwdField = document.getElementById("currPwd");
    const currPwd = currPwdField.value;

    showLoaderMainContentArea();
    try {
        const isAuthGood = await callAPI_authenticatePassword(currPwd);

        if (isAuthGood) {
            // Send userProf to the API, and update the cache userProfUnderEdit if the update
            // API succeeds in updating the db. This is how we ensure that userProfUnderEdit
            // perfectly mirrors the current userProf in the db.
            await updateUserProfileOnDbAndCache(userProf);

            // Use this method to get back to intialized state.
            handleCancelUserProfEdit();
        } else {
            alert(`Password Bad :-(`);
        }
    } catch (err) {
        console.dir(err);
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        setCurrentDirtyEditorName(null);
        goToLearningHome();
    }
    hideLoaderMainContentArea();
}

async function updateUserPhone(e) {
    // This method is being driven by a form submit action. We must prevent the default
    // browser action, which would be to submit the form and refresh the page.
    e.preventDefault();

    if (!validateForm('#profPhoneForm')) {
        return;
    }

    // Populate the userProf that we will send to the API first from the values in
    // userProfUnderEdit, which represents a local cache of the userProf in the db.
    const userProf = {};
    cloneTheSourceUserProf(userProf, userProfileUnderEdit);

    showLoaderMainContentArea();
    try {
        // Replace userProf values with UI field values the current editor page.
        const phoneNumsValid = await overlayNewPhoneValues(userProf);

        if (phoneNumsValid) {
            // Authenticate password before sending new userProf to backend.
            const currPwdField = document.getElementById("currPwd");
            const currPwd = currPwdField.value;
            const isAuthGood = await callAPI_authenticatePassword(currPwd);

            if (isAuthGood) {
                // Send userProf to the API, and update the cache userProfUnderEdit if the update
                // API succeeds in updating the db. This is how we ensure that userProfUnderEdit
                // perfectly mirrors the current userProf in the db.
                await updateUserProfileOnDbAndCache(userProf);

                // Use this method to get back to intialized state.
                handleCancelUserProfEdit();
            } else {
                alert(`Password Bad :-(`);
            }
        }
    } catch (err) {
        console.dir(err);
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        setCurrentDirtyEditorName(null);
        goToLearningHome();
    }
    hideLoaderMainContentArea();
}

async function updateUserPersonalInfo(e) {
    // This method is being driven by a form submit action. We must prevent the default
    // browser action, which would be to submit the form and refresh the page.
    e.preventDefault();

    if (!validateForm('#profForm')) {
        return;
    }

    // Populate the userProf that we will send to the API first from the values in
    // userProfUnderEdit, which represents a local cache of the userProf in the db.
    const userProf = {};
    cloneTheSourceUserProf(userProf, userProfileUnderEdit);

    // Replace userProf values with UI field values in the current editor page.
    overlayNewPersonalInfoValues(userProf);

    showLoaderMainContentArea();
    try {
        // Send userProf to the API, and update the cache userProfUnderEdit if the update
        // API succeeds in updating the db. This is how we ensure that userProfUnderEdit
        // perfectly mirrors the current userProf in the db.
        await updateUserProfileOnDbAndCache(userProf);
    } catch (err) {
        console.dir(err);
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        setCurrentDirtyEditorName(null);
        goToLearningHome();
    }
    hideLoaderMainContentArea();
}

async function updateUserPassword(e) {
    // This method is being driven by a form submit action. We must prevent the default
    // browser action, which would be to submit the form and refresh the page.
    e.preventDefault();

    if (!validateForm('#profPasswordForm')) {
        return;
    }

    // Populate the userProf that we will send to the API first from the values in
    // userProfUnderEdit, which represents a local cache of the userProf in the db.
    const userProf = {};
    cloneTheSourceUserProf(userProf, userProfileUnderEdit);

    // Replace userProf values with UI field values the current editor page.
    overlayNewPasswordValues(userProf);

    showLoaderMainContentArea();
    try {
        if (userSession.isPwdAuthenticated) {
            // Authenticate password before sending new userProf to backend.
            const currPwdField = document.getElementById("currPwd");
            const currPwd = currPwdField.value;
            const isAuthGood = await callAPI_authenticatePassword(currPwd);

            if (isAuthGood) {
                // Send userProf to the API, and update the cache userProfUnderEdit if the update
                // API succeeds in updating the db. This is how we ensure that userProfUnderEdit
                // perfectly mirrors the current userProf in the db.
                await changePasswordOnDb(userProf);

                // Use this method to get back to intialized state.
                handleCancelUserProfEdit();
            } else {
                alert(`Password Bad :-(`);
            }
        } else {
            await changePasswordOnDb(userProf);

            // ASSERT: The user has completed 2FA, and they've just changed their password to
            // one they now know. Set userSession.isPwdAuthenticated to true, so that if they
            // decide to come back to this editor to change their password again they'll have
            // to provide the current one first.
            setupInactivityTimeouts();
            userSession.isPwdAuthenticated = true;
            putUserSessionInLocalStorage(userSession);
            decorateUserIconWithEmail();

            // Use this method to get back to intialized state.
            handleCancelUserProfEdit();
        }
    } catch (err) {
        console.dir(err);
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        setCurrentDirtyEditorName(null);
        goToLearningHome();
    }
    hideLoaderMainContentArea();
}

function enableUserProfEditButtons() {
    const saveBtn = document.getElementById('userProfEditSave');
    const cancelBtn = document.getElementById('userProfEditCancel');

    saveBtn.disabled = false;
    cancelBtn.disabled = false;
}

function disableSaveAndCancelButtons() {
    const saveBtn = document.getElementById('userProfEditSave');
    const cancelBtn = document.getElementById('userProfEditCancel');

    saveBtn.disabled = true;
    cancelBtn.disabled = true;
}

function enableUserPasswordCancelButton() {
    const cancelBtn = document.getElementById('userProfEditCancel');
    cancelBtn.disabled = false;
}

function enableUserPasswordSaveButton() {
    const saveBtn = document.getElementById('userProfEditSave');
    saveBtn.disabled = false;
}

function disableUserPasswordSaveButton() {
    const saveBtn = document.getElementById('userProfEditSave');
    saveBtn.disabled = true;
}

function infoChanged() {
    enableUserProfEditButtons();
    setCurrentDirtyEditorName('userProfEdit');
}

function changePwdInfoChanged() {
    setCurrentDirtyEditorName('userProfEdit');
    enableUserPasswordSaveButton();
    enableUserPasswordCancelButton();
}

function handleUserProfStateChange(event) {
    enableUserProfEditButtons();

    const statesMetadata = getStateMetadataFromLocalStorage();
    const stateDropdown = document.getElementById("state-dropdown-user-prof");
    const numDistricts = getNumDistrictsForState(stateDropdown.value, statesMetadata);

    const districtNumDropdown = document.getElementById("district-num-user-prof");
    while (districtNumDropdown.firstChild) districtNumDropdown.removeChild(districtNumDropdown.firstChild);

    for (let i = 0; i < numDistricts + 1; i++) {
        let option = document.createElement("option");
        option.value = i;
        option.text = i;

        if (i == 0) {
            option.value = " ";
            option.text = " ";
        }

        districtNumDropdown.add(option, null);
    }
}

const displayLotteryCheckboxes = function (show) {
    if (show) {
        document.getElementById("userProf-lottery-rep-lbl").parentElement.classList.remove("hidden");
    } else {
        document.getElementById("userProf-lottery-rep-lbl").parentElement.classList.add("hidden");
    }
}

function overlayNewEmailValues(userProf) {
    const emailField = document.getElementById("email1");
    userProf.email = emailField.value;

    const email2Field = document.getElementById("email2");
    userProf.email2 = email2Field.value;
}

async function overlayNewPhoneValues(userProf) {
    let retVal = true;
    const currPwdField = document.getElementById("currPwd");
    userProf.currPwd = currPwdField.value;

    let badPhoneErrMsg = null;
    const phoneCorrectFormat = phoneInput.getNumber();
    console.log(`phoneCorrectFormat: ${phoneCorrectFormat}`);
    if (phoneCorrectFormat) {
        badPhoneErrMsg = await verifyPhoneNumFormat(phoneCorrectFormat);
        userProf.phone = phoneCorrectFormat;

        if (badPhoneErrMsg) {
            const phoneLabel = document.getElementById("labelPhone");
            phoneLabel.classList.add('hidden');

            const validPhoneStatus = document.getElementById("valid-phone-status");
            validPhoneStatus.classList.remove('hidden');
            const validPhoneStatusSpan = document.getElementById("valid-phone-status-span");
            validPhoneStatusSpan.innerHTML = badPhoneErrMsg;
        } else {
            const validPhoneStatus = document.getElementById("valid-phone-status");
            validPhoneStatus.classList.add('hidden');

            const phoneLabel = document.getElementById("labelPhone");
            phoneLabel.classList.remove('hidden');
        }
    } else {
        userProf.phone = "";
    }

    let badPhone2ErrMsg = null;
    const phone2CorrectFormat = phone2Input.getNumber();
    console.log(`phone2CorrectFormat: ${phone2CorrectFormat}`);
    if (phone2CorrectFormat) {
        badPhone2ErrMsg = await verifyPhoneNumFormat(phone2CorrectFormat);
        userProf.phone2 = phone2CorrectFormat;

        if (badPhone2ErrMsg) {
            const phone2Label = document.getElementById("labelPhone2");
            phone2Label.classList.add('hidden');

            const validPhone2Status = document.getElementById("valid-phone2-status");
            validPhone2Status.classList.remove('hidden');
            const validPhoneStatusSpan = document.getElementById("valid-phone2-status-span");
            validPhoneStatusSpan.innerHTML = badPhone2ErrMsg;
        } else {
            const validPhone2Status = document.getElementById("valid-phone2-status");
            validPhone2Status.classList.add('hidden');

            const phone2Label = document.getElementById("labelPhone2");
            phone2Label.classList.remove('hidden');
        }
    } else {
        userProf.phone2 = "";
    }

    if (badPhoneErrMsg || badPhone2ErrMsg) {
        retVal = false;
    }

    return retVal;
}

async function verifyPhoneNumFormat(phoneNumCorrectFormat) {
    let retVal = null;
    const data = new URLSearchParams();
    data.append("phone", phoneNumCorrectFormat);

    const response = await fetch("http://intl-tel-input-6922.twil.io/lookup", {
        method: "POST",
        body: data,
    });
    const json = await response.json();

    if (json.success) {
        const jsonStr = JSON.stringify(json);
        console.log(jsonStr);
        console.log(`Valid phone number in E.164 format: ${phoneNumCorrectFormat}`);
    } else {
        retVal = json.error;
        console.error(`Something went wrong verifying input phone number ${phoneNumCorrectFormat}: ${retVal}`);
    }

    return retVal;
}

function overlayNewPasswordValues(userProf) {
    const currPwdField = document.getElementById("currPwd");
    userProf.currPwd = currPwdField.value;

    const newPwd1Field = document.getElementById("newPwd1");
    userProf.newPwd1 = newPwd1Field.value;

    const newPwd2Field = document.getElementById("newPwd2");
    userProf.newPwd2 = newPwd2Field.value;
}

function clearPasswordFieldsInUi() {
    const currPwdField = document.getElementById("currPwd");
    currPwdField.value = "";

    const newPwd1Field = document.getElementById("newPwd1");
    newPwd1Field.value = "";

    const newPwd2Field = document.getElementById("newPwd2");
    newPwd2Field.value = "";
}

function overlayNewPersonalInfoValues(userProf) {
    const stateDropdown = document.getElementById("state-dropdown-user-prof");
    const districtDropdown = document.getElementById("district-num-user-prof");

    userProf.addr_state = stateDropdown.value;
    userProf.congress_district = districtDropdown.value;

    const userVerifyCheckbox = document.getElementById('user-verify');
    if (userVerifyCheckbox.checked) {
        userProf.is_addr_verified = 1;
    } else {
        userProf.is_addr_verified = 0;
    }

    const isLotteryRepCheckbox = document.getElementById('in-lottery-rep');
    if (isLotteryRepCheckbox.checked) {
        userProf.is_lottery_rep = 1;
    } else {
        userProf.is_lottery_rep = 0;
    }

    const isLotterySenCheckbox = document.getElementById('in-lottery-senate');
    if (isLotterySenCheckbox.checked) {
        userProf.is_lottery_sen = 1;
    } else {
        userProf.is_lottery_sen = 0;
    }

    const firstNameField = document.getElementById('first-name');
    userProf.first_name = firstNameField.value;

    const lastNameField = document.getElementById('last-name');
    userProf.last_name = lastNameField.value;

    const streetAddrField = document.getElementById('street-addr');
    userProf.addr_street1 = streetAddrField.value;

    const streetAddr2Field = document.getElementById('street-addr2');
    userProf.addr_street2 = streetAddr2Field.value;

    const addrCityField = document.getElementById('addr-city');
    userProf.addr_city = addrCityField.value;

    const addrZipField = document.getElementById('addr-zip');
    userProf.addr_zip = addrZipField.value;
}

async function updateUserProfileOnDbAndCache(userProf) {
    await callAPI_updateAcct(userProf);

    disableSaveAndCancelButtons();
    setCurrentDirtyEditorName(null);

    // Update the cached userProf with the one that was used to successfully update the db.
    cloneTheSourceUserProf(userProfileUnderEdit, userProf);
}

async function changePasswordOnDb(userProf) {
    const theurl = URL_PRE + `/rest/account/changepwd?email=${userProf.email}&currentPassword=${userProf.currPwd}&newPassword=${userProf.newPwd1}&csrfToken=${userSession.csrfPreventionToken}`;
    console.log(`Calling API to change user password...`);

    const response = await fetch(theurl,
        {
            method: 'PUT',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userProf)
        }
    );

    console.log(`response.status: ${response.status}`);

    if (response.ok) {
        disableSaveAndCancelButtons();
        setCurrentDirtyEditorName(null);
        clearPasswordFieldsInUi();
        console.log(`User password changed successfully`);
    } else {
        console.log(`ERROR: bug hit while attempting to change password for user: ${userProf.email}`);
    }
}

function cloneTheSourceUserProf(targetUserProf, sourceUserProf) {

    targetUserProf.id = sourceUserProf.id;
    targetUserProf.first_name = sourceUserProf.first_name;
    targetUserProf.last_name = sourceUserProf.last_name;
    targetUserProf.middle_name = sourceUserProf.middle_name;
    targetUserProf.addr_street1 = sourceUserProf.addr_street1;
    targetUserProf.addr_street2 = sourceUserProf.addr_street2;
    targetUserProf.addr_city = sourceUserProf.addr_city;
    targetUserProf.addr_zip = sourceUserProf.addr_zip;
    targetUserProf.addr_state = sourceUserProf.addr_state;
    targetUserProf.addr_cntryreg = sourceUserProf.addr_cntryreg;
    targetUserProf.phone = sourceUserProf.phone;
    targetUserProf.phone2 = sourceUserProf.phone2;
    targetUserProf.email = sourceUserProf.email;
    targetUserProf.email2 = sourceUserProf.email2;
    targetUserProf.congress_district = sourceUserProf.congress_district;
    targetUserProf.gender = sourceUserProf.gender;
    targetUserProf.ethnicity = sourceUserProf.ethnicity;
    targetUserProf.pronouns = sourceUserProf.pronouns;
    targetUserProf.occupation = sourceUserProf.occupation;
    targetUserProf.annual_income = sourceUserProf.annual_income;
    targetUserProf.oauthid = sourceUserProf.oauthid;
    targetUserProf.register_date = sourceUserProf.register_date;
    targetUserProf.is_admin = sourceUserProf.is_admin;
    targetUserProf.is_addr_verified = sourceUserProf.is_addr_verified;
    targetUserProf.is_lottery_rep = sourceUserProf.is_lottery_rep;
    targetUserProf.is_lottery_sen = sourceUserProf.is_lottery_sen;

}

async function is2faComplete() {
    if (!(userSession && userSession.is2FaAuthenticated)) {
        // Show the info popup about need to complete 2FA
        const modalDialog = getModalDialog();
        modalDialog.innerHTML = await retrieveHtmlTemplateForRouteKey('/twoFaNotComplete');

        const okBtn = document.getElementById("ok-btn");
        okBtn.addEventListener('click', event => {
            closeModalDialog();
        });

        openModalDialog();

        // Send user to page to complete 2FA
        const dummyLink = document.getElementById('dummy-link');
        dummyLink.setAttribute('href', WS_URL_PRE + `/userSecuritySendCode`);
        dummyLink.click();
        return false;
    } else {
        return true;
    }
}

async function callAPI_updateAcct(userProf) {
    const theurl = URL_PRE + `/rest/account?csrfToken=${userSession.csrfPreventionToken}`;
    console.log(`Calling UserAccountsApi.updateAcct(): PUT ${theurl} with json:`);
    console.log(userProf);

    const response = await fetch(theurl,
        {
            method: 'PUT',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userProf)
        }
    );

    console.log(response);
}