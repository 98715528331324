"use strict";

import {
  lsConsts,
  URL_PRE,
  putStringInLocalStorage,
  getStringFromLocalStorage
} from "./ddpLocalStorage.js";
import {
  LEFT_NAV_CLASS,
  LEARNING_HOME_PATH,
  initLeftNavEntities,
  handleAnyClick,
  routeDirectUrlAddress,
  urlRoute,
  urlLocationHandler,
  retrieveAndStoreHtmlTemplate,
  handleToggleLeftNav,
  handleMainNavClick,
  goToLearningHome,
  isInDemoMode
} from "./routingAndNav.js";
import {initMediaQueries} from "./mediaQueries.js";
import {
  userSession,
  readInUserSessionFromLocalStorage,
  showUserContextMenu,
  processLogin,
  initTabWithUserSession,
  shareToSocialMedia
} from "./userActivities.js";
import {initTabId, initTabManager, updateTabInfoForNoUserSession} from "./tabManager.js";
import {hideLoader, openErrorMessageModal, showLoader} from "./modalDialog.js";
import {messages} from "./messages.js";

document.addEventListener("readystatechange", (event) => {
  if (event.target.readyState === "complete") {
    /*
    ASSERT: the index.html has been loaded along with its CSS, and the javascript files have
    been loaded and parsed, so initialize the application.
     */
    initDdpApplication();
  }
});

async function initDdpApplication() {
  showLoader();
  initTabId();
  initTabManager();
  readInUserSessionFromLocalStorage();

  const provisionalLogoutToken = window.sessionStorage.getItem('provisionalLogoutToken');
  if (provisionalLogoutToken) {
    // ASSERT: A provisionalLogoutToken was placed into sessionStorage by the tabManager. Read comments in tabManager.js
    // to learn why provisionalLogout is needed. We need to send the provisionalLogoutToken in on a call to the login
    // API to get the session back that the user had when they clicked the page refresh button.

    await processLogin(window.sessionStorage.getItem('emailAddr'), null, null, provisionalLogoutToken);

    // Remove these properties from sessionStorage.
    window.sessionStorage.removeItem('provisionalLogoutToken');
    window.sessionStorage.removeItem('emailAddr');
  } else if (userSession) {
    // ASSERT: Page was refreshed, and a user is currently logged in.
    await initTabWithUserSession();
  } else {
    // Add current tab to OPEN_TABS in local storage for a tab without a logged in userSession.
    updateTabInfoForNoUserSession();
  }

  await retrieveAndStoreHtmlTemplate(LEARNING_HOME_PATH);
  if (!isInDemoMode) {
    await retrieveAndStoreStateMetadata();
  }
  await retrieveAndStoreHtmlTemplate('/createNewUserProfile');
  await retrieveAndStoreHtmlTemplate('/userLogin');
  await retrieveAndStoreHtmlTemplate('/unsavedChangesConfirmation');
  await retrieveAndStoreHtmlTemplate('/errorMessage');
  await retrieveAndStoreHtmlTemplate('/demoOnlyNotice');
  await retrieveAndStoreHtmlTemplate('/leftNavFooter');
  let serverIsUp = await retrieveAndStoreHtmlTemplate('/inactivityPopup');

  if (serverIsUp) {
    await initLeftNavEntities();
    addEventListeners();
  } else {
    openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
    goToLearningHome();
  }

  window.onload = routeDirectUrlAddress;
  window.onpopstate = urlLocationHandler;
  window.route = urlRoute;
  await initMediaQueries();

  const adminToolsButton = document.getElementById("adminTools-button");
  if (isInDemoMode) {
    adminToolsButton.style.display = "none";
  }

  hideLoader();
}

async function retrieveAndStoreStateMetadata() {
  const stateMetadataFromStorage = getStringFromLocalStorage(lsConsts.LS_STATE_METADATA);
  if (stateMetadataFromStorage && stateMetadataFromStorage.length > 0) {
    // ASSERT: Another tab already loaded the stateMetadata or this tab was refreshed, so it was already there.
    console.log("stateMetadata already exists");
    return;
  }

  const theurl = URL_PRE + "/rest/stateMetadata";
  console.log(`Calling API: GET ${theurl}`);

  try {
    const response = await fetch(theurl, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include'
    });
    const jsonStateMetadata = await response.json();

    putStringInLocalStorage(lsConsts.LS_STATE_METADATA, JSON.stringify(jsonStateMetadata));
  } catch (error) {
    console.error(error);
  }

}

function addEventListeners() {

  const mainNavButtons = document.querySelectorAll('.main-nav-link');
  mainNavButtons.forEach(currButton => {
    currButton.addEventListener('click', handleMainNavClick);
  });

  document.addEventListener("click", showUserContextMenu);
  document.addEventListener("click", handleAnyClick);
  document.getElementById('navi-toggle').addEventListener("click", handleToggleLeftNav);

  dragElement(document.querySelector(".separator"), "H");

  // Add event listeners to the social media share buttons.
  const btnArray = document.querySelectorAll('.social-share__option');
  btnArray.forEach(currSocialButton => {
    currSocialButton.addEventListener('click', shareToSocialMedia);
  });
}

// A function is used for dragging and moving
function dragElement(element, direction) {
  let md; // remember mouse down info

  element.onmousedown = onMouseDown;

  function onMouseDown(e) {
    const leftNav = document.querySelector(LEFT_NAV_CLASS);
    const mainContent = document.querySelector(".main-content-area-container");
    // console.log("mouse down: " + e.clientX);
    md = {
      e,
      offsetLeft: element.offsetLeft,
      offsetTop: element.offsetTop,
      firstWidth: leftNav.offsetWidth,
      secondWidth: mainContent.offsetWidth,
    };

    document.onmousemove = onMouseMove;
    document.onmouseup = () => {
      //console.log("mouse up");
      document.onmousemove = document.onmouseup = null;
    };
  }

  function onMouseMove(e) {
    const leftNav = document.querySelector(LEFT_NAV_CLASS);
    const mainContent = document.querySelector(".main-content-area-container");
    // console.log("mouse move: " + e.clientX);
    let delta = { x: e.clientX - md.e.clientX};

    if (direction === "H") {
      // Horizontal
      // Prevent negative-sized elements
      delta.x = Math.min(Math.max(delta.x, -md.firstWidth), md.secondWidth);

      // element.style.left = md.offsetLeft + delta.x + "px";
      leftNav.style.width = md.firstWidth + delta.x + "px";
      mainContent.style.width = md.secondWidth - delta.x + "px";
    }
  }
}

