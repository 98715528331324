import {
    getNumDistrictsForState,
    getStateMetadataFromLocalStorage,
    URL_PRE
} from "./ddpLocalStorage.js";
import {userSession} from "./userActivities.js";
import {messages} from "./messages.js";
import {
    hideLoaderMainContentArea,
    openErrorMessageModal,
    showLoaderMainContentArea
} from "./modalDialog.js";
import {callAPI_getRankVoteId} from "./rankVoting.js";
import {goToLearningHome, setCurrentDirtyEditorName} from "./routingAndNav.js";

export function initUiToolsRunLottery() {
    if(!(userSession && userSession.isPwdAuthenticated)) {
        const rankEditContainerDiv = document.querySelector(".rank-edit-container");
        rankEditContainerDiv.innerHTML = messages.MUST_LOGIN;
        return;
    }

    const runButton = document.getElementById("runLotteryButton");
    runButton.addEventListener("click", handleRunLottery);
}

export function initUiToolsTabulateRepVotes() {
    if(!(userSession && userSession.isPwdAuthenticated)) {
        const rankEditContainerDiv = document.querySelector(".rank-edit-container");
        rankEditContainerDiv.innerHTML = messages.MUST_LOGIN;
        return;
    }

    const runButton = document.getElementById("tabulateRepVotesButton");
    runButton.addEventListener("click", handleTabulateRepVotes);
    const stateDropdown = document.getElementById("state-dropdown");
    stateDropdown.addEventListener("change", handleStateChange);
}

export function initUiToolsTabulateIssueVotes() {
    if(!(userSession && userSession.isPwdAuthenticated)) {
        const rankEditContainerDiv = document.querySelector(".rank-edit-container");
        rankEditContainerDiv.innerHTML = messages.MUST_LOGIN;
        return;
    }

    const runButton = document.getElementById("tabulateIssueVotesButton");
    runButton.addEventListener("click", handleTabulateIssueVotes);
}

function handleStateChange(event) {
    const statesMetadata = getStateMetadataFromLocalStorage();
    const stateDropdown = document.getElementById("state-dropdown");
    const districtNumDropdown = document.getElementById("district-num");
    while (districtNumDropdown.firstChild) districtNumDropdown.removeChild(districtNumDropdown.firstChild);

    if (stateDropdown.value === " ") {
        // ASSERT: The stateDropdown was cleared, so just clear the district dropdown and return.

        return;
    }

    const numDistricts = getNumDistrictsForState(stateDropdown.value, statesMetadata);

    for (let i = 0; i < numDistricts + 1; i++) {
        let option = document.createElement("option");
        option.value = i;
        option.text = i;

        if (i === 0) {
            option.value = " ";
            option.text = " ";
        }

        districtNumDropdown.add(option, null);
    }
}

async function handleRunLottery() {
    if (userSession && userSession.isPwdAuthenticated) {
        showLoaderMainContentArea();
        await callAPI_runLottery();
        hideLoaderMainContentArea();
    }
}

async function handleTabulateRepVotes() {
    if (!(userSession && userSession.isPwdAuthenticated)) {
        return;
    }

    showLoaderMainContentArea();
    try {
        const elemState = document.getElementById("state-dropdown");
        const elemDistrict = document.getElementById("district-num");
        const selectedState = elemState.value;
        const selectedDistrict = elemDistrict.value;
        let voteKey = selectedState;

        if (selectedState === " ") {
            // ASSERT: The user has not selected a state, which means they want to run
            // tabulate on all the states and districts.
            console.log(`Still need to implement API for tabulating all votes.`)
        } else {
            if (selectedDistrict && selectedDistrict !== " ") {
                voteKey += "-" + selectedDistrict;
            }

            let rankVoteIdMapping = await callAPI_getRankVoteId(voteKey);

            if (rankVoteIdMapping) {
                const voteId = rankVoteIdMapping.voteId;
                await callAPI_tabulateRankVotes(voteId);
            }
        }
    } catch (err) {
        console.dir(err);
        openErrorMessageModal('/errorMessage', messages.SERVER_DOWN, messages.API_CALL_ERROR);
        setCurrentDirtyEditorName(null);
        goToLearningHome();
    }
    hideLoaderMainContentArea();
}

async function callAPI_tabulateRankVotes(voteId) {
    const theurl = URL_PRE + `/rest/vote/rank/tabulate?voteId=${voteId}&tier=1&id=${userSession.id}&csrfToken=${userSession.csrfPreventionToken}`;
    console.log(`Calling API: POST ${theurl}`);

    const response = await fetch(theurl, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include'
    });

    if (!response.ok) {
        throw new Error('Bad response in callAPI_tabulateRankVotes()', {
            cause: {response}
        })
    }

    const tabulateResults = await response.json();
    console.log(`Vote tabulation results:`);
    console.dir(tabulateResults);
    const rankedListContainer = document.getElementById('tabulateVotesMessageArea');
    rankedListContainer.innerHTML = messages.VOTE_TABULATION_SUCCEEDED;
}

async function callAPI_runLottery() {
    const theurl = URL_PRE + `/rest/account/lottery?action=run&id=${userSession.id}&csrfToken=${userSession.csrfPreventionToken}`;
    console.log(`Calling API: POST ${theurl}`);

    try {
        const response = await fetch(theurl, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error('Bad response in callAPI_runLottery()', {
                cause: {response}
            })
        }

        console.log(`Lottery response...`);
        console.dir(response);

        const rankedListContainer = document.getElementById('tabulateVotesMessageArea');
        rankedListContainer.innerHTML = messages.RUN_LOTTERY_SUCCEEDED;
    } catch (err) {
        console.dir(err);
        const rankedListContainer = document.getElementById('tabulateVotesMessageArea');
        rankedListContainer.innerHTML = messages.API_CALL_ERROR;
    }
}

async function handleTabulateIssueVotes() {
    if (!(userSession && userSession.isPwdAuthenticated)) {
        return;
    }

    showLoaderMainContentArea();
    const elemIssueDropdown = document.getElementById("issue-type-dropdown");
    const selectedIssueType = elemIssueDropdown.value;
    let voteId = null;

    if (selectedIssueType === "Regular Issues") {
        voteId = 1;
        console.log(`Running API to tabulate regular issues votes.`)
    } else {
        voteId = 2;
        console.log(`Running API to tabulate emergency issues votes.`);
    }

    await callAPI_tabulateRankVotes(voteId);
    hideLoaderMainContentArea();
}
